import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export interface Data {
  destinationId: string;
}

export type Result = {
  activities: string[];
};

export default function useGenerateTripActivities() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'generateTripActivities'), [functions]);
}
