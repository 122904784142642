import { Image, ImageProps } from '@chakra-ui/react';
import { useMemo } from 'react';

import { getPhotoSizeUrl } from '../../../common/getPhotoSizeUrl';
import { StoragePicture } from '../../../common/StoragePicture';
import useBlur from '../../../hooks/useBlur';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export type Props = {
  picture: StoragePicture;
} & Omit<ImageProps, 'alt' | 'fallbackSrc' | 'objectFit' | 'src'>;

export default function StoragePictureCell({ picture, ...imageProps }: Props) {
  const { height, width } = useWindowDimensions();

  const src = useMemo(
    () => getPhotoSizeUrl({
      faces: true,
      height,
      uri: picture.imgixUrl,
      width,
    }),
    [height, picture.imgixUrl, width],
  );

  const fallbackSrc = useBlur({
    blurHash: picture.blurHash,
    height: 32,
    width: 18,
  });

  return (
    <Image
      alt=""
      fallbackSrc={fallbackSrc}
      objectFit="cover"
      scrollSnapAlign="start"
      scrollSnapStop="always"
      src={src}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...imageProps}
    />
  );
}
