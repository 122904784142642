import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export type Data = void;

export interface Result {
  token: string;
}

export default function useTelegramGetLoginToken() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'telegram-getLoginToken'), [functions]);
}
