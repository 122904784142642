import {
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { LuChevronLeft } from 'react-icons/lu';
import { Link } from 'react-router-dom';

import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import ProfileForm from './ProfileForm';

export function ProfileUpdateScreenMain() {
  const insets = useInsets();

  const { t } = useTranslation('ProfileScreen');

  return (
    <VStack alignItems="stretch" gap={4} h="100%">
      <Container
        style={{
          paddingTop: insets.top,
        }}
      >
        <HStack gap={3}>
          <IconButton
            aria-label={t('backButton.default')}
            as={Link}
            icon={<Icon as={LuChevronLeft} />}
            to=".."
          />

          <Heading
            flex={1}
            fontSize="4xl"
            lineHeight="40px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {t('title')}
          </Heading>
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <ProfileForm />
      </Container>
    </VStack>
  );
}

export default function ProfileUpdateScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <ProfileUpdateScreenMain />
      </Suspense>
    </Catch>
  );
}
