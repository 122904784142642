import {
  Box,
  BoxProps,
  Container,
  HStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import {
  MouseEvent,
  Suspense,
  UIEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFirestoreDocData } from 'reactfire';

import { ApplicationDoc } from '../../../collections/Applications';
import Catch from '../../../components/Catch';
import InsetsProvider, { useInsets } from '../../../components/InsetsProvider';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import InfoCell from './InfoCell';
import StoragePictureCell from './StoragePictureCell';

export type Props = {
  applicationSnap: QueryDocumentSnapshot<ApplicationDoc>;
} & BoxProps;

export function ApplicationMain({
  applicationSnap,
  ...boxProps
}: Props) {
  const insets = useInsets();

  const applicationDoc = useMemo(() => applicationSnap.data(), [applicationSnap]);

  const { data: applicantDoc } = useFirestoreDocData(applicationDoc.applicantRef);

  const { height, width } = useWindowDimensions();

  const [dots, setDots] = useState<number[]>(() => ([
    1,
    ...new Array<number>(applicantDoc.pictures.length - 1).fill(0.25),
  ]));

  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      const res = new Array(applicantDoc.pictures.length).fill(1);
      for (let i = 0; i < applicantDoc.pictures.length; i += 1) {
        res[i] = Math.round((1 - (
          Math.min(Math.abs(e.currentTarget.scrollLeft - width * i), width) / width
        ) * 0.75) * 10) / 10;
      }
      setDots(res);
    },
    [applicantDoc.pictures.length, width],
  );

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!ref.current) {
        return;
      }

      if (e.clientX < e.currentTarget.clientWidth / 3) {
        ref.current.scrollBy({ left: -width });
      } else {
        ref.current.scrollBy({ left: width });
      }
    },
    [width],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      position="relative"
    >
      {dots.length > 1 ? (
        <Container
          left={0}
          position="absolute"
          right={0}
          style={{
            top: `calc(${insets.top} + var(--chakra-space-2))`,
          }}
          zIndex={10}
        >
          <HStack gap={2}>
            {dots.map((opacity, i) => (
              <Box
                backdropFilter="saturate(180%) blur(20px)"
                backgroundColor={`rgb(from var(--chakra-colors-white) r g b / ${opacity})`}
                borderRadius="full"
                flex={1}
                h={0.5}
              // eslint-disable-next-line react/no-array-index-key
                key={i}
                transition="background-color 0.15s ease-in-out"
              />
            ))}
          </HStack>
        </Container>
      ) : null}

      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        h={height}
        onClick={handleClick}
        onScroll={handleScroll}
        overflow="hidden"
        overscrollBehavior="none"
        position="absolute"
        ref={ref}
        scrollSnapType="x mandatory"
        w={width}
      >
        <InsetsProvider
          bottom={insets.bottom}
          left={insets.left}
          right={insets.right}
          top={`calc(${insets.top} + var(--chakra-space-2) + var(--chakra-space-0-5))`}
        >
          {applicantDoc.pictures.map((p) => (
            <StoragePictureCell
              flexBasis={height}
              flexGrow={0}
              flexShrink={0}
              height={height}
              key={p.imgixUrl}
              picture={p}
              width={width}
            />
          ))}
        </InsetsProvider>
      </Box>

      <InfoCell
        applicationSnap={applicationSnap}
        left={0}
        position="absolute"
        right={0}
        style={{
          bottom: `calc(${insets.bottom} + var(--chakra-space-2))`,
        }}
        zIndex={20}
      />
    </Box>
  );
}

export default function Application(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ApplicationMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
