import {
  Badge,
  Box,
  BoxProps,
  Collapse,
  Container,
  Divider,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import _ from 'lodash';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LuCircleDollarSign,
  LuDna,
  LuHeart,
  LuLanguages,
  LuSparkles,
} from 'react-icons/lu';
import { useFirestoreDocData } from 'reactfire';

import { ProfileDoc } from '../../collections/Profiles';
import AppLanguage from '../../common/AppLanguage';
import Gender from '../../common/Gender';
import Role from '../../common/Role';
import Sexuality from '../../common/Sexuality';
import Catch from '../../components/Catch';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ActionCell from './ActionCell';
import ProfileAvatar from './ProfileAvatar';

export type Props = {
  profileDoc: ProfileDoc;
} & BoxProps;

export function InfoCellMain({
  profileDoc,
  ...boxProps
}: Props) {
  const { i18n } = useTranslation('ProfilePreviewScreen', { keyPrefix: 'InfoCell' });
  const { t: lt } = useTranslation('Languages');
  const { t: rt } = useTranslation('Role');
  const { t: gt } = useTranslation('Gender');
  const { t: st } = useTranslation('Sexuality');
  const { t: zt } = useTranslation('Zodiac');

  const { isOpen: isOpenDetails, onToggle: onToggleDetails } = useDisclosure();

  const { height } = useWindowDimensions();

  const { data: originDoc } = useFirestoreDocData(profileDoc.originRef);

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <Container>
        <Box
          _dark={{
            backgroundColor: 'rgb(from var(--chakra-colors-black) r g b / 0.5)',
          }}
          _light={{
            backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.5)',
          }}
          backdropFilter="saturate(180%) blur(20px)"
          borderRadius="2xl"
          cursor="pointer"
          onClick={onToggleDetails}
          p={4}
        >
          <Collapse
            in={isOpenDetails}
            startingHeight="48px"
          >
            <VStack alignItems="stretch" gap={4}>
              <HStack gap={3}>
                <ProfileAvatar
                  profileDoc={profileDoc}
                />

                <VStack alignItems="stretch" flex={1} gap={1} minW={0}>
                  <HStack gap={1}>
                    <Text fontSize="md" lineHeight={1}>
                      {/* eslint-disable-next-line max-len */}
                      {profileDoc.translations?.name[i18n.language as AppLanguage] ?? profileDoc.name}
                      {', '}
                      {profileDoc.age}
                    </Text>

                    {profileDoc.role === Role.HOT ? (
                      <Badge colorScheme="deepOrange" my="-2px">
                        {rt(`${profileDoc.role}.shortLabel`)}
                      </Badge>
                    ) : null}

                    {profileDoc.role === Role.RICH ? (
                      <Badge colorScheme="teal" my="-2px">
                        {rt(`${profileDoc.role}.shortLabel`)}
                      </Badge>
                    ) : null}
                  </HStack>

                  <Text fontSize="xs" lineHeight={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    {_.compact([originDoc.countryEmoji, _.compact([
                      originDoc.name,
                      originDoc.countryName,
                    ]).join(', ')]).join(' ')}
                  </Text>
                </VStack>

                <ActionCell />
              </HStack>

              <Divider />

              <VStack alignItems="stretch" gap={4} maxH={`${height - 274}px`} overflow="auto">
                <Wrap>
                  {profileDoc.role === Role.HOT ? (
                    <Badge alignItems="center" colorScheme="deepOrange" display="flex" gap={1}>
                      <Icon as={LuCircleDollarSign} boxSize={3} />
                      {rt(`${profileDoc.role}.longLabel`)}
                    </Badge>
                  ) : null}

                  {profileDoc.role === Role.RICH ? (
                    <Badge alignItems="center" colorScheme="teal" display="flex" gap={1}>
                      <Icon as={LuCircleDollarSign} boxSize={3} />
                      {rt(`${profileDoc.role}.longLabel`)}
                    </Badge>
                  ) : null}

                  {profileDoc.gender === Gender.FEMALE ? (
                    <Badge alignItems="center" colorScheme="pink" display="flex" gap={1}>
                      <Icon as={LuDna} size={5} />
                      {gt(profileDoc.gender)}
                    </Badge>
                  ) : null}

                  {profileDoc.gender === Gender.MALE ? (
                    <Badge alignItems="center" colorScheme="blue" display="flex" gap={1}>
                      <Icon as={LuDna} size={5} />
                      {gt(profileDoc.gender)}
                    </Badge>
                  ) : null}

                  {
                    profileDoc.sexuality === Sexuality.STRAIGHT
                      && profileDoc.gender === Gender.FEMALE ? (
                        <Badge alignItems="center" colorScheme="blue" display="flex" gap={1}>
                          <Icon as={LuHeart} size={5} />
                          {st(profileDoc.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    profileDoc.sexuality === Sexuality.STRAIGHT
                      && profileDoc.gender === Gender.MALE ? (
                        <Badge alignItems="center" colorScheme="pink" display="flex" gap={1}>
                          <Icon as={LuHeart} size={5} />
                          {st(profileDoc.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    profileDoc.sexuality === Sexuality.GAY
                      && profileDoc.gender === Gender.FEMALE ? (
                        <Badge alignItems="center" colorScheme="pink" display="flex" gap={1}>
                          <Icon as={LuHeart} size={5} />
                          {st(profileDoc.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    profileDoc.sexuality === Sexuality.GAY
                      && profileDoc.gender === Gender.MALE ? (
                        <Badge alignItems="center" colorScheme="blue" display="flex" gap={1}>
                          <Icon as={LuHeart} size={5} />
                          {st(profileDoc.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    profileDoc.sexuality === Sexuality.BISEXUAL ? (
                      <Badge alignItems="center" colorScheme="purple" display="flex" gap={1}>
                        <Icon as={LuHeart} size={5} />
                        {st(profileDoc.sexuality)}
                      </Badge>
                    ) : null
                  }

                  {profileDoc.languages.map((l) => (
                    <Badge alignItems="center" display="flex" gap={1} key={l}>
                      <Icon as={LuLanguages} size={5} />
                      {lt(l)}
                    </Badge>
                  ))}

                  <Badge>
                    {profileDoc.height}
                    cm
                  </Badge>

                  <Badge>
                    {profileDoc.weight}
                    kg
                  </Badge>

                  <Badge>
                    {profileDoc.age}
                    y
                  </Badge>

                  <Badge alignItems="center" display="flex" gap={1}>
                    <Icon as={LuSparkles} size={5} />
                    {zt(profileDoc.zodiac)}
                  </Badge>

                  {originDoc.name ? (
                    <Badge>
                      {originDoc.name}
                    </Badge>
                  ) : null}

                  {originDoc.countryName ? (
                    <Badge>
                      {originDoc.countryName}
                    </Badge>
                  ) : null}

                  {originDoc.continentName ? (
                    <Badge>
                      {originDoc.continentName}
                    </Badge>
                  ) : null}
                </Wrap>
              </VStack>
            </VStack>
          </Collapse>
        </Box>
      </Container>
    </Box>
  );
}

export default function InfoCell(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <InfoCellMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
