import { Button, Icon } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LuPlus } from 'react-icons/lu';

import useTelegramGetLoginToken from '../../functions/useTelegramGetLoginToken';

export default function TelegramButton() {
  const { t } = useTranslation('SettingsScreen', { keyPrefix: 'TelegramUsers' });

  const [token, setToken] = useState<string>();

  const telegramGetLoginToken = useTelegramGetLoginToken();

  useEffect(
    () => {
      telegramGetLoginToken()
        .then(({ data }) => setToken(data.token))
        .catch(() => { });
    },
    [telegramGetLoginToken],
  );

  return (
    <Button
      as="a"
      href={token ? `https://t.me/qupidubot?start=${token}` : undefined}
      isLoading={!token}
      justifyContent="start"
      leftIcon={<Icon as={LuPlus} />}
      loadingText={t('connectButton.default')}
      target="_blanc"
      variant="ghost"
    >
      {t('connectButton.default')}
    </Button>
  );
}
