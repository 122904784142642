import {
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  Spinner,
  Step,
  StepIcon,
  StepIndicator,
  Stepper,
  StepSeparator,
  StepStatus,
  VStack,
} from '@chakra-ui/react';
import { logEvent, setUserId } from 'firebase/analytics';
import { RecaptchaVerifier, User } from 'firebase/auth';
import { doc } from 'firebase/firestore';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  LuArrowLeftRight,
  LuDna,
  LuMessageSquare,
  LuPhone,
  LuUserSquare,
} from 'react-icons/lu';
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import {
  useAnalytics,
  useAuth,
  useFirestoreDoc,
  useUser,
} from 'reactfire';

import { useProfilesCollectionRef } from '../../collections/Profiles';
import Gender from '../../common/Gender';
import Role from '../../common/Role';
import Sexuality from '../../common/Sexuality';
import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import OnboardingGenderScreen from '../OnboardingGenderScreen';
import OnboardingPhoneNumberScreen from '../OnboardingPhoneNumberScreen';
import OnboardingProfileScreen from '../OnboardingProfileScreen';
import OnboardingRoleScreen from '../OnboardingRoleScreen';
import OnboardingVerificationCodeScreen from '../OnboardingVerificationCodeScreen';

export function OnboardingLayoutMain() {
  const insets = useInsets();

  const navigate = useNavigate();
  const analytics = useAnalytics();
  const profilesCollectionRef = useProfilesCollectionRef();

  const { data: user } = useUser();

  if (!user) {
    throw new Error('Unauthenticated');
  }

  const profileRef = useMemo(
    () => doc(profilesCollectionRef, user.uid),
    [profilesCollectionRef, user.uid],
  );

  const { data: profileSnap } = useFirestoreDoc(profileRef);

  useEffect(
    () => {
      if (profileSnap.exists()) {
        navigate('/');
      }
    },
    [navigate, profileSnap],
  );

  const auth = useAuth();
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>();

  useEffect(
    () => {
      const rv = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          theme: 'dark',
        },
        auth,
      );
      setRecaptchaVerifier(rv);

      return () => {
        rv.clear();
      };
    },
    [auth],
  );

  const [
    phoneNumberFormResponse,
    setPhoneNumberFormResponse,
  ] = useState<{
    countryCode: string;
    phoneNumber: string;
    userExists: boolean;
    verificationId: string;
  }>();
  const onPhoneNumberFormComplete = useCallback(
    (value: {
      countryCode: string;
      phoneNumber: string;
      userExists: boolean;
      verificationId: string;
    }) => {
      setPhoneNumberFormResponse(value);
      navigate('./verificationCode');
    },
    [navigate],
  );

  const onVerificationCodeFormComplete = useCallback(
    (values: { user: User }) => {
      setUserId(analytics, values.user.uid);

      logEvent(analytics, 'login', {
        method: 'phone',
      });

      navigate('./role');
    },
    [analytics, navigate],
  );

  const onVerificationCodeFormCancel = useCallback(
    () => {
      navigate('./phoneNumber');
    },
    [navigate],
  );

  const [roleFormResponse, setRoleFormResponse] = useState<{ role: Role }>();

  const onRoleFormComplete = useCallback(
    (value: { role: Role }) => {
      setRoleFormResponse(value);

      navigate('./gender');
    },
    [navigate],
  );

  const [genderFormResponse, setGenderFormResponse] = useState<{
    gender: Gender,
    sexuality: Sexuality,
  }>();

  const onGenderFormComplete = useCallback(
    (value: { gender: Gender, sexuality: Sexuality, }) => {
      setGenderFormResponse(value);

      navigate('./profile');
    },
    [navigate],
  );

  const onComplete = useCallback(
    () => {
      navigate('/');
    },
    [navigate],
  );

  const phoneNumberMatch = useMatch('/onboarding/phoneNumber');
  const verificationCodeMatch = useMatch('/onboarding/verificationCode');
  const roleMatch = useMatch('/onboarding/role');
  const genderMatch = useMatch('/onboarding/gender');
  const profileMatch = useMatch('/onboarding/profile');

  const step = useMemo(
    () => {
      if (phoneNumberMatch) return 0;
      if (verificationCodeMatch) return 1;
      if (roleMatch) return 2;
      if (genderMatch) return 3;
      if (profileMatch) return 4;
      return -1;
    },
    [genderMatch, phoneNumberMatch, profileMatch, roleMatch, verificationCodeMatch],
  );

  return (
    <Container
      h="100%"
      minH={0}
      style={{
        paddingBottom: insets.bottom,
        paddingTop: insets.top,
      }}
    >
      <VStack alignItems="stretch" gap={4} h="100%">
        <HStack gap={3}>
          <Heading
            flex={1}
            fontSize="4xl"
            lineHeight="40px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            Onboarding
          </Heading>
        </HStack>

        <Stepper gap={0} index={step}>
          <Step style={{ gap: 0 }}>
            <StepIndicator>
              <StepStatus
                active={<Icon as={LuPhone} />}
                complete={<StepIcon />}
                incomplete={<Icon as={LuPhone} />}
              />
            </StepIndicator>
            <StepSeparator style={{ marginLeft: 0 }} />
          </Step>

          <Step style={{ gap: 0 }}>
            <StepIndicator>
              <StepStatus
                active={<Icon as={LuMessageSquare} />}
                complete={<StepIcon />}
                incomplete={<Icon as={LuMessageSquare} />}
              />
            </StepIndicator>
            <StepSeparator style={{ marginLeft: 0 }} />
          </Step>

          <Step style={{ gap: 0 }}>
            <StepIndicator>
              <StepStatus
                active={<Icon as={LuArrowLeftRight} />}
                complete={<StepIcon />}
                incomplete={<Icon as={LuArrowLeftRight} />}
              />
            </StepIndicator>
            <StepSeparator style={{ marginLeft: 0 }} />
          </Step>

          <Step style={{ gap: 0 }}>
            <StepIndicator>
              <StepStatus
                active={<Icon as={LuDna} />}
                complete={<StepIcon />}
                incomplete={<Icon as={LuDna} />}
              />
            </StepIndicator>
            <StepSeparator style={{ marginLeft: 0 }} />
          </Step>

          <Step style={{ gap: 0 }}>
            <StepIndicator>
              <StepStatus
                active={<Icon as={LuUserSquare} />}
                complete={<StepIcon />}
                incomplete={<Icon as={LuUserSquare} />}
              />
            </StepIndicator>
            <StepSeparator style={{ marginLeft: 0 }} />
          </Step>
        </Stepper>

        {/* eslint-disable react/jsx-props-no-spreading */}
        <Routes>
          <Route element={<Navigate to="phoneNumber" />} index />
          <Route
            element={
              // eslint-disable-next-line no-nested-ternary
              !user.isAnonymous ? (
                <Navigate to="../role" />
              ) : (
                recaptchaVerifier ? (
                  <OnboardingPhoneNumberScreen
                    onComplete={onPhoneNumberFormComplete}
                    recaptchaVerifier={recaptchaVerifier}
                    {...phoneNumberFormResponse}
                  />
                ) : null
              )
            }
            path="phoneNumber"
          />
          <Route
            element={
              // eslint-disable-next-line no-nested-ternary
              !user.isAnonymous ? (
                <Navigate to="../role" />
              ) : (
                phoneNumberFormResponse ? (
                  <OnboardingVerificationCodeScreen
                    onCancel={onVerificationCodeFormCancel}
                    onComplete={onVerificationCodeFormComplete}
                    {...phoneNumberFormResponse}
                  />
                ) : (
                  <Navigate to="../phoneNumber" />
                )
              )
            }
            path="verificationCode"
          />
          <Route
            element={(
              <OnboardingRoleScreen
                onComplete={onRoleFormComplete}
                {...roleFormResponse}
              />
          )}
            path="role"
          />
          <Route
            element={roleFormResponse ? (
              <OnboardingGenderScreen
                onComplete={onGenderFormComplete}
                {...roleFormResponse}
                {...genderFormResponse}
              />
            ) : (
              <Navigate to="../role" />
            )}
            path="gender"
          />
          <Route
            element={roleFormResponse && genderFormResponse ? (
              <OnboardingProfileScreen
                onComplete={onComplete}
                {...roleFormResponse}
                {...genderFormResponse}
              />
            ) : (
              <Navigate to="../gender" />
            )}
            path="profile"
          />
        </Routes>
        {/* eslint-enable react/jsx-props-no-spreading */}
      </VStack>
    </Container>
  );
}

export default function OnboardingLayout() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <OnboardingLayoutMain />
      </Suspense>
    </Catch>
  );
}
