import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export interface Data {
  initData: string;
}

export interface Result {
  token: string;
}

export default function useTelegramGetToken() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'telegram-getToken'), [functions]);
}
