import {
  Box,
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { LuFilter } from 'react-icons/lu';

import Catch from '../../components/Catch';
import InsetsProvider, { useInsets } from '../../components/InsetsProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import PreferencesForm from './PreferencesForm';
import TripList from './TripList';

export function HomeScreenMain() {
  const insets = useInsets();

  const { t } = useTranslation('HomeScreen');

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box
      h="100%"
      position="relative"
    >
      <Box
        left={0}
        position="absolute"
        right={0}
        style={{
          top: insets.top,
        }}
        zIndex={10}
      >
        <Container>
          <HStack gap={3}>
            <Heading
              color="white"
              flex={1}
              fontSize="4xl"
              lineHeight="40px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {t('title')}
            </Heading>

            <Popover
              closeOnBlur={false}
              isOpen={isOpen}
              onClose={onClose}
              onOpen={onOpen}
              placement="bottom-end"
            >
              <PopoverTrigger>
                <IconButton
                  aria-label="Filter"
                  backdropFilter="saturate(180%) blur(20px)"
                  icon={<Icon as={LuFilter} />}
                />
              </PopoverTrigger>

              <PopoverContent p={4}>
                <PopoverArrow />
                <PreferencesForm onCancel={onClose} />
              </PopoverContent>
            </Popover>
          </HStack>
        </Container>
      </Box>

      <InsetsProvider
        bottom={insets.bottom}
        left={insets.left}
        right={insets.right}
        top={`calc(${insets.top} + var(--chakra-space-10))`}
      >
        <TripList />
      </InsetsProvider>
    </Box>
  );
}

export default function HomeScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <HomeScreenMain />
      </Suspense>
    </Catch>
  );
}
