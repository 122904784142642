import {
  Badge,
  Icon,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { limit, query, where } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuMessageSquare } from 'react-icons/lu';
import { Link, useMatch } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { ApplicationStatus, useApplicationsCollectionRef } from '../../collections/Applications';
import {
  ConversationStatus,
  useConversationsCollectionRef,
} from '../../collections/Conversations';
import Catch from '../../components/Catch';
import { useProfileRef } from '../../components/ProfileRefProvider';

export function ConversationsBadge() {
  const profileRef = useProfileRef();

  const conversationsCollectionRef = useConversationsCollectionRef();
  const { data: unreadConversationsSnap } = useFirestoreCollection(
    query(
      conversationsCollectionRef,
      where('status', '==', ConversationStatus.OPENED),
      where('notReadByRefs', 'array-contains', profileRef),
      limit(10),
    ),
  );

  const unreadConversationsCount = useMemo(
    () => unreadConversationsSnap.docs.length,
    [unreadConversationsSnap.docs.length],
  );

  const applicationsCollectionRef = useApplicationsCollectionRef();
  const { data: applicationsSnap } = useFirestoreCollection(
    query(
      applicationsCollectionRef,
      where('status', '==', ApplicationStatus.SENT),
      where('organizerRef', '==', profileRef),
      limit(10),
    ),
  );

  const applicationsCount = useMemo(
    () => applicationsSnap.docs.length,
    [applicationsSnap.docs.length],
  );

  const total = useMemo(() => unreadConversationsCount + applicationsCount, [
    unreadConversationsCount,
    applicationsCount,
  ]);

  if (!total) {
    return null;
  }

  return (
    <Badge colorScheme="red" mr={-5} mt={-5} variant="solid" zIndex={10}>
      {total > 9 ? '9+' : total}
    </Badge>
  );
}

export default function Conversations() {
  const { t } = useTranslation('MainLayout');

  const conversationsMatch = useMatch('/conversations/*');

  return (
    <VStack alignItems="center" spacing={0}>
      <IconButton
        aria-label={t('conversationsButton.default')}
        as={Link}
        icon={<Icon as={LuMessageSquare} boxSize={6} />}
        isActive={!!conversationsMatch}
        isRound
        size="lg"
        to="/conversations"
        variant="ghost"
      />
      <Catch fallback={null}>
        <Suspense fallback={null}>
          <ConversationsBadge />
        </Suspense>
      </Catch>
    </VStack>
  );
}
