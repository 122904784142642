import { insightsClient } from '@algolia/client-insights';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  Icon,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import {
  and,
  doc,
  getDocs,
  limit,
  or,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuHeart, LuMessageSquare } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';
import { useFirestoreCollection, useFirestoreDocData } from 'reactfire';

import {
  ApplicationStatus,
  useApplicationsCollectionRef,
} from '../../../collections/Applications';
import {
  ConversationStatus,
  useConversationsCollectionRef,
} from '../../../collections/Conversations';
import { useProfilesCollectionRef } from '../../../collections/Profiles';
import { TripStatus, useTripsCollectionRef } from '../../../collections/Trips';
import TripAlgoliaSearchRecord from '../../../common/TripAlgoliaSearchRecord';
import Catch from '../../../components/Catch';
import { useProfileRef } from '../../../components/ProfileRefProvider';
import useShowError from '../../../hooks/useShowError';

const algoliaInsights = insightsClient('G4ZEIPSJ7Z', '87b1a20fccf5600b3aff0754e664c2ce');

export type Props = {
  profileExists: boolean;
  queryId: string | undefined;
  tripRecord: TripAlgoliaSearchRecord;
};

export function ActionCellMain({
  profileExists, queryId, tripRecord,
}: Props) {
  const { t } = useTranslation('HomeScreen', { keyPrefix: 'Trip' });

  const showError = useShowError();

  const profileRef = useProfileRef();

  const tripsCollectionRef = useTripsCollectionRef();
  const tripRef = useMemo(
    () => doc(tripsCollectionRef, tripRecord.objectID),
    [tripRecord.objectID, tripsCollectionRef],
  );

  const { data: tripDoc } = useFirestoreDocData(tripRef);

  const profilesCollectionRef = useProfilesCollectionRef();
  const organizerRef = useMemo(
    () => doc(profilesCollectionRef, tripRecord.organizer.id),
    [profilesCollectionRef, tripRecord.organizer.id],
  );

  const applicationsCollectionRef = useApplicationsCollectionRef();
  const { data: applicationsSnap } = useFirestoreCollection(
    query(
      applicationsCollectionRef,
      where('organizerRef', '==', tripDoc.organizerRef),
      where('applicantRef', '==', profileRef),
      where('status', '==', ApplicationStatus.SENT),
      limit(1),
    ),
  );
  const applied = useMemo(
    () => applicationsSnap.docs.length > 0,
    [applicationsSnap.docs.length],
  );

  const conversationsCollectionRef = useConversationsCollectionRef();
  const { data: conversationsSnap } = useFirestoreCollection(
    query(
      conversationsCollectionRef,
      and(
        or(
          where('participantRefs', '==', [profileRef, tripDoc.organizerRef]),
          where('participantRefs', '==', [tripDoc.organizerRef, profileRef]),
        ),
        where('status', '==', ConversationStatus.OPENED),
      ),
      limit(1),
    ),
  );
  const accepted = useMemo(
    () => conversationsSnap.docs.length > 0,
    [conversationsSnap.docs.length],
  );

  const [sending, setSending] = useState<boolean>(false);
  const handleApplyClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setSending(true);

      if (tripRecord.status !== TripStatus.PUBLISHED) {
        throw new Error('Trip should be published');
      }

      const existingApplicationsSnap = await getDocs(
        query(
          applicationsCollectionRef,
          where('applicantRef', '==', profileRef),
          where('organizerRef', '==', organizerRef),
          where('tripRef', '==', tripRef),
          where('status', '==', ApplicationStatus.SENT),
        ),
      );

      if (existingApplicationsSnap.docs.length > 0) {
        throw new Error('Trip Join Request already exists');
      }

      const applicationRef = doc(applicationsCollectionRef);

      await setDoc(
        applicationRef,
        {
          _v: 1,
          applicantRef: profileRef,
          expiresAt: Timestamp.fromDate(moment.utc().add(1, 'year').toDate()),
          organizerRef,
          sentAt: serverTimestamp(),
          status: ApplicationStatus.SENT,
          tripRef,
        },
      );

      if (queryId) {
        algoliaInsights.pushEvents({
          events: [
            {
              authenticatedUserToken: profileRef.id,
              eventName: 'Trip Applied',
              eventType: 'conversion',
              index: 'trips',
              objectIDs: [tripRecord.objectID],
              queryID: queryId,
              timestamp: Date.now(),
              userToken: profileRef.id,
            },
          ],
        }).catch(() => { });
      }

      setSending(false);
    },
    [
      applicationsCollectionRef,
      organizerRef,
      profileRef,
      queryId,
      tripRecord.objectID,
      tripRecord.status,
      tripRef,
    ],
  );

  const navigate = useNavigate();

  const handleMessageClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (conversationsSnap.docs.length) {
        navigate(`/conversations/${conversationsSnap.docs[0].id}`);
      }
    },
    [conversationsSnap.docs, navigate],
  );

  const { isOpen, onClose, onOpen } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  if (profileExists) {
    if (accepted) {
      return (
        <IconButton
          aria-label={t('sendMessageButton.default')}
          icon={<Icon as={LuMessageSquare} />}
          onClick={handleMessageClick}
          size="lg"
          variant="solid"
        />
      );
    }

    if (applied) {
      return (
        <IconButton
          aria-label={t('applyButton.disabled')}
          colorScheme="pink"
          icon={<Icon as={LuHeart} />}
          isDisabled
          size="lg"
          variant="solid"
        />
      );
    }

    return (
      <IconButton
        aria-label={t('applyButton.default')}
        colorScheme="pink"
        icon={<Icon as={LuHeart} />}
        isLoading={sending}
        onClick={(e) => { handleApplyClick(e).catch(showError); }}
        size="lg"
        variant="solid"
      />
    );
  }

  return (
    <>
      <IconButton
        aria-label={t('applyButton.default')}
        colorScheme="pink"
        icon={<Icon as={LuHeart} />}
        onClick={(e) => { e.stopPropagation(); onOpen(); }}
        size="lg"
        variant="solid"
      />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogContent
          mx={4}
        >
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t('applyAlertModal.title')}
          </AlertDialogHeader>

          <AlertDialogBody>
            {t('applyAlertModal.body')}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} ref={cancelRef} variant="ghost">
              {t('applyAlertModal.cancelButton.default')}
            </Button>

            <Button as={Link} ml={3} to="/onboarding">
              {t('applyAlertModal.confirmButton.default')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default function ActionCell(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense
        fallback={(
          <IconButton
            aria-label="Loading"
            colorScheme="pink"
            icon={<Icon as={LuHeart} />}
            isLoading
            size="lg"
            variant="solid"
          />
        )}
      >
        <ActionCellMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
