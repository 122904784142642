import { FirebaseStorage, ref } from 'firebase/storage';
import _ from 'lodash';

export interface Props {
  storage: FirebaseStorage;
  storageRef: string;
}

export function storageRefToImgixUrl({
  storage, storageRef,
}: Props) {
  const url = new URL(`https://qupidu.imgix.net/${_.drop(ref(storage, storageRef).fullPath.split('/'), 1).join('/')}`);
  url.searchParams.set('h', String(320));
  url.searchParams.set('w', String(180));
  url.searchParams.set('fit', 'crop');
  url.searchParams.set('crop', 'faces');
  url.searchParams.set('fm', 'webp');
  return url.toString();
}
