export interface Props {
  faces?: boolean;
  height: number;
  uri: string;
  width: number;
}

export function getPhotoSizeUrl({
  faces = false, height, uri, width,
}: Props) {
  const url = new URL(uri);
  url.searchParams.set('h', String(Math.round(height * window.devicePixelRatio)));
  url.searchParams.set('w', String(Math.round(width * window.devicePixelRatio)));
  url.searchParams.set('fit', 'crop');
  url.searchParams.set('fm', 'webp');

  if (faces) {
    url.searchParams.set('crop', 'faces');
  }
  return url.toString();
}

export function usePhotoSizeUrl(props: Props) {
  return getPhotoSizeUrl({ ...props });
}
