import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { FirebaseError } from 'firebase/app';
import { AuthErrorCodes, PhoneAuthProvider, RecaptchaVerifier } from 'firebase/auth';
import { Formik, FormikHelpers } from 'formik';
import _ from 'lodash';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'reactfire';
import * as yup from 'yup';

import countryCodes from '../../common/countryCodes';
import Catch from '../../components/Catch';
import { useIPData } from '../../components/IPDataProvider';
import useUserWithPhoneNumberExists from '../../functions/useUserWithPhoneNumberExists';
import ErrorFallbackScreen from '../ErrorFallbackScreen';

export type Props = {
  // eslint-disable-next-line react/require-default-props
  countryCode?: string;
  onComplete: (values: {
    countryCode: string,
    phoneNumber: string,
    userExists: boolean,
    verificationId: string,
  }) => void;
  // eslint-disable-next-line react/require-default-props
  phoneNumber?: string;
  recaptchaVerifier: RecaptchaVerifier;
};

export function PhoneNumberFormMain({
  countryCode,
  onComplete,
  phoneNumber,
  recaptchaVerifier,
}: Props) {
  const { t } = useTranslation('OnboardingPhoneNumberScreen', { keyPrefix: 'PhoneNumberForm' });

  const schema = useMemo(
    () => yup.object().shape({
      countryCode: yup
        .string()
        .label(t('countryCode.label'))
        .required(),
      phoneNumber: yup
        .string()
        .label(t('phoneNumber.label'))
        .required(),
    }),
    [t],
  );

  const ipData = useIPData();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      countryCode: countryCode ?? ipData?.calling_code ?? '',
      phoneNumber: phoneNumber ?? '',
    }),
    [countryCode, ipData?.calling_code, phoneNumber],
  );

  const auth = useAuth();

  const phoneAuthProvider = useMemo(() => new PhoneAuthProvider(auth), [auth]);

  const userWithPhoneNumberExists = useUserWithPhoneNumberExists();
  const onSubmit = useCallback(
    async (
      values: typeof schema['__outputType'],
      formikHelpers: FormikHelpers<typeof schema['__outputType']>,
    ) => {
      if (!recaptchaVerifier) {
        return;
      }

      try {
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          `+${values.countryCode}${values.phoneNumber}`,
          recaptchaVerifier,
        );

        const { data: { exists } } = await userWithPhoneNumberExists({
          phoneNumber: `+${values.countryCode}${values.phoneNumber}`,
        });

        onComplete({
          ...values,
          userExists: exists,
          verificationId,
        });
      } catch (err) {
        if (err instanceof FirebaseError) {
          if (err.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
            formikHelpers.setFieldError('phoneNumber', 'Too many attempts, try later.');
            return;
          }

          if (err.code === AuthErrorCodes.INVALID_PHONE_NUMBER) {
            formikHelpers.setFieldError('phoneNumber', 'Invalid phone number.');
            return;
          }

          formikHelpers.setFieldError('phoneNumber', err.message);
          return;
        }

        throw err;
      }
    },
    [onComplete, phoneAuthProvider, recaptchaVerifier, userWithPhoneNumberExists],
  );

  const codes = useMemo(
    () => _.uniq(countryCodes.map(({ calling_code }) => calling_code)).sort(),
    [],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik<typeof schema['__outputType']>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          flex={1}
          gap={4}
          h="100%"
          minH={0}
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
        >
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <FormControl isInvalid={!!errors.phoneNumber || !!errors.countryCode}>
              <FormLabel>{t('phoneNumber.label')}</FormLabel>

              <HStack>
                <Select
                  autoComplete="tel-country-code"
                  onChange={handleChange('countryCode')}
                  value={values.countryCode}
                  w="120px"
                >
                  {codes.map((calling_code) => (
                    <option key={calling_code} value={calling_code}>
                      +
                      {calling_code}
                    </option>
                  ))}
                </Select>

                <Input
                  autoComplete="tel-national"
                  autoFocus
                  inputMode="tel"
                  onChange={handleChange('phoneNumber')}
                  type="tel"
                  value={values.phoneNumber}
                />
              </HStack>

              <FormErrorMessage>
                {errors.phoneNumber}
                {errors.countryCode}
              </FormErrorMessage>
            </FormControl>
          </VStack>

          <Button
            isLoading={isSubmitting}
            loadingText={t('sendCodeButton.loading')}
            type="submit"
            width="100%"
          >
            {t('sendCodeButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}

export default function PhoneNumberForm(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <PhoneNumberFormMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
