import { HStack, useRadioGroup, UseRadioGroupProps } from '@chakra-ui/react';

import Role from '../../common/Role';
import HotCard from './HotCard';
import RichCard from './RichCard';

export default function RoleRadioGroup(props: UseRadioGroupProps) {
  const { getRadioProps, getRootProps } = useRadioGroup(props);

  const group = getRootProps();

  const radioHot = getRadioProps({ value: Role.HOT });
  const radioRich = getRadioProps({ value: Role.RICH });

  return (
    <HStack
      alignItems="stretch"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...group}
    >
      <HotCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioHot}
      />
      <RichCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioRich}
      />
    </HStack>
  );
}
