import { Center, Spinner } from '@chakra-ui/react';
import { Timestamp, updateDoc } from 'firebase/firestore';
import { Suspense, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { getProfileHiddenRef } from '../collections/Profiles';
import Catch from '../components/Catch';
import ProfileProvider from '../components/ProfileProvider';
import { useProfileRef } from '../components/ProfileRefProvider';
import ErrorFallbackScreen from './ErrorFallbackScreen';

export type Props = {
  fallback: React.ReactElement;
};

export function CheckProfileLayoutMain({ fallback }: Props) {
  const profileRef = useProfileRef();

  const profileHiddenRef = useMemo(
    () => getProfileHiddenRef(profileRef),
    [profileRef],
  );

  const { data: profileSnap } = useFirestoreDoc(profileRef);
  const { data: profileHiddenSnap } = useFirestoreDoc(profileHiddenRef);

  useEffect(
    () => {
      const update = () => {
        updateDoc(
          profileRef,
          'lastSeenAt',
          Timestamp.now(),
        ).catch(() => { });
      };

      update();
      const timer = setInterval(update, 1000 * 15);

      return () => {
        clearInterval(timer);
      };
    },
    [profileRef],
  );

  if (
    !profileSnap.exists()
    || !profileHiddenSnap.exists()
  ) {
    return fallback;
  }

  return (
    <ProfileProvider
      profileHiddenSnap={profileHiddenSnap}
      profileSnap={profileSnap}
    >
      <Outlet />
    </ProfileProvider>
  );
}

export default function CheckProfileLayout(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <CheckProfileLayoutMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
