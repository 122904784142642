import { Avatar, AvatarBadge, Icon } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { LuUser } from 'react-icons/lu';
import { useFirestoreDocData } from 'reactfire';

import { ProfileDoc } from '../../collections/Profiles';
import { getPhotoSizeUrl } from '../../common/getPhotoSizeUrl';
import Catch from '../../components/Catch';
import useCurrentTime from '../../hooks/useCurrentTime';

export type Props = {
  participantRef: DocumentReference<ProfileDoc>;
};

export function ParticipantAvatarMain({ participantRef }: Props) {
  const { data: participantDoc } = useFirestoreDocData(participantRef);

  const currentTime = useCurrentTime();

  const primaryPictureSrc = useMemo(
    () => {
      const uri = participantDoc.pictures[participantDoc.primaryPictureIndex ?? 0]?.imgixUrl;

      if (!uri) {
        return undefined;
      }

      return getPhotoSizeUrl({
        faces: true,
        height: 40,
        uri,
        width: 40,
      });
    },
    [participantDoc.pictures, participantDoc.primaryPictureIndex],
  );

  return (
    <Avatar
      boxSize={10}
      icon={<Icon as={LuUser} />}
      loading="lazy"
      size="md"
      src={primaryPictureSrc}
    >
      {currentTime.getTime() - 1000 * 60 < (
        participantDoc.lastSeenAt?.toMillis() ?? 0
      ) ? (
        <AvatarBadge bg="green.500" border="none" boxSize={3} transform="none" />
        ) : null}
    </Avatar>
  );
}

export default function ParticipantAvatar(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ParticipantAvatarMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
