import {
  Avatar,
  AvatarBadge,
  AvatarProps,
  Icon,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { LuUser } from 'react-icons/lu';
import { useFirestoreDocData } from 'reactfire';

import { ProfileDoc } from '../../../collections/Profiles';
import { getPhotoSizeUrl } from '../../../common/getPhotoSizeUrl';
import Catch from '../../../components/Catch';
import useCurrentTime from '../../../hooks/useCurrentTime';

export type Props = {
  organizerRef: DocumentReference<ProfileDoc>;
} & AvatarProps;

export function OrganizerAvatarMain({ organizerRef, ...avatarProps }: Props) {
  const { data: organizerDoc } = useFirestoreDocData(organizerRef);

  const primaryPictureSrc = useMemo(
    () => {
      const uri = organizerDoc.pictures[organizerDoc.primaryPictureIndex ?? 0].imgixUrl;

      if (!uri) {
        return undefined;
      }

      return getPhotoSizeUrl({
        faces: true,
        height: 32,
        uri,
        width: 32,
      });
    },
    [organizerDoc.pictures, organizerDoc.primaryPictureIndex],
  );

  const currentTime = useCurrentTime();

  return (
    <Avatar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...avatarProps}
      icon={<Icon as={LuUser} />}
      loading="lazy"
      src={primaryPictureSrc}
    >
      {currentTime.getTime() - 1000 * 60 < (
        organizerDoc.lastSeenAt?.toMillis() ?? 0
      ) ? (
        <AvatarBadge
          bg="green.500"
          border="none"
          boxSize={3}
          transform="none"
        />
        ) : null}
    </Avatar>
  );
}

export default function OrganizerAvatar(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <OrganizerAvatarMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
