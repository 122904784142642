import {
  arrayRemove,
  arrayUnion,
  orderBy,
  query,
  QueryDocumentSnapshot,
  refEqual,
  where,
  writeBatch,
} from 'firebase/firestore';
import _ from 'lodash';
import { Suspense, useEffect, useMemo } from 'react';
import { useFirestore, useFirestoreCollection } from 'reactfire';

import { ConversationDoc } from '../../collections/Conversations';
import { useMessagesCollectionRef } from '../../collections/Messages';
import Catch from '../../components/Catch';
import { useProfileRef } from '../../components/ProfileRefProvider';
import UserMessageItem from './TextMessage';

export type Props = {
  conversationSnap: QueryDocumentSnapshot<ConversationDoc>;
};

export function MessageListMain({ conversationSnap }: Props) {
  const messagesCollectionRef = useMessagesCollectionRef();
  const { data: messagesSnap } = useFirestoreCollection(
    query(
      messagesCollectionRef,
      where('conversationRef', '==', conversationSnap.ref),
      orderBy('createdAt', 'desc'),
    ),
  );

  const profileRef = useProfileRef();

  const conversationDoc = useMemo(() => conversationSnap.data(), [conversationSnap]);

  const firestore = useFirestore();

  useEffect(
    () => {
      if (_.some(conversationDoc.notReadByRefs, (ref) => refEqual(ref, profileRef))) {
        const batch = writeBatch(firestore);

        batch.set(conversationSnap.ref, {
          notReadByRefs: arrayRemove(profileRef),
          readByRefs: arrayUnion(profileRef),
        }, { merge: true });

        batch.commit().catch(() => { });
      }
    },
    [conversationDoc.notReadByRefs, conversationSnap.ref, firestore, profileRef],
  );

  return (
    <>
      {messagesSnap.docs.map((messageSnap) => (
        <UserMessageItem key={messageSnap.id} messageSnap={messageSnap} />
      ))}
    </>
  );
}

export default function MessageList(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <MessageListMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
