import { insightsClient } from '@algolia/client-insights';
import {
  Badge,
  Box,
  BoxProps,
  Collapse,
  Container,
  Divider,
  HStack,
  Icon,
  IconButton,
  Text,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { doc, DocumentReference } from 'firebase/firestore';
import _ from 'lodash';
import { MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LuChevronRight } from 'react-icons/lu';

import { DestinationDoc, useDestinationsCollectionRef } from '../../../collections/Destinations';
import AppLanguage from '../../../common/AppLanguage';
import DestinationAlgoliaSearchRecord from '../../../common/DestinationAlgoliaSearchRecord';
import { useInsets } from '../../../components/InsetsProvider';
import { useProfileRef } from '../../../components/ProfileRefProvider';

const algoliaInsights = insightsClient('G4ZEIPSJ7Z', '87b1a20fccf5600b3aff0754e664c2ce');

export type Props = {
  destinationRecord: DestinationAlgoliaSearchRecord;
  index: number;
  onNextClick: (destinationRef: DocumentReference<DestinationDoc>) => unknown;
  queryId: string | undefined;
} & BoxProps;

export default function InfoCell({
  destinationRecord,
  index,
  onNextClick,
  queryId,
  ...boxProps
}: Props) {
  const insets = useInsets();

  const { i18n, t } = useTranslation('TripCreateInitScreen', { keyPrefix: 'Destination.InfoCell' });
  const { t: dlt } = useTranslation('DestinationLabels');

  const { isOpen: isOpenDetails, onToggle: onToggleDetails } = useDisclosure();

  const destinationsCollectionRef = useDestinationsCollectionRef();

  const profileRef = useProfileRef();

  const handleNextClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onNextClick(
        doc(destinationsCollectionRef, destinationRecord.objectID),
      );

      if (queryId) {
        algoliaInsights.pushEvents({
          events: [
            {
              authenticatedUserToken: profileRef.id,
              eventName: 'Destination Selected',
              eventType: 'conversion',
              index: 'destinations',
              objectIDs: [destinationRecord.objectID],
              queryID: queryId,
              timestamp: Date.now(),
              userToken: profileRef.id,
            },
          ],
        }).catch(() => { });
      }
    },
    [destinationRecord.objectID, destinationsCollectionRef, onNextClick, profileRef.id, queryId],
  );

  const handleClick = useCallback(
    () => {
      onToggleDetails();

      if (queryId) {
        algoliaInsights.pushEvents({
          events: [
            {
              authenticatedUserToken: profileRef.id,
              eventName: 'Destination Details Clicked',
              eventType: 'click',
              index: 'destinations',
              objectIDs: [destinationRecord.objectID],
              positions: [index + 1],
              queryID: queryId,
              timestamp: Date.now(),
              userToken: profileRef.id,
            },
          ],
        }).catch(() => { });
      }
    },
    [index, onToggleDetails, profileRef.id, queryId, destinationRecord.objectID],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <Container>
        <Box
          _dark={{
            backgroundColor: 'rgb(from var(--chakra-colors-black) r g b / 0.5)',
          }}
          _light={{
            backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.5)',
          }}
          backdropFilter="saturate(180%) blur(20px)"
          borderRadius="2xl"
          cursor="pointer"
          onClick={handleClick}
          p={4}
        >
          <Collapse
            in={isOpenDetails}
            startingHeight="48px"
          >
            <VStack alignItems="stretch" gap={4}>
              <HStack gap={3}>
                <VStack alignItems="stretch" flex={1} gap={2}>
                  <Text fontSize="2xl" fontWeight="bold" lineHeight={1}>
                    {destinationRecord.name[i18n.language as AppLanguage]}
                  </Text>

                  <Text lineHeight={1}>
                    {_.compact([
                      destinationRecord.countryEmoji,
                      destinationRecord.countryName[i18n.language as AppLanguage],
                    ]).join(' ')}
                  </Text>
                </VStack>

                <IconButton
                  aria-label={t('applyButton.disabled')}
                  colorScheme="blue"
                  icon={<Icon as={LuChevronRight} />}
                  onClick={handleNextClick}
                  size="lg"
                  variant="solid"
                />
              </HStack>

              <Divider />

              <VStack
                alignItems="stretch"
                gap={4}
                overflow="auto"
                style={{
                  maxHeight: `calc(100vh - (${insets.top} + var(--chakra-space-4)) - (${insets.bottom} + var(--chakra-space-2)) - var(--chakra-space-12) - var(--chakra-space-16) - var(--chakra-space-1))`,
                }}
              >
                <Wrap>
                  {(destinationRecord.labels ?? []).map((label) => (
                    <Badge key={label}>{dlt(label)}</Badge>
                  ))}
                </Wrap>

                <Divider />

                <Text>
                  {destinationRecord.description[i18n.language as AppLanguage]}
                </Text>
              </VStack>
            </VStack>
          </Collapse>
        </Box>
      </Container>
    </Box>
  );
}
