import { HStack } from '@chakra-ui/react';
import { QueryDocumentSnapshot, refEqual } from 'firebase/firestore';
import _ from 'lodash';
import { useMemo } from 'react';

import { ConversationDoc } from '../../collections/Conversations';
import { useProfileRef } from '../../components/ProfileRefProvider';
import TypingParticipant from './TypingParticipant';

export type Props = {
  conversationSnap: QueryDocumentSnapshot<ConversationDoc>;
};

export default function TypingParticipants({ conversationSnap }: Props) {
  const conversationDoc = useMemo(() => conversationSnap.data(), [conversationSnap]);
  const profileRef = useProfileRef();

  const typingParticipantRefs = useMemo(
    () => _.filter(conversationDoc.typingParticipantRefs, (ref) => !refEqual(ref, profileRef)),
    [conversationDoc.typingParticipantRefs, profileRef],
  );

  if (!typingParticipantRefs.length) {
    return null;
  }

  return (
    <HStack>
      {typingParticipantRefs.map((typingParticipantRef) => (
        <TypingParticipant key={typingParticipantRef.id} profileRef={typingParticipantRef} />
      ))}
    </HStack>
  );
}
