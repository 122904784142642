import {
  Box,
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import {
  ChangeEvent,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuSearch } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

import { DestinationDoc } from '../../collections/Destinations';
import Catch from '../../components/Catch';
import InsetsProvider, { useInsets } from '../../components/InsetsProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import DestinationList from './DestinationList';

export function TripCreateScreenMain() {
  const insets = useInsets();

  const { t } = useTranslation('TripCreateScreen');

  const navigate = useNavigate();

  const onNextClick = useCallback(
    (destinationRef: DocumentReference<DestinationDoc>) => {
      navigate(`./${destinationRef.id}`);
    },
    [navigate],
  );

  const [queryInputValue, setQueryInputValue] = useState<string>('');
  const [query, setQuery] = useState<string>(queryInputValue);

  const onQueryInputValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setQueryInputValue(e.target.value);
    },
    [],
  );

  useEffect(
    () => {
      const timeout = setTimeout(
        () => setQuery(queryInputValue),
        600,
      );

      return () => {
        clearTimeout(timeout);
      };
    },
    [queryInputValue],
  );

  return (
    <Box
      h="100%"
      position="relative"
    >
      <Box
        left={0}
        position="absolute"
        right={0}
        style={{
          top: insets.top,
        }}
        zIndex={10}
      >
        <Container>
          <HStack gap={3}>
            <Heading
              flex={1}
              fontSize="4xl"
              lineHeight="40px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {t('title')}
            </Heading>
          </HStack>
        </Container>
      </Box>

      <Box
        left={0}
        position="absolute"
        right={0}
        style={{
          top: `calc(${insets.top} + var(--chakra-space-10) + var(--chakra-space-2))`,
        }}
        zIndex={20}
      >
        <Container>
          <InputGroup
            size="lg"
          >
            <InputLeftElement>
              <Icon as={LuSearch} />
            </InputLeftElement>

            <Input
              _dark={{
                backgroundColor: 'rgb(from var(--chakra-colors-black) r g b / 0.5)',
              }}
              _light={{
                backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.5)',
              }}
              backdropFilter="saturate(180%) blur(20px)"
              border="none"
              onChange={onQueryInputValueChange}
              placeholder="Bali, Indonesia"
              value={queryInputValue}
            />
          </InputGroup>
        </Container>
      </Box>

      <InsetsProvider
        bottom={insets.bottom}
        left={insets.left}
        right={insets.right}
        top={`calc(${insets.top} + var(--chakra-space-10) + var(--chakra-space-2) + var(--chakra-space-12))`}
      >
        <DestinationList onNextClick={onNextClick} query={query} />
      </InsetsProvider>
    </Box>
  );
}

export default function TripCreateScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <TripCreateScreenMain />
      </Suspense>
    </Catch>
  );
}
