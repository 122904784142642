/* eslint-disable react/require-default-props */
import {
  Button,
  Center,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Role from '../../common/Role';
import Catch from '../../components/Catch';
import RoleFormControl from '../../components/RoleFormControl';
import ErrorFallbackScreen from '../ErrorFallbackScreen';

export type Props = {
  onComplete: (values: {
    role: Role,
  }) => void;
  role?: Role;
};

export function RoleFormMain({
  onComplete,
  role,
}: Props) {
  const { t } = useTranslation('OnboardingRoleScreen', { keyPrefix: 'RoleForm' });

  const schema = useMemo(
    () => yup.object().shape({
      role: yup
        .string()
        .label(t('role.label'))
        .oneOf(Object.values(Role))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      role: role ?? Role.HOT,
    }),
    [role],
  );

  const handleFormSubmit = useCallback(
    (values: typeof schema['__outputType']) => {
      onComplete(values);
    },
    [onComplete],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          flex={1}
          gap={4}
          h="100%"
          minH={0}
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
        >
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <RoleFormControl
              label={t('role.label')}
              name="role"
            />
          </VStack>

          <Button
            isLoading={isSubmitting}
            loadingText={t('nextButton.loading')}
            type="submit"
            width="100%"
          >
            {t('nextButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}

export default function RoleForm(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <RoleFormMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
