import {
  Button,
  Center,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { LuAlertTriangle, LuRefreshCw } from 'react-icons/lu';

export default function ErrorFallbackScreen() {
  return (
    <Center h="100%">
      <VStack>
        <Icon as={LuAlertTriangle} boxSize={10} />
        <Text>Unknown error</Text>
        <Button
          leftIcon={<Icon as={LuRefreshCw} />}
          onClick={() => window.location.reload()}
        >
          Reload
        </Button>
      </VStack>
    </Center>
  );
}
