import {
  Box,
  Center,
  Container,
  Heading,
  HStack,
  Spinner,
} from '@chakra-ui/react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import Catch from '../../components/Catch';
import InsetsProvider, { useInsets } from '../../components/InsetsProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import ApplicationList from './ApplicationList';

export function ApplicationsScreenMain() {
  const insets = useInsets();

  const { t } = useTranslation('ApplicationsScreen');

  return (
    <Box
      h="100%"
      position="relative"
    >
      <Box
        left={0}
        position="absolute"
        right={0}
        style={{
          top: insets.top,
        }}
        zIndex={10}
      >
        <Container>
          <HStack gap={3}>
            <Heading
              flex={1}
              fontSize="4xl"
              lineHeight="40px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {t('title')}
            </Heading>
          </HStack>
        </Container>
      </Box>

      <InsetsProvider
        bottom={insets.bottom}
        left={insets.left}
        right={insets.right}
        top={`calc(${insets.top} + var(--chakra-space-10))`}
      >
        <ApplicationList />
      </InsetsProvider>
    </Box>
  );
}

export default function ApplicationsScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <ApplicationsScreenMain />
      </Suspense>
    </Catch>
  );
}
