import { Button, VStack } from '@chakra-ui/react';
import { logEvent, setUserProperties } from 'firebase/analytics';
import {
  doc,
  getDoc,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAnalytics, useFirestore } from 'reactfire';
import * as yup from 'yup';

import { useCitiesCollectionRef } from '../../collections/Cities';
import AppLanguage from '../../common/AppLanguage';
import dateToZodiac from '../../common/dateToZodiac';
import Gender from '../../common/Gender';
import Language from '../../common/Language';
import Sexuality from '../../common/Sexuality';
import { StoragePicture } from '../../common/StoragePicture';
import CityFormControl from '../../components/CityFormControl';
import GenderFormControl from '../../components/GenderFormControl';
import LanguagesFormControl from '../../components/LanguagesFormControl';
import NumberFormControl from '../../components/NumberFormControl';
import PicturesFormControl from '../../components/PicturesFormControl';
import { useProfile } from '../../components/ProfileProvider';
import SexualityFormControl from '../../components/SexualityFormControl';
import TextFormControl from '../../components/TextFormControl';
import useTranslateProfileProperties from '../../functions/translateProfileProperties';

export default function ProfileForm() {
  const { i18n, t } = useTranslation('ProfileScreen', { keyPrefix: 'ProfileForm' });

  const { profileHiddenSnap, profileSnap } = useProfile();

  const profileDoc = useMemo(
    () => profileSnap.data(),
    [profileSnap],
  );

  const settingsDoc = useMemo(
    () => profileHiddenSnap.data(),
    [profileHiddenSnap],
  );

  const schema = useMemo(
    () => yup.object().shape({
      birthDate: yup
        .string()
        .label(t('birthDate.label'))
        .required(),
      gender: yup
        .string()
        .label(t('gender.label'))
        .oneOf(Object.values(Gender))
        .required(),
      height: yup
        .number()
        .label(t('height.label'))
        .integer()
        .min(30)
        .max(300)
        .required(),
      languages: yup
        .array(yup
          .string()
          .label(t('languages.item.label'))
          .oneOf(Object.values(Language))
          .required())
        .label(t('languages.label'))
        .min(1)
        .required(),
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
      originId: yup
        .string()
        .label(t('originId.label'))
        .required(),
      pictures: yup
        .array()
        .label(t('pictures.label'))
        .compact()
        .min(1)
        .max(6)
        .required()
        .of(yup
          .object()
          .label(t('pictures.item.label'))
          .required()
          .shape({
            blurHash: yup.string(),
            imgixUrl: yup.string().required(),
            storageRef: yup.string().required(),
          })),
      sexuality: yup
        .string()
        .label(t('sexuality.label'))
        .oneOf(Object.values(Sexuality))
        .required(),
      weight: yup
        .number()
        .label(t('weight.label'))
        .integer()
        .min(30)
        .max(300)
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      birthDate: settingsDoc.birthDate,
      gender: profileDoc.gender,
      height: profileDoc.height,
      languages: profileDoc.languages,
      name: profileDoc.name,
      originId: profileDoc.originRef.id,
      pictures: profileDoc.pictures,
      sexuality: profileDoc.sexuality,
      weight: profileDoc.weight,
    }),
    [
      profileDoc,
      settingsDoc,
    ],
  );

  const firestore = useFirestore();
  const citiesCollectionRef = useCitiesCollectionRef();

  const analytics = useAnalytics();
  const navigate = useNavigate();

  const translateProfileProperties = useTranslateProfileProperties();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      const originRef = doc(citiesCollectionRef, values.originId);
      const originSnap = await getDoc(originRef);
      const originDoc = originSnap.data();

      if (!originSnap.exists() || !originDoc) {
        throw new Error('City not found');
      }

      const birthDate = moment.utc(values.birthDate).startOf('day');

      const age = Math.floor(
        moment.duration(
          moment().diff(birthDate),
        ).asYears(),
      );

      const zodiac = dateToZodiac(birthDate.toDate());

      const pictures: StoragePicture[] = _.compact(values.pictures);

      const batch = writeBatch(firestore);

      const { data: translations } = await translateProfileProperties({
        gender: values.gender,
        language: i18n.language as AppLanguage,
        name: values.name,
      });

      batch.set(
        profileSnap.ref,
        {
          age,
          expiresAt: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 365),
          gender: values.gender,
          height: values.height,
          languages: values.languages,
          name: values.name,
          originRef,
          pictures,
          sexuality: values.sexuality,
          translations,
          updatedAt: Timestamp.now(),
          weight: values.weight,
          zodiac,
        },
        { merge: true },
      );

      batch.set(
        profileHiddenSnap.ref,
        {
          birthDate: birthDate.format('YYYY-MM-DD'),
          timezone: originDoc.timezone,
        },
        { merge: true },
      );

      await batch.commit();

      logEvent(analytics, 'update_profile');
      setUserProperties(analytics, {
        age,
        languages: values.languages,
        sexuality: values.sexuality,
        zodiac,
      });

      navigate('..');
    },
    [
      analytics,
      citiesCollectionRef,
      firestore,
      i18n.language,
      navigate,
      profileHiddenSnap.ref,
      profileSnap.ref,
      translateProfileProperties,
    ],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <VStack alignItems="stretch" gap={4} h="100%">
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <PicturesFormControl
              label={t('pictures.label')}
              name="pictures"
            />

            <TextFormControl
              autoComplete="given-name"
              label={t('name.label')}
              name="name"
              type="text"
            />

            <CityFormControl
              label={t('originId.label')}
              name="originId"
              placeholder={t('originId.placeholder')}
            />

            <GenderFormControl
              label={t('gender.label')}
              name="gender"
            />

            <SexualityFormControl
              gender={profileDoc.gender}
              label={t('sexuality.label')}
              name="sexuality"
            />

            <TextFormControl
              autoComplete="bday"
              label={t('birthDate.label')}
              name="birthDate"
              type="date"
            />

            <NumberFormControl
              label={t('height.label')}
              max={250}
              min={150}
              name="height"
              right={t('height.unit')}
              step={1}
            />

            <NumberFormControl
              label={t('weight.label')}
              max={120}
              min={45}
              name="weight"
              right={t('weight.unit')}
              step={1}
            />

            <LanguagesFormControl
              label={t('languages.label')}
              name="languages"
              placeholder={t('languages.placeholder')}
            />
          </VStack>

          <Button
            isLoading={isSubmitting}
            loadingText={t('updateProfileButton.loading')}
            onClick={() => handleSubmit()}
          >
            {t('updateProfileButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
