import { DocumentReference } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { ProfileDoc } from '../collections/Profiles';

type Props = {
  profileRef: DocumentReference<ProfileDoc>;
};

const ProfileRefContext = createContext<DocumentReference<ProfileDoc> | null>(null);

export const useProfileRef = () => {
  const ctx = useContext(ProfileRefContext);

  if (!ctx) {
    throw new Error('ProfileRefContext');
  }

  return ctx;
};

export default function ProfileRefProvider({
  children,
  profileRef,
}: PropsWithChildren<Props>) {
  return (
    <ProfileRefContext.Provider value={profileRef}>
      {children}
    </ProfileRefContext.Provider>
  );
}
