import {
  Card,
  Center,
  HStack,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreCollection } from 'reactfire';

import { ApplicationStatus, useApplicationsCollectionRef } from '../../collections/Applications';
import Catch from '../../components/Catch';
import { useProfileRef } from '../../components/ProfileRefProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Application from './Application';

export function ApplicationListMain() {
  const { t } = useTranslation('ConversationsScreen', { keyPrefix: 'ApplicationList' });
  const profileRef = useProfileRef();

  const applicationsCollectionRef = useApplicationsCollectionRef();
  const { data: applicationsSnap } = useFirestoreCollection(
    query(
      applicationsCollectionRef,
      where('organizerRef', '==', profileRef),
      where('status', '==', ApplicationStatus.SENT),
      orderBy('sentAt', 'desc'),
    ),
  );

  return (
    <VStack alignItems="stretch">
      <Text size="sm">
        {t('title')}
      </Text>

      <HStack
        gap={4}
        minW={0}
        overflowX="scroll"
        overflowY="hidden"
        pb={2}
        scrollSnapType="x mandatory"
      >
        {applicationsSnap.docs.map((applicationSnap) => (
          <Application applicationSnap={applicationSnap} key={applicationSnap.id} />
        ))}

        {new Array(Math.max(7 - applicationsSnap.docs.length, 0)).fill(null).map((_, index) => (
          <Card
            flexGrow={0}
            flexShrink={0}
            h="160px"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            w="90px"
          />
        ))}
      </HStack>
    </VStack>
  );
}

export default function ApplicationList() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <ApplicationListMain />
      </Suspense>
    </Catch>
  );
}
