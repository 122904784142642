import {
  Badge,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import { useField } from 'formik';
import _ from 'lodash';
import {
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { LuFileEdit } from 'react-icons/lu';
import { useFirestoreDocData } from 'reactfire';

import { useDestinationsCollectionRef } from '../../collections/Destinations';
import Activity from '../../common/Activity';
import useGenerateTripActivities from '../../functions/generateTripActivities';
import useShowError from '../../hooks/useShowError';
import Catch from '../Catch';

export type Props = {
  destinationId: string;
  label: string;
  name: string;
  placeholder: string;
};

export function ActivitiesFormControlMain({
  destinationId,
  label,
  name,
  placeholder,
}: Props) {
  const showError = useShowError();
  const [input, meta, helper] = useField<string[]>(name);

  const destinationsCollectionRef = useDestinationsCollectionRef();
  const { data: destinationDoc } = useFirestoreDocData(
    doc(destinationsCollectionRef, destinationId),
  );

  const [activitiesResult, setActivitiesResult] = useState<string[]>(
    destinationDoc.activities ?? Object.values(Activity),
  );

  const [loading, setLoading] = useState<boolean>(false);

  const generateTripActivities = useGenerateTripActivities();
  useEffect(
    () => {
      if (!destinationDoc.activities?.length) {
        setLoading(true);

        generateTripActivities({
          destinationId,
        })
          .then(
            ({ data }) => {
              setActivitiesResult(data.activities);
              setLoading(false);
            },
          )
          .catch((err) => {
            showError(err);
            setLoading(false);
          });
      }
    },
    [destinationDoc.activities?.length, destinationId, generateTripActivities, showError],
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleChange = useCallback(
    (keys: string[]) => {
      helper.setValue(keys).catch(showError);
    },
    [helper, showError],
  );

  return (
    <>
      <FormControl isInvalid={!!meta.error}>
        <FormLabel>{label}</FormLabel>

        <VStack alignItems="stretch">
          <Box
            borderRadius="md"
            cursor="pointer"
            onClick={onOpen}
            px={4}
            py={2}
            style={{
              border: '1px solid',
              borderColor: 'inherit',
            }}
          >
            <HStack spacing={5}>
              {input.value.length ? (
                <Wrap>
                  {input.value.map((activity) => (
                    <Badge key={activity}>
                      {activity}
                    </Badge>
                  ))}
                </Wrap>
              ) : (
                <Text flex={1}>
                  {placeholder}
                </Text>
              )}

              <Icon as={LuFileEdit} />
            </HStack>
          </Box>
        </VStack>

        <FormErrorMessage>
          {meta.error}
        </FormErrorMessage>
      </FormControl>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay
          backdropFilter="saturate(180%) blur(20px)"
          backgroundColor="rgb(from var(--chakra-colors-chakra-body-bg) r g b / 0.5)"
        />

        <ModalContent
          mx={4}
        >
          <ModalHeader>
            Activities
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody
            maxH="calc(100vh - 262px)"
            overflow="auto"
          >
            {loading ? (
              <Spinner />
            ) : (
              <CheckboxGroup
                onChange={handleChange}
                value={input.value}
              >
                <VStack alignItems="stretch" spacing={3}>
                  {_.uniq(activitiesResult).map((activity) => (
                    <Checkbox key={activity} value={activity}>
                      <Badge>
                        {activity}
                      </Badge>
                    </Checkbox>
                  ))}
                </VStack>
              </CheckboxGroup>
            )}
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant="ghost">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default function ActivitiesFormControl(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ActivitiesFormControlMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
