import {
  Badge,
  Card,
  CardBody,
  HStack,
  Image,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { TripDoc } from '../../../collections/Trips';
import AppLanguage from '../../../common/AppLanguage';
import { getPhotoSizeUrl } from '../../../common/getPhotoSizeUrl';
import Catch from '../../../components/Catch';
import useBlur from '../../../hooks/useBlur';

export type Props = {
  tripSnap: QueryDocumentSnapshot<TripDoc>;
};

export function TripMain({ tripSnap }: Props) {
  const { i18n, t } = useTranslation('TripsScreen', { keyPrefix: 'Trip' });
  const { t: tdt } = useTranslation('TripDuration');

  const tripDoc = useMemo(() => tripSnap.data(), [tripSnap]);

  const { data: destinationDoc } = useFirestoreDocData(tripDoc.destinationRef);

  const src = useMemo(
    () => getPhotoSizeUrl({
      height: 320,
      uri: tripDoc.picture.urls.raw,
      width: 180,
    }),
    [tripDoc.picture.urls.raw],
  );

  const fallbackSrc = useBlur({
    blurHash: tripDoc.picture.blur_hash,
    height: 32,
    width: 18,
  });

  return (
    <Card as={Link} cursor="pointer" to={`/trips/${tripSnap.id}`}>
      <CardBody py={3}>
        <HStack alignItems="flex-start" spacing={4}>
          <Image
            borderLeftRadius="md"
            fallbackSrc={fallbackSrc}
            h={160}
            ml={-5}
            my={-3}
            src={src}
            w={90}
          />

          <VStack alignItems="stretch">
            <HStack flex={1} spacing={4} userSelect="none">
              <VStack alignItems="stretch" flex={1} spacing={1}>
                <Text fontSize="sm" lineHeight={1}>
                  {destinationDoc.name[i18n.language as AppLanguage]}
                </Text>
                <Text fontSize="xs" lineHeight={1}>
                  {_.compact([
                    destinationDoc.countryEmoji,
                    destinationDoc.countryName[i18n.language as AppLanguage],
                  ]).join(' ')}
                </Text>
              </VStack>
            </HStack>

            <HStack>
              <Badge>
                {tdt(tripDoc.duration)}
              </Badge>
              <Text>
                {t('departure', {
                  departure: moment(tripDoc.departure).toDate(),
                  formatParams: { departure: { day: 'numeric', month: 'long' } },
                })}
              </Text>
            </HStack>

            <Text fontSize="xs" lineHeight={1} noOfLines={5}>
              {tripDoc.description}
            </Text>
          </VStack>
        </HStack>
      </CardBody>
    </Card>
  );
}

export function TripLoading() {
  return (
    <Skeleton h="160px" w="100%" />
  );
}

export default function Trip(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={<TripLoading />}>
        <TripMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
