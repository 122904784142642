import { insightsClient } from '@algolia/client-insights';
import {
  Box,
  Center,
  Container,
  Spinner,
  Text,
} from '@chakra-ui/react';
import algoliasearch from 'algoliasearch';
import {
  Suspense,
  UIEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreDocData } from 'reactfire';

import { getProfileHiddenRef } from '../../collections/Profiles';
import { TripStatus } from '../../collections/Trips';
import Gender from '../../common/Gender';
import Role from '../../common/Role';
import Sexuality from '../../common/Sexuality';
import SexualityGender from '../../common/SexualityGender';
import TripAlgoliaSearchRecord from '../../common/TripAlgoliaSearchRecord';
import Catch from '../../components/Catch';
import { useProfileRef } from '../../components/ProfileRefProvider';
import useShowError from '../../hooks/useShowError';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Trip from './Trip';

const client = algoliasearch('G4ZEIPSJ7Z', '87b1a20fccf5600b3aff0754e664c2ce');
const tripsIndex = client.initIndex('trips');

const algoliaInsights = insightsClient('G4ZEIPSJ7Z', '87b1a20fccf5600b3aff0754e664c2ce');

export function TripListMain() {
  const { t } = useTranslation('HomeScreen');
  const showError = useShowError();

  const profileRef = useProfileRef();
  const { data: profileDoc } = useFirestoreDocData(profileRef);

  const filters = useMemo(
    () => {
      const nextFilters: (string | string[])[] = [];
      nextFilters.push(`status: ${TripStatus.PUBLISHED}`);

      if (profileDoc) {
        const organizerLanguagesFilter: string[] = profileDoc.languages.map(
          (lng) => `organizer.languages: ${lng}`,
        );
        if (organizerLanguagesFilter.length) {
          nextFilters.push(`(${organizerLanguagesFilter.join(' OR ')})`);
        }

        const applicantSexualityGender: SexualityGender = `${profileDoc.sexuality}_${profileDoc.gender}`;

        switch (applicantSexualityGender) {
          case `${Sexuality.STRAIGHT}_${Gender.MALE}`: {
            nextFilters.push(`(${[
              `organizer.sexualityGender: ${Sexuality.STRAIGHT}_${Gender.FEMALE}`,
              `organizer.sexualityGender: ${Sexuality.BISEXUAL}_${Gender.FEMALE}`,
            ].join(' OR ')})`);
            break;
          }
          case `${Sexuality.STRAIGHT}_${Gender.FEMALE}`: {
            nextFilters.push(`(${[
              `organizer.sexualityGender: ${Sexuality.STRAIGHT}_${Gender.MALE}`,
              `organizer.sexualityGender: ${Sexuality.BISEXUAL}_${Gender.MALE}`,
            ].join(' OR ')})`);
            break;
          }
          case `${Sexuality.BISEXUAL}_${Gender.MALE}`: {
            nextFilters.push(`(${[
              `organizer.sexualityGender: ${Sexuality.STRAIGHT}_${Gender.FEMALE}`,
              `organizer.sexualityGender: ${Sexuality.BISEXUAL}_${Gender.FEMALE}`,
              `organizer.sexualityGender: ${Sexuality.BISEXUAL}_${Gender.MALE}`,
              `organizer.sexualityGender: ${Sexuality.GAY}_${Gender.MALE}`,
            ].join(' OR ')})`);
            break;
          }
          case `${Sexuality.BISEXUAL}_${Gender.FEMALE}`: {
            nextFilters.push(`(${[
              `organizer.sexualityGender: ${Sexuality.STRAIGHT}_${Gender.MALE}`,
              `organizer.sexualityGender: ${Sexuality.BISEXUAL}_${Gender.MALE}`,
              `organizer.sexualityGender: ${Sexuality.BISEXUAL}_${Gender.FEMALE}`,
              `organizer.sexualityGender: ${Sexuality.GAY}_${Gender.FEMALE}`,
            ].join(' OR ')})`);
            break;
          }
          case `${Sexuality.GAY}_${Gender.MALE}`: {
            nextFilters.push(`(${[
              `organizer.sexualityGender: ${Sexuality.BISEXUAL}_${Gender.MALE}`,
              `organizer.sexualityGender: ${Sexuality.GAY}_${Gender.MALE}`,
            ].join(' OR ')})`);
            break;
          }
          case `${Sexuality.GAY}_${Gender.FEMALE}`: {
            nextFilters.push(`(${[
              `organizer.sexualityGender: ${Sexuality.BISEXUAL}_${Gender.FEMALE}`,
              `organizer.sexualityGender: ${Sexuality.GAY}_${Gender.FEMALE}`,
            ].join(' OR ')})`);
            break;
          }
          default: {
            break;
          }
        }

        if (profileDoc.role === Role.RICH) {
          nextFilters.push(`organizer.role: ${Role.HOT}`);
        } else if (profileDoc.role === Role.HOT) {
          nextFilters.push(`organizer.role: ${Role.RICH}`);
        }
      }

      return nextFilters.join(' AND ');
    },
    [profileDoc],
  );
  const { data: profileHiddenDoc } = useFirestoreDocData(getProfileHiddenRef(profileRef));

  const optionalFilters = useMemo(
    () => {
      const nextOptionalFilters: (string | string[])[] = [];

      if (profileHiddenDoc) {
        nextOptionalFilters.push(`organizer.age: ${profileHiddenDoc.preferences.age.min} TO ${profileHiddenDoc.preferences.age.max}`);
        nextOptionalFilters.push(`organizer.height: ${profileHiddenDoc.preferences.height.min} TO ${profileHiddenDoc.preferences.height.max}`);
        nextOptionalFilters.push(`organizer.weight: ${profileHiddenDoc.preferences.weight.min} TO ${profileHiddenDoc.preferences.weight.max}`);
      }

      return nextOptionalFilters.join(' AND ');
    },
    [profileHiddenDoc],
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [trips, setTrips] = useState<TripAlgoliaSearchRecord[]>([]);
  const [queryId, setQueryId] = useState<string | undefined>(undefined);

  useEffect(
    () => {
      setIsLoading(true);
      tripsIndex.search<TripAlgoliaSearchRecord>('', {
        clickAnalytics: true,
        // enablePersonalization: true,
        filters,
        length: 1000,
        offset: 0,
        optionalFilters,
        userToken: profileRef.id,
      }).finally(() => {
        setIsLoading(false);
      }).then((response) => {
        setTrips(response.hits);
        setQueryId(response.queryID);
      }).catch(showError);
    },
    [filters, optionalFilters, profileRef.id, showError],
  );

  const { height, width } = useWindowDimensions();

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      setCurrentIndex(Math.round(e.currentTarget.scrollTop / height));
    },
    [height],
  );

  useEffect(
    () => {
      if (trips[currentIndex]) {
        algoliaInsights.pushEvents({
          events: [
            {
              authenticatedUserToken: profileRef.id,
              eventName: 'Trip View',
              eventType: 'view',
              index: 'trips',
              objectIDs: [trips[currentIndex].objectID],
              timestamp: Date.now(),
              userToken: profileRef.id,
            },
          ],
        }).catch(() => { });
      }
    },
    [currentIndex, profileRef.id, trips],
  );

  if (trips.length) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        h={height}
        onScroll={handleScroll}
        overflowX="hidden"
        overflowY="scroll"
        overscrollBehavior="none"
        scrollSnapType="y mandatory"
        w={width}
      >
        {trips.map((trip, index) => (
          Math.abs(index - currentIndex) < 10
            ? (
              <Trip
                flexBasis={height}
                flexGrow={0}
                flexShrink={0}
                height={height}
                index={index}
                key={trip.objectID}
                profileExists={!!profileDoc}
                queryId={queryId}
                scrollSnapAlign="center"
                scrollSnapStop="always"
                tripRecord={trip}
                width={width}
              />
            )
            : (
              <Box
                flexBasis={height}
                flexGrow={0}
                flexShrink={0}
                height={height}
                key={trip.objectID}
                scrollSnapAlign="center"
                scrollSnapStop="always"
                width={width}
              />
            )
        ))}
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Container height="100%">
        <Center height="100%">
          <Spinner size="xl" />
        </Center>
      </Container>
    );
  }

  return (
    <Container height="100%">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export default function TripList() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <TripListMain />
      </Suspense>
    </Catch>
  );
}
