import {
  Center,
  Container,
  Heading,
  HStack,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import { Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useDestinationsCollectionRef } from '../../collections/Destinations';
import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import DestinationForm from './DestinationForm';

export function DestinationUpdateScreenMain() {
  const insets = useInsets();

  const { t } = useTranslation('DestinationUpdateScreen');

  const { destinationId } = useParams<{ destinationId: string }>();

  const destinationsCollectionRef = useDestinationsCollectionRef();
  const destinationRef = useMemo(
    () => doc(destinationsCollectionRef, destinationId),
    [destinationId, destinationsCollectionRef],
  );

  const navigate = useNavigate();
  const onComplete = useCallback(
    () => {
      navigate('../add');
    },
    [navigate],
  );

  return (
    <VStack alignItems="stretch" gap={4} h="100%">
      <Container
        style={{
          paddingTop: insets.top,
        }}
      >
        <HStack gap={3}>
          <Heading
            flex={1}
            fontSize="4xl"
            lineHeight="40px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {t('title')}
          </Heading>
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <DestinationForm destinationRef={destinationRef} onComplete={onComplete} />
      </Container>
    </VStack>
  );
}

export default function DestinationUpdateScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <DestinationUpdateScreenMain />
      </Suspense>
    </Catch>
  );
}
