import {
  Container,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
} from '@chakra-ui/react';
import {
  arrayRemove,
  arrayUnion,
  doc,
  QueryDocumentSnapshot,
  refEqual,
  setDoc,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import _ from 'lodash';
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LuSendHorizonal } from 'react-icons/lu';
import { useFirestore } from 'reactfire';

import { ConversationDoc } from '../../collections/Conversations';
import { MessageType, useMessagesCollectionRef } from '../../collections/Messages';
import { useProfileRef } from '../../components/ProfileRefProvider';
import useShowError from '../../hooks/useShowError';

export type Props = {
  conversationSnap: QueryDocumentSnapshot<ConversationDoc>;
};

export default function MessageInput({ conversationSnap }: Props) {
  const messagesCollectionRef = useMessagesCollectionRef();
  const profileRef = useProfileRef();

  const [value, setValue] = useState<string>('');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [],
  );

  const handleFocus = useCallback(
    () => {
      setDoc(
        conversationSnap.ref,
        { typingParticipantRefs: arrayUnion(profileRef) },
        { merge: true },
      ).catch(() => { });
    },
    [conversationSnap.ref, profileRef],
  );

  const handleBlur = useCallback(
    () => {
      setDoc(
        conversationSnap.ref,
        { typingParticipantRefs: arrayRemove(profileRef) },
        { merge: true },
      ).catch(() => { });
    },
    [conversationSnap.ref, profileRef],
  );

  useEffect(
    () => {
      const handler = () => {
        setDoc(
          conversationSnap.ref,
          { typingParticipantRefs: arrayRemove(profileRef) },
          { merge: true },
        ).catch(() => { });
      };

      window.addEventListener('blur', handler);

      return () => {
        window.removeEventListener('blur', handler);
      };
    },
    [conversationSnap.ref, profileRef],
  );

  const showError = useShowError();

  const [isSending, setSending] = useState<boolean>(false);

  const conversationDoc = useMemo(() => conversationSnap.data(), [conversationSnap]);

  const firestore = useFirestore();

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLDivElement>) => {
      e.preventDefault();
      setSending(true);

      const batch = writeBatch(firestore);

      batch.set(
        doc(messagesCollectionRef),
        {
          _v: 1,
          conversationRef: conversationSnap.ref,
          createdAt: Timestamp.now(),
          notReadByRefs: _.filter(
            conversationDoc.participantRefs,
            (ref) => !refEqual(ref, profileRef),
          ),
          readByRefs: [],
          senderRef: profileRef,
          text: value,
          type: MessageType.TEXT,
          updatedAt: Timestamp.now(),
        },
      );

      batch.set(
        conversationSnap.ref,
        {
          lastActionAt: Timestamp.now(),
          notReadByRefs: arrayUnion(..._.filter(
            conversationDoc.participantRefs,
            (ref) => !refEqual(ref, profileRef),
          )),
        },
        { merge: true },
      );

      batch
        .commit()
        .finally(() => setSending(false))
        .catch(showError);

      setValue('');
    },
    [
      conversationDoc.participantRefs,
      conversationSnap.ref,
      firestore,
      messagesCollectionRef,
      profileRef,
      showError,
      value,
    ],
  );

  return (
    <Container>
      <VStack as="form" onSubmit={handleSubmit}>
        <InputGroup size="lg">
          <Input
            enterKeyHint="send"
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            pr="3rem"
            value={value}
          />

          <InputRightElement width="3rem">
            <IconButton
              aria-label="Send"
              h="2.5rem"
              icon={<Icon as={LuSendHorizonal} />}
              isLoading={isSending}
              size="sm"
              type="submit"
              variant="ghost"
              w="2.5rem"
            />
          </InputRightElement>
        </InputGroup>
      </VStack>
    </Container>
  );
}
