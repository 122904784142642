import { Skeleton, Text } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreDocData } from 'reactfire';

import { ProfileDoc } from '../../collections/Profiles';
import AppLanguage from '../../common/AppLanguage';
import Catch from '../../components/Catch';

export type Props = {
  profileRef: DocumentReference<ProfileDoc>;
};

export function TypingParticipantMain({ profileRef }: Props) {
  const { i18n, t } = useTranslation('ConversationScreen', { keyPrefix: 'TypingParticipant' });

  const { data: profileDoc } = useFirestoreDocData(profileRef);

  return (
    <Text color="gray.500" fontSize="xs" fontStyle="italic">
      {t('participantTyping', { name: profileDoc.translations?.name[i18n.language as AppLanguage] ?? profileDoc.name })}
    </Text>
  );
}

export default function TypingParticipant(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={<Skeleton h={3} w="100px" />}>
        <TypingParticipantMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
