import { Icon, IconButton } from '@chakra-ui/react';
import { LuMessageSquare } from 'react-icons/lu';

export default function ActionCell() {
  return (
    <IconButton
      aria-label=""
      icon={<Icon as={LuMessageSquare} />}
      isDisabled
      size="lg"
      variant="solid"
    />
  );
}
