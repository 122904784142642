import {
  Button,
  Icon,
  Text,
  useColorMode,
  VStack,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LuMoon, LuSun } from 'react-icons/lu';

export default function ColorModeSelector() {
  const { t } = useTranslation('SettingsScreen', { keyPrefix: 'SettingsMenu.ColorModeSelector' });

  const { colorMode, setColorMode } = useColorMode();

  const onChange = useCallback(
    (language: string | string[]) => {
      setColorMode(language);
    },
    [setColorMode],
  );

  return (
    <VStack alignItems="stretch">
      <Text fontSize="sm">
        {t('title')}
      </Text>

      <Button
        isActive={colorMode === 'dark'}
        justifyContent="start"
        leftIcon={<Icon as={LuMoon} />}
        onClick={() => onChange('dark')}
        variant="ghost"
      >
        {t('dark')}
      </Button>

      <Button
        isActive={colorMode === 'light'}
        justifyContent="start"
        leftIcon={<Icon as={LuSun} />}
        onClick={() => onChange('light')}
        variant="ghost"
      >
        {t('light')}
      </Button>
    </VStack>
  );
}
