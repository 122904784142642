import {
  Button,
  Center,
  Container,
  HStack,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Catch from '../../components/Catch';
import ErrorFallbackScreen from '../ErrorFallbackScreen';

export function ProfileFallbackScreenMain() {
  const { t } = useTranslation('ProfileFallbackScreen');

  return (
    <Container height="100%" overflowY="auto" py={4}>
      <Center height="100%">
        <VStack alignItems="stretch">
          <HStack>
            <Text textAlign="center">{t('body')}</Text>
          </HStack>

          <Button as={Link} to="/onboarding">
            {t('createProfileButton.default')}
          </Button>
        </VStack>
      </Center>
    </Container>
  );
}

export default function ProfileFallbackScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <ProfileFallbackScreenMain />
      </Suspense>
    </Catch>
  );
}
