import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import { ContinentDoc } from './Continent';
import { CurrencyDoc } from './Currency';

export interface CountryDoc {
  _v: 1;
  capital?: string;
  continentName?: string;
  continentRef?: DocumentReference<ContinentDoc>;
  countryIso2: string;
  countryIso3?: string;
  countryIsoNumeric?: number;
  currencyName?: string;
  currencyRef?: DocumentReference<CurrencyDoc>;
  emoji?: string;
  fipsCode?: string;
  name?: string;
  phonePrefix?: string;
  population?: number;
  updatedAt?: Timestamp;
}

export const isCountryDoc = (
  data?: DocumentData,
): data is CountryDoc => true;

export const isCountryRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<CountryDoc> => ref.parent.id === 'countries';

function isCountrySnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
CountryDoc
>;
function isCountrySnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
CountryDoc
>;
function isCountrySnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
CountryDoc
> | QueryDocumentSnapshot<
CountryDoc
> {
  return isCountryRef(snap.ref);
}

export { isCountrySnap };

export const useCountriesCollectionRef = () => {
  const firestore = useFirestore();
  return collection(firestore, 'countries') as CollectionReference<CountryDoc>;
};
