import { insightsClient } from '@algolia/client-insights';
import {
  Box,
  Center,
  Container,
  Spinner,
  Text,
} from '@chakra-ui/react';
import algoliasearch from 'algoliasearch';
import { DocumentReference } from 'firebase/firestore';
import {
  Suspense,
  UIEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { DestinationDoc } from '../../collections/Destinations';
import DestinationAlgoliaSearchRecord from '../../common/DestinationAlgoliaSearchRecord';
import Catch from '../../components/Catch';
import { useProfileRef } from '../../components/ProfileRefProvider';
import useShowError from '../../hooks/useShowError';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Destination from './Destination';

const client = algoliasearch('G4ZEIPSJ7Z', '87b1a20fccf5600b3aff0754e664c2ce');
const destinationsIndex = client.initIndex('destinations');

const algoliaInsights = insightsClient('G4ZEIPSJ7Z', '87b1a20fccf5600b3aff0754e664c2ce');

export type Props = {
  onNextClick: (destinationRef: DocumentReference<DestinationDoc>) => unknown;
  query: string;
};

export function DestinationListMain({
  onNextClick,
  query,
}: Props) {
  const { t } = useTranslation('HomeScreen');
  const showError = useShowError();

  const profileRef = useProfileRef();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [destinations, setDestinations] = useState<DestinationAlgoliaSearchRecord[]>([]);
  const [queryId, setQueryId] = useState<string | undefined>(undefined);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(
    () => {
      setIsLoading(true);
      destinationsIndex.search<DestinationAlgoliaSearchRecord>(query, {
        clickAnalytics: true,
        // enablePersonalization: true,
        length: 1000,
        offset: 0,
        userToken: profileRef.id,
      }).finally(() => {
        setIsLoading(false);
      }).then((response) => {
        setDestinations(response.hits);
        setQueryId(response.queryID);
        ref.current?.scrollTo({ top: 0 });
      }).catch(showError);
    },
    [profileRef.id, showError, query],
  );

  const { height, width } = useWindowDimensions();

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      setCurrentIndex(Math.round(e.currentTarget.scrollTop / height));
    },
    [height],
  );

  useEffect(
    () => {
      if (destinations[currentIndex]) {
        algoliaInsights.pushEvents({
          events: [
            {
              authenticatedUserToken: profileRef.id,
              eventName: 'Destination View',
              eventType: 'view',
              index: 'destinations',
              objectIDs: [destinations[currentIndex].objectID],
              timestamp: Date.now(),
              userToken: profileRef.id,
            },
          ],
        }).catch(() => { });
      }
    },
    [currentIndex, profileRef.id, destinations],
  );

  if (destinations.length) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        h={height}
        onScroll={handleScroll}
        overflowX="hidden"
        overflowY="scroll"
        overscrollBehavior="none"
        ref={ref}
        scrollSnapType="y mandatory"
        w={width}
      >
        {destinations.map((destination, index) => (
          Math.abs(index - currentIndex) < 10
            ? (
              <Destination
                destinationRecord={destination}
                flexBasis={height}
                flexGrow={0}
                flexShrink={0}
                height={height}
                index={index}
                key={destination.objectID}
                onNextClick={onNextClick}
                queryId={queryId}
                scrollSnapAlign="center"
                scrollSnapStop="always"
                width={width}
              />
            )
            : (
              <Box
                flexBasis={height}
                flexGrow={0}
                flexShrink={0}
                height={height}
                key={destination.objectID}
                scrollSnapAlign="center"
                scrollSnapStop="always"
                width={width}
              />
            )
        ))}
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Container height="100%">
        <Center height="100%">
          <Spinner size="xl" />
        </Center>
      </Container>
    );
  }

  return (
    <Container height="100%">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export default function DestinationList(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <DestinationListMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
