import {
  Center,
  Container,
  Heading,
  HStack,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import ApplicationList from './ApplicationList';
import ConversationList from './ConversationList';

export function ConversationsScreenMain() {
  const insets = useInsets();

  const { t } = useTranslation('ConversationsScreen');

  return (
    <VStack alignItems="stretch" gap={4} h="100%">
      <Container
        style={{
          paddingTop: insets.top,
        }}
      >
        <HStack gap={3}>
          <Heading
            flex={1}
            fontSize="4xl"
            lineHeight="40px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {t('title')}
          </Heading>
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <VStack alignItems="stretch" gap={4}>
          <ApplicationList />
          <ConversationList />
        </VStack>
      </Container>
    </VStack>
  );
}

export default function ConversationsScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <ConversationsScreenMain />
      </Suspense>
    </Catch>
  );
}
