import {
  Button,
  Center,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { logEvent, setUserProperties } from 'firebase/analytics';
import {
  doc,
  getDoc,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalytics, useFirestore, useUser } from 'reactfire';
import * as yup from 'yup';

import { useCitiesCollectionRef } from '../../collections/Cities';
import {
  getProfileHiddenRef,
  ProfileStatus,
  useProfilesCollectionRef,
} from '../../collections/Profiles';
import AppLanguage from '../../common/AppLanguage';
import dateToZodiac from '../../common/dateToZodiac';
import Gender from '../../common/Gender';
import Language from '../../common/Language';
import Role from '../../common/Role';
import Sexuality from '../../common/Sexuality';
import { StoragePicture } from '../../common/StoragePicture';
import Zodiac from '../../common/Zodiac';
import Catch from '../../components/Catch';
import CityFormControl from '../../components/CityFormControl';
import { useIPData } from '../../components/IPDataProvider';
import LanguagesFormControl from '../../components/LanguagesFormControl';
import NumberFormControl from '../../components/NumberFormControl';
import PicturesFormControl from '../../components/PicturesFormControl';
import TextFormControl from '../../components/TextFormControl';
import useTranslateProfileProperties from '../../functions/translateProfileProperties';
import useShowError from '../../hooks/useShowError';
import ErrorFallbackScreen from '../ErrorFallbackScreen';

export type Props = {
  gender: Gender;
  onComplete: () => void;
  role: Role;
  sexuality: Sexuality;
};

export function ProfileFormMain({
  gender,
  onComplete,
  role,
  sexuality,
}: Props) {
  const { i18n, t } = useTranslation('OnboardingProfileScreen', { keyPrefix: 'ProfileForm' });

  const schema = useMemo(
    () => yup.object().shape({
      birthDate: yup
        .string()
        .label(t('birthDate.label'))
        .required(),
      height: yup
        .number()
        .label(t('height.label'))
        .integer()
        .min(30)
        .max(300)
        .required(),
      languages: yup
        .array(
          yup
            .string()
            .label(t('languages.item.label'))
            .oneOf(Object.values(Language))
            .required(),
        )
        .label(t('languages.label'))
        .min(1)
        .required(),
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
      originId: yup
        .string()
        .label(t('originId.label'))
        .required(),
      pictures: yup
        .array()
        .label(t('pictures.label'))
        .compact()
        .min(1)
        .max(6)
        .required()
        .of(yup
          .object()
          .label(t('pictures.item.label'))
          .required()
          .shape({
            blurHash: yup.string().required(),
            imgixUrl: yup.string().required(),
            storageRef: yup.string().required(),
          })),
      weight: yup
        .number()
        .label(t('weight.label'))
        .integer()
        .min(30)
        .max(300)
        .required(),
    }),
    [t],
  );

  const ipData = useIPData();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => {
      const ipDataLanguages: Language[] = ipData?.languages.map(
        (l) => (l as unknown as { code: Language }).code,
      ) ?? [];

      const navigatorLanguages: Language[] = ([navigator.language, ...navigator.languages])
        .map((l) => l.split('-')[0] as Language);

      const languages: Language[] = _.uniq([
        ...ipDataLanguages,
        ...navigatorLanguages,
      ]).sort();

      return {
        birthDate: moment().subtract(20, 'years').format('YYYY-MM-DD'),
        gender: Gender.FEMALE,
        height: 170,
        languages,
        name: '',
        originId: '',
        pictures: [],
        sexuality: Sexuality.STRAIGHT,
        weight: 50,
      };
    },
    [ipData?.languages],
  );

  const showError = useShowError();

  const { data: user } = useUser();
  const firestore = useFirestore();
  const profilesCollectionRef = useProfilesCollectionRef();
  const citiesCollectionRef = useCitiesCollectionRef();

  const analytics = useAnalytics();

  const translateProfileProperties = useTranslateProfileProperties();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      if (!user?.uid) {
        throw new Error();
      }

      try {
        const profileRef = doc(profilesCollectionRef, user.uid);
        const profileSnap = await getDoc(profileRef);

        if (profileSnap.exists()) {
          throw new Error('Profile already exists');
        }

        const profileHiddenRef = getProfileHiddenRef(profileRef);

        const originRef = doc(citiesCollectionRef, values.originId);
        const originSnap = await getDoc(originRef);
        const originDoc = originSnap.data();

        if (!originSnap.exists() || !originDoc) {
          throw new Error('City not found');
        }

        const birthDate = moment.utc(values.birthDate).startOf('day');

        const age = Math.floor(
          moment.duration(
            moment().diff(birthDate),
          ).asYears(),
        );

        const zodiac = dateToZodiac(birthDate.toDate());

        const pictures: StoragePicture[] = _.compact(values.pictures);

        const { data: translations } = await translateProfileProperties({
          gender,
          language: i18n.language as AppLanguage,
          name: values.name,
        });

        const batch = writeBatch(firestore);

        batch.set(
          profileRef,
          {
            _v: 1,
            age,
            createdAt: Timestamp.now(),
            expiresAt: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 365),
            gender,
            height: values.height,
            languages: values.languages,
            lastNotificationAt: Timestamp.now(),
            lastSeenAt: Timestamp.now(),
            name: values.name,
            originRef,
            pictures,
            role,
            sexuality,
            status: ProfileStatus.PUBLISHED,
            translations,
            updatedAt: Timestamp.now(),
            weight: values.weight,
            zodiac: zodiac ?? Zodiac.CAPRICORN,
          },
        );

        batch.set(
          profileHiddenRef,
          {
            _v: 1,
            appLanguage: i18n.language as AppLanguage,
            birthDate: birthDate.format('YYYY-MM-DD'),
            preferences: gender === Gender.FEMALE ? {
              age: {
                max: Math.min(Math.max(age + 20, 18), 100),
                min: Math.min(Math.max(age, 18), 100),
              },
              height: {
                max: Math.min(Math.max(values.height + 50, 100), 300),
                min: Math.min(Math.max(values.height, 100), 300),
              },
              weight: {
                max: Math.min(Math.max(values.weight + 50, 30), 300),
                min: Math.min(Math.max(values.weight, 30), 300),
              },
            } : {
              age: {
                max: Math.min(Math.max(age, 18), 100),
                min: Math.min(Math.max(age - 20, 18), 100),
              },
              height: {
                max: Math.min(Math.max(values.height, 100), 300),
                min: Math.min(Math.max(values.height - 50, 100), 300),
              },
              weight: {
                max: Math.min(Math.max(values.weight, 30), 300),
                min: Math.min(Math.max(values.weight - 50, 30), 300),
              },
            },
            timezone: originDoc.timezone,
          },
        );

        await batch.commit();

        logEvent(analytics, 'create_profile');
        setUserProperties(analytics, {
          age,
          gender,
          languages: values.languages,
          role,
          sexuality,
          zodiac,
        });

        onComplete();
      } catch (err) {
        showError(err);
      }
    },
    [
      analytics,
      citiesCollectionRef,
      firestore,
      gender,
      i18n.language,
      onComplete,
      profilesCollectionRef,
      role,
      sexuality,
      showError,
      translateProfileProperties,
      user?.uid,
    ],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          flex={1}
          gap={4}
          h="100%"
          minH={0}
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
        >
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <PicturesFormControl label="Profile pictures" name="pictures" />

            <TextFormControl
              autoComplete="given-name"
              label={t('name.label')}
              name="name"
              type="text"
            />

            <CityFormControl
              aroundLatLngViaIP
              label={t('originId.label')}
              name="originId"
              placeholder={t('originId.placeholder')}
            />

            <LanguagesFormControl
              label={t('languages.label')}
              name="languages"
              placeholder={t('languages.placeholder')}
            />

            <TextFormControl
              autoComplete="bday"
              label={t('birthDate.label')}
              name="birthDate"
              type="date"
            />

            <NumberFormControl
              label={t('height.label')}
              max={250}
              min={150}
              name="height"
              right={t('height.unit')}
              step={1}
            />

            <NumberFormControl
              label={t('weight.label')}
              max={120}
              min={45}
              name="weight"
              right={t('weight.unit')}
              step={1}
            />
          </VStack>

          <VStack alignItems="stretch">
            <Button
              isLoading={isSubmitting}
              loadingText={t('createProfileButton.loading')}
              type="submit"
              width="100%"
            >
              {t('createProfileButton.default')}
            </Button>
          </VStack>
        </VStack>
      )}
    </Formik>
  );
}

export default function ProfileForm(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <ProfileFormMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
