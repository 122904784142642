import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AvatarGroup,
  Button,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot, serverTimestamp, setDoc } from 'firebase/firestore';
import _ from 'lodash';
import {
  MouseEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuChevronLeft, LuHeartOff } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';

import {
  ConversationClosedBy,
  ConversationDoc,
  ConversationStatus,
} from '../../collections/Conversations';
import { useInsets } from '../../components/InsetsProvider';
import { useProfileRef } from '../../components/ProfileRefProvider';
import useShowError from '../../hooks/useShowError';
import ParticipantName from '../ConversationsScreen/Conversation/ParticipantName';
import ParticipantAvatar from './ParticipantAvatar';

export type Props = {
  conversationSnap: QueryDocumentSnapshot<ConversationDoc>;
};

export default function ConversationHeader({ conversationSnap }: Props) {
  const insets = useInsets();

  const { t } = useTranslation('ConversationScreen');
  const profileRef = useProfileRef();

  const conversationDoc = useMemo(() => conversationSnap.data(), [conversationSnap]);

  const participantRefs = useMemo(
    () => _.filter(
      conversationDoc.participantRefs,
      (r) => r.id !== profileRef.id,
    ),
    [conversationDoc.participantRefs, profileRef.id],
  );

  const showError = useShowError();
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const [closing, setClosing] = useState<boolean>(false);
  const handleCloseConfirmClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      setClosing(true);
      setDoc(conversationSnap.ref, {
        closedAt: serverTimestamp(),
        closedBy: ConversationClosedBy.GUEST,
        status: ConversationStatus.CLOSED,
      }, { merge: true })
        .finally(() => setClosing(false))
        .then(() => navigate('..'))
        .catch(showError);
    },
    [conversationSnap.ref, navigate, showError],
  );

  return (
    <Container
      style={{
        paddingTop: insets.top,
      }}
    >
      <HStack gap={3}>
        <IconButton
          aria-label={t('backButton.default')}
          as={Link}
          icon={<Icon as={LuChevronLeft} />}
          to=".."
        />

        <AvatarGroup max={2}>
          {participantRefs.map((participantRef) => (
            <ParticipantAvatar key={participantRef.id} participantRef={participantRef} />
          ))}
        </AvatarGroup>

        <Heading
          flex={1}
          fontSize="4xl"
          lineHeight="40px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {participantRefs.map((participantRef) => (
            <ParticipantName
              key={participantRef.id}
              participantRef={participantRef}
            />
          ))}
        </Heading>

        <IconButton
          aria-label={t('closeButton.default')}
          colorScheme="red"
          icon={<Icon as={LuHeartOff} />}
          onClick={onOpen}
        />

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogContent
            mx={4}
          >
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('closeAlertModal.title')}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t('closeAlertModal.body')}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose} ref={cancelRef} variant="ghost">
                {t('closeAlertModal.cancelButton.default')}
              </Button>

              <Button
                isLoading={closing}
                loadingText={t('closeAlertModal.confirmButton.loading')}
                ml={3}
                onClick={handleCloseConfirmClick}
              >
                {t('closeAlertModal.confirmButton.default')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </HStack>
    </Container>
  );
}
