import {
  AspectRatio,
  Box,
  Button,
  Card,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Icon,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { useField } from 'formik';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LuImagePlus } from 'react-icons/lu';

import { getPhotoSizeUrl } from '../common/getPhotoSizeUrl';
import { UnsplashPicture } from '../common/UnsplashPicture';
import useUnsplashPhotosTrackDownload from '../functions/useUnsplashPhotosTrackDownload';
import useUnsplashSearchGetPhotos from '../functions/useUnsplashSearchGetPhotos';
import useBlur from '../hooks/useBlur';
import useShowError from '../hooks/useShowError';

export interface Props {
  destination: string;
  label: string;
  name: string;
}

export function UnsplashPhotoItem({
  onPictureSelected,
  photo,
}: {
  onPictureSelected: (picture : UnsplashPicture) => void;
  photo: UnsplashPicture;
}) {
  const unsplashPhotosTrackDownload = useUnsplashPhotosTrackDownload();
  const handleImageChange = useCallback(
    () => {
      unsplashPhotosTrackDownload({
        downloadLocation: photo.links.download_location,
      }).catch(() => { /* do nothing */ });

      onPictureSelected(photo);
    },
    [onPictureSelected, photo, unsplashPhotosTrackDownload],
  );

  const src = useMemo(
    () => getPhotoSizeUrl({ height: 320, uri: photo.urls.raw, width: 180 }),
    [photo.urls.raw],
  );

  const fallbackSrc = useBlur({
    blurHash: photo.blur_hash,
    height: 32,
    width: 18,
  });

  return (
    <AspectRatio
      ratio={9 / 16}
      w="100%"
    >
      <Image
        alt=""
        borderRadius="md"
        cursor="pointer"
        fallbackSrc={fallbackSrc}
        h="100%"
        objectFit="cover"
        onClick={handleImageChange}
        src={src}
        w="100%"
      />
    </AspectRatio>
  );
}

export function UnsplashPhotoModal({
  destination,
  isOpen,
  label,
  onClose,
  onPictureSelected,
}: {
  destination: string;
  isOpen: boolean;
  label: string;
  onClose: () => void;
  onPictureSelected: (picture : UnsplashPicture) => void;
}) {
  const showError = useShowError();

  const [query, setQuery] = useState<string>(destination);

  const [photos, setPhotos] = useState<UnsplashPicture[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const unsplashSearchGetPhotos = useUnsplashSearchGetPhotos();
  useEffect(
    () => {
      if (query.length > 0) {
        setLoading(true);
        unsplashSearchGetPhotos({
          orientation: 'portrait',
          page,
          perPage: 30,
          query,
        }).then(({ data }) => {
          if (data.type === 'success') {
            setPhotos(data.response.results);
            setTotalPages(data.response.total_pages);
          } else {
            showError(data.errors[0]);
          }
          setLoading(false);
        }).catch(showError);
      }
    },
    [page, query, showError, unsplashSearchGetPhotos],
  );

  const pages = useMemo<number[]>(
    () => {
      const start = Math.max(page - 3, 1);
      const end = Math.min(page + 3, totalPages);
      const res: number[] = [];
      for (let i = start; i <= end; i += 1) {
        res.push(i);
      }
      return res;
    },
    [page, totalPages],
  );

  const handleQueryChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setQuery(e.target.value),
    [],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay
        backdropFilter="saturate(180%) blur(20px)"
        backgroundColor="rgb(from var(--chakra-colors-chakra-body-bg) r g b / 0.5)"
      />

      <ModalContent
        mx={4}
      >
        <ModalCloseButton />

        <ModalHeader>
          {label}
        </ModalHeader>

        <ModalBody>
          <VStack spacing={4} w="100%">
            <Input
              onChange={handleQueryChange}
              placeholder="Paris, France"
              value={query}
            />

            {loading ? (
              <Grid gap={2} templateColumns="repeat(2, 1fr)" width="100%">
                <AspectRatio ratio={9 / 16} w="100%">
                  <Skeleton borderRadius="md" />
                </AspectRatio>
                <AspectRatio ratio={9 / 16} w="100%">
                  <Skeleton borderRadius="md" />
                </AspectRatio>
                <AspectRatio ratio={9 / 16} w="100%">
                  <Skeleton borderRadius="md" />
                </AspectRatio>
                <AspectRatio ratio={9 / 16} w="100%">
                  <Skeleton borderRadius="md" />
                </AspectRatio>
              </Grid>
            ) : (
              <Grid gap={2} templateColumns="repeat(2, 1fr)" width="100%">
                {photos.map((photo) => (
                  <Box key={photo.id} position="relative">
                    <UnsplashPhotoItem
                      onPictureSelected={onPictureSelected}
                      photo={photo}
                    />

                    <Text
                      bottom={0}
                      color="white"
                      fontSize="xs"
                      left={0}
                      p={1}
                      position="absolute"
                      right={0}
                      textAlign="right"
                      textShadow="black 1px 1px 1px"
                      userSelect="none"
                    >
                      Photo by
                      {' '}
                      <Link href={`${photo.user.links.html}?utm_source=Qupidu&utm_medium=referral`} target="_blank">
                        {photo.user.first_name}
                        {' '}
                        {photo.user.last_name}
                      </Link>
                      {' '}
                      on
                      {' '}
                      <Link href="https://unsplash.com/?utm_source=Qupidu&utm_medium=referral" target="_blank">Unsplash</Link>
                    </Text>
                  </Box>
                ))}
              </Grid>
            )}

            <Wrap>
              {pages.map((i) => (
                <Button
                  isActive={i === page}
                  key={i}
                  onClick={() => setPage(i)}
                  size="xs"
                >
                  {i}
                </Button>
              ))}
            </Wrap>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} variant="ghost">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function UnsplashPictureItem({ picture }: { picture: UnsplashPicture }) {
  const src = useMemo(
    () => getPhotoSizeUrl({
      height: 64 * 16,
      uri: picture.urls.raw,
      width: 64 * 9,
    }),
    [picture.urls.raw],
  );

  const fallbackSrc = useBlur({
    blurHash: picture.blur_hash,
    height: 32,
    width: 18,
  });

  return (
    <Image
      alt=""
      fallbackSrc={fallbackSrc}
      h="100%"
      objectFit="cover"
      src={src}
      w="100%"
    />
  );
}

export default function CoverFormControl({ destination, label, name }: Props) {
  const [input, meta, helper] = useField<UnsplashPicture>(name);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleImageChange = useCallback(
    (photo: UnsplashPicture) => {
      onClose();

      helper.setValue(photo).catch(() => { });
    },
    [onClose, helper],
  );

  return (
    <>
      <FormControl isInvalid={!!meta.error}>
        <FormLabel>{label}</FormLabel>

        <AspectRatio ratio={9 / 16} w="100%">
          <Card
            cursor="pointer"
            onClick={onOpen}
            overflow="hidden"
            position="relative"
          >
            {input.value ? (
              <UnsplashPictureItem picture={input.value} />
            ) : null}

            {!input.value ? (
              <Center h="100%">
                <Icon as={LuImagePlus} boxSize={6} />
              </Center>
            ) : null}
          </Card>
        </AspectRatio>

        <FormErrorMessage>
          {meta.error}
        </FormErrorMessage>
      </FormControl>

      <UnsplashPhotoModal
        destination={destination}
        isOpen={isOpen}
        label={label}
        onClose={onClose}
        onPictureSelected={handleImageChange}
      />
    </>
  );
}
