import { Button, Text, VStack } from '@chakra-ui/react';
import { DocumentReference, setDoc, Timestamp } from 'firebase/firestore';
import { Formik } from 'formik';
import { Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreDocData } from 'reactfire';
import * as yup from 'yup';

import { TripDoc, TripDuration, TripStatus } from '../../collections/Trips';
import AppLanguage from '../../common/AppLanguage';
import Gender from '../../common/Gender';
import Sexuality from '../../common/Sexuality';
import { UnsplashPicture } from '../../common/UnsplashPicture';
import ActivitiesFormControl from '../../components/ActivitiesFormControl';
import Catch from '../../components/Catch';
import CoverFormControl from '../../components/CoverFormControl';
import { useProfileRef } from '../../components/ProfileRefProvider';
import RadioFormControl from '../../components/RadioFormControl';
import TextFormControl from '../../components/TextFormControl';
import TripDescriptionFormControl from '../../components/TripDescriptionFormControl';
import useTranslateTripProperties from '../../functions/translateTripProperties';

export type Props = {
  onComplete: (tripRef: DocumentReference<TripDoc>) => void;
  tripRef: DocumentReference<TripDoc>;
};

export function TripFormMain({ onComplete, tripRef }: Props) {
  const { i18n, t } = useTranslation('TripUpdateScreen', { keyPrefix: 'TripForm' });

  const { data: tripDoc } = useFirestoreDocData(tripRef);
  const { data: destinationDoc } = useFirestoreDocData(tripDoc.destinationRef);

  const schema = useMemo(
    () => yup.object().shape({
      activities: yup
        .array()
        .label(t('activities.label'))
        .min(0)
        .max(20)
        .of(
          yup
            .string()
            .label(t('activities.item.label'))
            .required(),
        )
        .required(),
      departure: yup
        .string()
        .label(t('departure.label'))
        .required(),
      description: yup
        .string()
        .label(t('description.label'))
        .required(),
      duration: yup
        .string()
        .label(t('duration.label'))
        .oneOf(Object.values(TripDuration))
        .required(),
      picture: yup
        .mixed<UnsplashPicture>()
        .label(t('picture.label'))
        .required()
        .nullable(),
      status: yup
        .string()
        .label(t('status.label'))
        .oneOf(Object.values(TripStatus))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      activities: tripDoc.activities ?? [],
      departure: tripDoc.departure,
      description: tripDoc.description,
      destinationId: tripDoc.destinationRef.id,
      duration: tripDoc.duration,
      picture: tripDoc.picture ?? null,
      status: tripDoc.status,
    }),
    [tripDoc],
  );

  const profileRef = useProfileRef();
  const { data: profileDoc } = useFirestoreDocData(profileRef);

  const translateTripProperties = useTranslateTripProperties();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      if (!values.picture) {
        return;
      }

      let audienceGenders: Gender[];

      switch (profileDoc.sexuality) {
        case Sexuality.GAY: {
          audienceGenders = [profileDoc.gender];
          break;
        }
        case Sexuality.BISEXUAL: {
          audienceGenders = [Gender.FEMALE, Gender.MALE];
          break;
        }
        case Sexuality.STRAIGHT:
        default: {
          if (profileDoc.gender === Gender.FEMALE) {
            audienceGenders = [Gender.MALE];
          } else {
            audienceGenders = [Gender.FEMALE];
          }
        }
      }

      const { data: translations } = await translateTripProperties({
        activities: values.activities,
        audienceGenders,
        departure: values.departure,
        description: values.description,
        destination: {
          countryName: destinationDoc.countryName,
          name: destinationDoc.name,
        },
        duration: values.duration,
        language: i18n.language as AppLanguage,
        organizer: {
          age: profileDoc.age,
          gender: profileDoc.gender,
          // eslint-disable-next-line max-len
          name: profileDoc.translations?.name ?? { [i18n.language as AppLanguage]: profileDoc.name } as Record<AppLanguage, string>,
          role: profileDoc.role,
          sexuality: profileDoc.sexuality,
        },
      });

      await setDoc(
        tripRef,
        {
          activities: values.activities,
          departure: values.departure,
          description: values.description,
          duration: values.duration,
          expiresAt: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 365),
          picture: values.picture,
          status: values.status,
          translations,
          updatedAt: Timestamp.now(),
        },
        { merge: true },
      );

      onComplete(tripRef);
    },
    [
      destinationDoc.countryName,
      destinationDoc.name,
      i18n.language,
      onComplete,
      profileDoc.age,
      profileDoc.gender,
      profileDoc.name,
      profileDoc.role,
      profileDoc.sexuality,
      profileDoc.translations?.name,
      translateTripProperties,
      tripRef,
    ],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
      }) => (
        <VStack alignItems="stretch" gap={4} h="100%">
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <VStack alignItems="stretch" gap={0}>
              <Text fontSize="3xl" fontWeight="bold">
                {destinationDoc.name[i18n.language as AppLanguage]}
              </Text>

              <Text fontSize="xl">
                {destinationDoc.countryEmoji}
                {' '}
                {destinationDoc.countryName[i18n.language as AppLanguage]}
              </Text>
            </VStack>

            <CoverFormControl
              destination={destinationDoc.name.en}
              label={t('picture.label')}
              name="picture"
            />

            <TextFormControl
              label={t('departure.label')}
              name="departure"
              type="date"
            />

            <RadioFormControl
              label={t('duration.label')}
              name="duration"
              options={{
                [TripDuration.MONTH]: t('duration.option.month'),
                [TripDuration.SEASON]: t('duration.option.season'),
                [TripDuration.WEEK]: t('duration.option.week'),
                [TripDuration.WEEKEND]: t('duration.option.weekend'),
              }}
            />

            <RadioFormControl
              label={t('status.label')}
              name="status"
              options={{
                [TripStatus.PUBLISHED]: t('status.option.published'),
                [TripStatus.UNPUBLISHED]: t('status.option.unpublished'),
              }}
            />

            <ActivitiesFormControl
              destinationId={tripDoc.destinationRef.id}
              label={t('activities.label')}
              name="activities"
              placeholder={t('activities.placeholder')}
            />

            <TripDescriptionFormControl
              activities={values.activities}
              departure={values.departure}
              destinationId={tripDoc.destinationRef.id}
              duration={values.duration}
              label={t('description.label')}
              name="description"
              placeholder={t('description.placeholder')}
            />
          </VStack>

          <Button
            isLoading={isSubmitting}
            loadingText={t('updateButton.loading')}
            onClick={() => handleSubmit()}
          >
            {t('updateButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}

export default function TripForm(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <TripFormMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
