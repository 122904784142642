import {
  Box,
  BoxProps,
  Container,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import { useMemo } from 'react';

import { getPhotoSizeUrl } from '../../../common/getPhotoSizeUrl';
import { UnsplashPicture } from '../../../common/UnsplashPicture';
import { useInsets } from '../../../components/InsetsProvider';
import useBlur from '../../../hooks/useBlur';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export type Props = {
  picture: UnsplashPicture;
} & BoxProps;

export default function UnsplashPictureCell({ picture, ...boxProps }: Props) {
  const insets = useInsets();

  const { height, width } = useWindowDimensions();

  const src = useMemo(
    () => getPhotoSizeUrl({
      height,
      uri: picture.urls.raw,
      width,
    }),
    [height, picture, width],
  );

  const fallbackSrc = useBlur({
    blurHash: picture.blur_hash,
    height: 32,
    width: 18,
  });

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      position="relative"
    >
      <Image
        alt=""
        fallbackSrc={fallbackSrc}
        height="100%"
        objectFit="cover"
        scrollSnapAlign="start"
        scrollSnapStop="always"
        src={src}
        width="100%"
      />

      {picture ? (
        <Box
          left={0}
          position="absolute"
          right={0}
          style={{
            top: `calc(${insets.top} + var(--chakra-space-1))`,
          }}
        >
          <Container>
            <Text fontSize="xs" textAlign="right" textShadow="black 1px 1px 1px" userSelect="none">
              Photo by
              {' '}
              <Link href={`${picture.user.links.html}?utm_source=Qupidu&utm_medium=referral`} target="_blank">
                {picture.user.first_name}
                {' '}
                {picture.user.last_name}
              </Link>
              {' '}
              on
              {' '}
              <Link href="https://unsplash.com/?utm_source=Qupidu&utm_medium=referral" target="_blank">Unsplash</Link>
            </Text>
          </Container>
        </Box>
      ) : null}
    </Box>
  );
}
