import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  useRadioGroup,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { useCallback } from 'react';

import Gender from '../../common/Gender';
import useShowError from '../../hooks/useShowError';
import FemaleCard from './FemaleCard';
import MaleCard from './MaleCard';

export interface Props {
  label: string;
  name: string;
}

export default function GenderFormControl({
  label,
  name,
}: Props) {
  const showError = useShowError();

  const [input, meta, helper] = useField<Gender>(name);

  const handleChange = useCallback(
    (value: Gender) => {
      helper.setValue(value)
        .catch(showError);
    },
    [helper, showError],
  );

  const { getRadioProps, getRootProps } = useRadioGroup({
    name,
    onChange: handleChange,
    value: input.value,
  });

  const group = getRootProps();

  const radioFemale = getRadioProps({ value: Gender.FEMALE });
  const radioMale = getRadioProps({ value: Gender.MALE });

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>
      <HStack
        alignItems="stretch"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...group}
      >
        <FemaleCard
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...radioFemale}
        />
        <MaleCard
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...radioMale}
        />
      </HStack>
      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
