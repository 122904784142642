import { ChakraProvider } from '@chakra-ui/react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth } from 'firebase/auth';
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getPerformance } from 'firebase/performance';
import { getRemoteConfig } from 'firebase/remote-config';
import { getStorage } from 'firebase/storage';
import {
  AnalyticsProvider,
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  PerformanceProvider,
  RemoteConfigProvider,
  StorageProvider,
} from 'reactfire';
import { ReportHandler } from 'web-vitals';

import IPDataProvider from './components/IPDataProvider';
import Navigation from './Navigation';
import theme from './theme';

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyDRLwoqAus2J6ieZR57TCkCIK7QULDXAz4',
  appId: '1:472996625527:web:fd8632165ade4bef55da72',
  authDomain: 'qupidu.firebaseapp.com',
  measurementId: 'G-F9MVVESRSR',
  messagingSenderId: '472996625527',
  projectId: 'qupidu',
  storageBucket: 'qupidu.appspot.com',
});

initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6Ld_ASUqAAAAAAwkG6OCJ_4glcZVg12C6yLQYcTd'),
});

const remoteConfigInstance = getRemoteConfig(firebaseApp);
remoteConfigInstance.settings.minimumFetchIntervalMillis = 1000 * 60 * 5;

const authInstance = getAuth(firebaseApp);
const analyticsInstance = getAnalytics(firebaseApp);
const functionsInstance = getFunctions(firebaseApp);
const storageInstance = getStorage(firebaseApp);
const performanceInstance = getPerformance(firebaseApp);

const firestoreInstance = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
  localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
});

function App() {
  return (
    <IPDataProvider>
      <FirebaseAppProvider firebaseApp={firebaseApp} suspense>
        <AuthProvider sdk={authInstance}>
          <AnalyticsProvider sdk={analyticsInstance}>
            <FirestoreProvider sdk={firestoreInstance}>
              <FunctionsProvider sdk={functionsInstance}>
                <StorageProvider sdk={storageInstance}>
                  <RemoteConfigProvider sdk={remoteConfigInstance}>
                    <PerformanceProvider sdk={performanceInstance}>
                      <ChakraProvider theme={theme}>
                        <Navigation />
                      </ChakraProvider>
                    </PerformanceProvider>
                  </RemoteConfigProvider>
                </StorageProvider>
              </FunctionsProvider>
            </FirestoreProvider>
          </AnalyticsProvider>
        </AuthProvider>
      </FirebaseAppProvider>
    </IPDataProvider>
  );
}

const webVitalsReportHandler: ReportHandler = ({ delta, id, name }) => {
  logEvent(analyticsInstance, name, {
    eventAction: name,
    eventCategory: 'Web Vitals',
    eventLabel: id,
    eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
    nonInteraction: true,
    transport: 'beacon',
  });
};

import('web-vitals').then(({
  getCLS, getFCP, getFID, getLCP, getTTFB,
}) => {
  getCLS(webVitalsReportHandler);
  getFID(webVitalsReportHandler);
  getFCP(webVitalsReportHandler);
  getLCP(webVitalsReportHandler);
  getTTFB(webVitalsReportHandler);
}).catch(() => { /* do nothing */ });

export default App;
