import {
  Center,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreCollection } from 'reactfire';

import {
  ConversationStatus,
  useConversationsCollectionRef,
} from '../../collections/Conversations';
import Catch from '../../components/Catch';
import { useProfileRef } from '../../components/ProfileRefProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Conversation, { ConversationLoading } from './Conversation';

export function ConversationListMain() {
  const { t } = useTranslation('ConversationsScreen', { keyPrefix: 'ConversationList' });

  const profileRef = useProfileRef();

  const conversationsCollectionRef = useConversationsCollectionRef();
  const { data: conversationsSnap } = useFirestoreCollection(
    query(
      conversationsCollectionRef,
      where('participantRefs', 'array-contains', profileRef),
      where('status', '==', ConversationStatus.OPENED),
      orderBy('lastActionAt', 'desc'),
    ),
  );

  if (!conversationsSnap.docs.length) {
    return (
      <VStack alignItems="stretch" spacing={3}>
        <Text size="sm">
          {t('title')}
        </Text>

        <Center height="100%">
          <Text textAlign="center">
            {t('emptyList.body')}
          </Text>
        </Center>
      </VStack>
    );
  }

  return (
    <VStack alignItems="stretch" spacing={3}>
      <Text size="sm">
        {t('title')}
      </Text>

      {conversationsSnap.docs.map((conversationSnap) => (
        <Conversation conversationSnap={conversationSnap} key={conversationSnap.id} />
      ))}
    </VStack>
  );
}

export function ConversationListLoading() {
  const { t } = useTranslation('ConversationsScreen', { keyPrefix: 'ConversationList' });

  return (
    <VStack alignItems="stretch" gap={3}>
      <Text size="sm">
        {t('title')}
      </Text>

      <ConversationLoading />
      <ConversationLoading />
      <ConversationLoading />
    </VStack>
  );
}

export default function ConversationList() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <ConversationListMain />
      </Suspense>
    </Catch>
  );
}
