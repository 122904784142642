import { createContext, PropsWithChildren, useContext } from 'react';

type Props = {
  bottom: string;
  left: string;
  right: string;
  top: string;
};

const InsetsContext = createContext<Props>({
  bottom: 'max(var(--chakra-space-4), env(safe-area-inset-bottom))',
  left: '0px',
  right: '0px',
  top: 'max(var(--chakra-space-4), env(safe-area-inset-top))',
});

export const useInsets = () => {
  const ctx = useContext(InsetsContext);

  return ctx;
};

export default function InsetsProvider({
  children,
  ...style
}: PropsWithChildren<Props>) {
  return (
    <InsetsContext.Provider value={style}>
      {children}
    </InsetsContext.Provider>
  );
}
