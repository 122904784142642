import { Icon, IconButton, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LuBackpack } from 'react-icons/lu';
import { Link, useMatch } from 'react-router-dom';

export default function TripsButton() {
  const { t } = useTranslation('MainLayout');

  const tripsMatch = useMatch('/trips/*');

  return (
    <VStack alignItems="center" spacing={0}>
      <IconButton
        aria-label={t('tripsButton.default')}
        as={Link}
        icon={<Icon as={LuBackpack} boxSize={6} />}
        isActive={!!tripsMatch}
        isRound
        size="lg"
        to="/trips"
        variant="ghost"
      />
    </VStack>
  );
}
