import {
  Box,
  BoxProps,
  Container,
  HStack,
} from '@chakra-ui/react';
import {
  MouseEvent,
  Suspense,
  UIEvent,
  useCallback,
  useRef,
  useState,
} from 'react';

import TripAlgoliaSearchRecord from '../../../common/TripAlgoliaSearchRecord';
import Catch from '../../../components/Catch';
import InsetsProvider, { useInsets } from '../../../components/InsetsProvider';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import InfoCell from './InfoCell';
import StoragePictureCell from './StoragePictureCell';
import UnsplashPictureCell from './UnsplashPictureCell';

export type Props = {
  index: number;
  profileExists: boolean;
  queryId: string | undefined;
  tripRecord: TripAlgoliaSearchRecord;
} & BoxProps;

export function TripMain({
  index,
  profileExists,
  queryId,
  tripRecord,
  ...boxProps
}: Props) {
  const insets = useInsets();

  const { height, width } = useWindowDimensions();

  const [dots, setDots] = useState<number[]>(() => ([
    1,
    ...new Array<number>(tripRecord.organizer.pictures.length).fill(0.25),
  ]));

  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      const res = new Array(tripRecord.organizer.pictures.length + 1).fill(1);
      for (let i = 0; i < tripRecord.organizer.pictures.length + 1; i += 1) {
        res[i] = Math.round((1 - (
          Math.min(Math.abs(e.currentTarget.scrollLeft - width * i), width) / width
        ) * 0.75) * 10) / 10;
      }
      setDots(res);
    },
    [width, tripRecord.organizer.pictures.length],
  );

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!ref.current) {
        return;
      }

      if (e.clientX < e.currentTarget.clientWidth / 3) {
        ref.current.scrollBy({ left: -width });
      } else {
        ref.current.scrollBy({ left: width });
      }
    },
    [width],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      position="relative"
    >
      {dots.length > 1 ? (
        <Container
          left={0}
          position="absolute"
          right={0}
          style={{
            top: `calc(${insets.top} + var(--chakra-space-2))`,
          }}
          zIndex={10}
        >
          <HStack gap={2}>
            {dots.map((opacity, i) => (
              <Box
                backdropFilter="saturate(180%) blur(20px)"
                backgroundColor={`rgb(from var(--chakra-colors-white) r g b / ${opacity})`}
                borderRadius="full"
                flex={1}
                h={0.5}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                transition="background-color 0.15s ease-in-out"
              />
            ))}
          </HStack>
        </Container>
      ) : null}

      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        h={height}
        onClick={handleClick}
        onScroll={handleScroll}
        overflow="hidden"
        overscrollBehavior="none"
        position="absolute"
        ref={ref}
        scrollSnapType="x mandatory"
        w={width}
      >
        <InsetsProvider
          bottom={insets.bottom}
          left={insets.left}
          right={insets.right}
          top={`calc(${insets.top} + var(--chakra-space-2) + var(--chakra-space-0-5))`}
        >
          <UnsplashPictureCell
            flexBasis={width}
            flexGrow={0}
            flexShrink={0}
            height={height}
            picture={tripRecord.picture}
            width={width}
          />

          {tripRecord.organizer.pictures.map((p) => (
            <StoragePictureCell
              flexBasis={height}
              flexGrow={0}
              flexShrink={0}
              height={height}
              key={p.imgixUrl}
              picture={p}
              width={width}
            />
          ))}
        </InsetsProvider>
      </Box>

      <InfoCell
        index={index}
        left={0}
        position="absolute"
        profileExists={profileExists}
        queryId={queryId}
        right={0}
        style={{
          bottom: `calc(${insets.bottom} + var(--chakra-space-2))`,
        }}
        tripRecord={tripRecord}
        zIndex={20}
      />
    </Box>
  );
}

export default function Trip(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <TripMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
