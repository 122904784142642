import {
  HStack,
  Skeleton,
  Text,
  useToken,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import _ from 'lodash';
import { Suspense } from 'react';
import { useFirestoreDocData } from 'reactfire';

import { CityDoc } from '../../collections/Cities';
import Catch from '../Catch';

export type Props = {
  cityRef: DocumentReference<CityDoc>;
};

export function CityRowMain({ cityRef }: Props) {
  const { data: cityDoc } = useFirestoreDocData(cityRef);

  const [mono] = useToken('fonts', ['mono']);

  return (
    <HStack flex={1} spacing={4} userSelect="none">
      <Text fontFamily={mono} fontSize="4xl" lineHeight={1}>
        {cityRef.id}
      </Text>

      <VStack alignItems="stretch" flex={1} spacing={1}>
        <Text fontSize="sm" lineHeight={1}>
          {cityDoc.name}
        </Text>
        <Text fontSize="xs" lineHeight={1}>
          {_.compact([cityDoc.countryEmoji, cityDoc.countryName]).join(' ')}
        </Text>
      </VStack>
    </HStack>
  );
}

export function CityRowLoading() {
  return (
    <HStack spacing={4}>
      <Skeleton h="30px" w="60px" />

      <VStack alignItems="stretch" flex={1} spacing={1}>
        <Skeleton h="14px" w="100%" />
        <Skeleton h="12px" w="100%" />
      </VStack>
    </HStack>
  );
}

export default function CityRow(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={<CityRowLoading />}>
        <CityRowMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
