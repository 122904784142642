import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import ApplicationsScreen from './screens/ApplicationsScreen';
import CheckProfileLayout from './screens/CheckProfileLayout';
import CheckProfileRefLayout from './screens/CheckProfileRefLayout';
import ConversationScreen from './screens/ConversationScreen';
import ConversationsScreen from './screens/ConversationsScreen';
import DestinationCreateScreen from './screens/DestinationCreateScreen';
import DestinationUpdateScreen from './screens/DestinationUpdateScreen';
import EventsScreen from './screens/EventsScreen';
import HomeScreen from './screens/HomeScreen';
import MainLayout from './screens/MainLayout';
import OnboardingLayout from './screens/OnboardingLayout';
import ProfileFallbackScreen from './screens/ProfileFallbackScreen';
import ProfilePreviewScreen from './screens/ProfilePreviewScreen';
import ProfileScreen from './screens/ProfileScreen';
import ProfileUpdateScreen from './screens/ProfileUpdateScreen';
import SettingsScreen from './screens/SettingsScreen';
import SignInAnonymouslyScreen from './screens/SignInAnonymouslyScreen';
import TelegramScreen from './screens/TelegramScreen';
import TripCreateInitScreen from './screens/TripCreateInitScreen';
import TripCreateScreen from './screens/TripCreateScreen';
import TripsScreen from './screens/TripsScreen';
import TripUpdateScreen from './screens/TripUpdateScreen';

export default function Navigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<SignInAnonymouslyScreen />} path="login" />
        <Route element={<TelegramScreen />} path="telegram" />

        <Route element={<CheckProfileRefLayout fallback={<Navigate replace to="/login" />} />}>
          <Route element={<OnboardingLayout />} path="onboarding/*" />

          <Route element={<MainLayout />} path="/">
            <Route element={<HomeScreen />} index />

            <Route path="events">
              <Route element={<EventsScreen />} index />
            </Route>

            <Route path="destinations">
              <Route element={<DestinationCreateScreen />} path="add" />
              <Route element={<DestinationUpdateScreen />} path=":destinationId" />
            </Route>

            <Route element={<CheckProfileLayout fallback={<ProfileFallbackScreen />} />}>
              <Route path="profile">
                <Route element={<ProfileScreen />} index />
                <Route element={<ProfileUpdateScreen />} path="update" />
                <Route element={<SettingsScreen />} path="settings" />
                <Route element={<ProfilePreviewScreen />} path="preview" />
              </Route>

              <Route path="trips">
                <Route element={<TripsScreen />} index />
                <Route path="add">
                  <Route element={<TripCreateInitScreen />} index />
                  <Route element={<TripCreateScreen />} path=":destinationId" />
                </Route>
                <Route element={<TripUpdateScreen />} path=":tripId" />
              </Route>

              <Route element={<Navigate replace to="../conversations/applications" />} path="applications" />

              <Route path="conversations">
                <Route element={<ConversationsScreen />} index />
                <Route element={<ConversationScreen />} path=":conversationId" />

                <Route path="applications">
                  <Route element={<ApplicationsScreen />} index />
                  <Route element={<Navigate replace to=".." />} path=":applicationId" />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
