import { DocumentReference } from 'firebase/firestore';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreDocData } from 'reactfire';

import { ProfileDoc } from '../../../collections/Profiles';
import AppLanguage from '../../../common/AppLanguage';
import Catch from '../../../components/Catch';

export type Props = {
  participantRef: DocumentReference<ProfileDoc>;
};

export function ParticipantNameMain({ participantRef }: Props) {
  const { i18n } = useTranslation('ConversationScreen', { keyPrefix: 'Conversation.ParticipantNameMain' });

  const { data: participantDoc } = useFirestoreDocData(participantRef);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {participantDoc.translations?.name[i18n.language as AppLanguage] ?? participantDoc.name}
    </>
  );
}

export default function ParticipantName(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ParticipantNameMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
