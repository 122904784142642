import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

import AppLanguage from '../common/AppLanguage';
import Gender from '../common/Gender';

export interface Data {
  gender: Gender;
  language: AppLanguage;
  name: string;
}

export type Result = {
  name: Record<AppLanguage, string>;
};

export default function useTranslateProfileProperties() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'translateProfileProperties'), [functions]);
}
