import { Center, Spinner } from '@chakra-ui/react';
import { signInAnonymously } from 'firebase/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'reactfire';

import useShowError from '../../hooks/useShowError';

export default function SignInAnonymouslyScreen() {
  const showError = useShowError();
  const navigate = useNavigate();

  const auth = useAuth();
  useEffect(
    () => {
      signInAnonymously(auth)
        .then(() => {
          navigate('/');
        })
        .catch((err) => {
          showError(err);
        });
    },
    [
      auth,
      navigate,
      showError,
    ],
  );

  return (
    <Center h="100%">
      <Spinner size="xl" />
    </Center>
  );
}
