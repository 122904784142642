import {
  Box,
  HStack,
  Radio,
  Text,
  useRadio,
  UseRadioProps,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export type Props = UseRadioProps;

export default function HotCard(props: Props) {
  const { t } = useTranslation('RoleFormControl', { keyPrefix: 'HotCard' });

  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" flex={1} userSelect="none">
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
      />
      <Box
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...checkbox}
        _checked={{
          bg: 'deepOrange.700',
          borderColor: 'deepOrange.700',
          color: 'deepOrange.200',
        }}
        bg="gray.700"
        borderColor="gray.600"
        borderRadius="md"
        borderWidth="1px"
        boxShadow="md"
        color="gray.500"
        cursor="pointer"
        height="100%"
        px={5}
        py={3}
      >
        <VStack alignItems="stretch" height="100%">
          <HStack alignItems="center">
            <Radio
              colorScheme="deepOrange"
              // eslint-disable-next-line react/destructuring-assignment
              isChecked={props.isChecked}
            />

            <Text flex={1} fontSize="xl" fontWeight="bold">
              {t('title')}
            </Text>
          </HStack>

          <Text fontSize="sm">
            {t('body')}
          </Text>
        </VStack>
      </Box>
    </Box>
  );
}
