import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { ChangeEvent, useCallback } from 'react';

import useShowError from '../hooks/useShowError';

function NumberInput({ max, min, name }: { max:number, min: number, name: string }) {
  const showError = useShowError();

  const [input, meta, helper] = useField<number>(name);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      helper.setValue(parseFloat(e.target.value))
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <VStack alignItems="stretch" flex={1}>
      <Input
        max={max}
        min={min}
        onChange={handleChange}
        type="number"
        value={String(input.value)}
      />
      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </VStack>
  );
}

export interface Props {
  label: string;
  max: number;
  min: number;
  name: string;
}

export default function RangeFormControl({
  label, max, min, name,
}: Props) {
  const showError = useShowError();

  const [input, meta, helper] = useField<{ max: number, min: number }>(name);

  const handleChange = useCallback(
    ([minValue, maxValue]: [number, number]) => {
      helper.setValue({ max: maxValue, min: minValue })
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>
      <VStack alignItems="stretch" spacing={4}>
        <RangeSlider
          max={max}
          min={min}
          onChange={handleChange}
          value={[input.value.min, input.value.max]}
        >
          <RangeSliderTrack>
            <RangeSliderFilledTrack />
          </RangeSliderTrack>
          <RangeSliderThumb index={0} />
          <RangeSliderThumb index={1} />
        </RangeSlider>
        <HStack alignItems="start">
          <NumberInput max={input.value.max - 1} min={min} name={`${name}.min`} />
          <NumberInput max={max} min={input.value.min + 1} name={`${name}.max`} />
        </HStack>
      </VStack>
    </FormControl>
  );
}
