import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import { ConversationDoc } from './Conversations';
import { ProfileDoc } from './Profiles';

export enum MessageType {
  FILE = 'FILE',
  TEXT = 'TEXT',
}

export type BaseMessage = {
  _v: 1;
  conversationRef: DocumentReference<ConversationDoc>;
  createdAt: Timestamp;
  notReadByRefs: DocumentReference<ProfileDoc>[];
  readByRefs: DocumentReference<ProfileDoc>[];
  senderRef: DocumentReference<ProfileDoc>;
  updatedAt: Timestamp;
};

export type TextMessageDoc = {
  text: string;
  type: MessageType.TEXT;
} & BaseMessage;

export type FileMessageDoc = {
  storageRef: string;
  type: MessageType.FILE;
} & BaseMessage;

export type MessageDoc = TextMessageDoc;

export const isMessageDoc = (
  data?: DocumentData,
): data is MessageDoc => true;

export const isMessageRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<MessageDoc> => ref.parent.id === 'messages';

function isMessageSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
MessageDoc
>;
function isMessageSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
MessageDoc
>;
function isMessageSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
MessageDoc
> | QueryDocumentSnapshot<
MessageDoc
> {
  return isMessageRef(snap.ref);
}

export { isMessageSnap };

export const getMessagesCollectionRef = (firestore: Firestore) => collection(firestore, 'messages') as CollectionReference<MessageDoc>;

export const useMessagesCollectionRef = () => {
  const firestore = useFirestore();
  return getMessagesCollectionRef(firestore);
};
