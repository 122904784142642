import { QueryDocumentSnapshot } from 'firebase/firestore';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

import { ProfileDoc, ProfileHiddenDoc } from '../collections/Profiles';

type Props = {
  profileHiddenSnap: QueryDocumentSnapshot<ProfileHiddenDoc>;
  profileSnap: QueryDocumentSnapshot<ProfileDoc>;
};

const ProfileContext = createContext<{
  profileHiddenSnap: QueryDocumentSnapshot<ProfileHiddenDoc>;
  profileSnap: QueryDocumentSnapshot<ProfileDoc>;
} | null>(null);

export const useProfile = () => {
  const ctx = useContext(ProfileContext);

  if (!ctx) {
    throw new Error('ProfileContext');
  }

  return ctx;
};

export default function ProfileProvider({
  children,
  profileHiddenSnap,
  profileSnap,
}: PropsWithChildren<Props>) {
  const value = useMemo(
    () => ({
      profileHiddenSnap,
      profileSnap,
    }),
    [
      profileHiddenSnap,
      profileSnap,
    ],
  );

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  );
}
