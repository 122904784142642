import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export interface Data {
  phoneNumber: string;
}

export interface Result {
  exists: boolean;
}

export default function useUserWithPhoneNumberExists() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'userWithPhoneNumberExists'), [functions]);
}
