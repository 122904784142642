import { insightsClient } from '@algolia/client-insights';
import {
  Badge,
  Box,
  BoxProps,
  Collapse,
  Container,
  Divider,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LuCircleDollarSign,
  LuDna,
  LuHeart,
  LuLanguages,
  LuSparkles,
} from 'react-icons/lu';

import { useProfilesCollectionRef } from '../../../collections/Profiles';
import AppLanguage from '../../../common/AppLanguage';
import Gender from '../../../common/Gender';
import Role from '../../../common/Role';
import Sexuality from '../../../common/Sexuality';
import TripAlgoliaSearchRecord from '../../../common/TripAlgoliaSearchRecord';
import { useInsets } from '../../../components/InsetsProvider';
import { useProfileRef } from '../../../components/ProfileRefProvider';
import ActionCell from './ActionCell';
import OrganizerAvatar from './OrganizerAvatar';

const algoliaInsights = insightsClient('G4ZEIPSJ7Z', '87b1a20fccf5600b3aff0754e664c2ce');

export type Props = {
  index: number;
  profileExists: boolean;
  queryId: string | undefined;
  tripRecord: TripAlgoliaSearchRecord;
} & BoxProps;

export default function InfoCell({
  index,
  profileExists,
  queryId,
  tripRecord,
  ...boxProps
}: Props) {
  const insets = useInsets();

  const { i18n, t } = useTranslation('HomeScreen', { keyPrefix: 'Trip.InfoCell' });
  const { t: lt } = useTranslation('Languages');
  const { t: rt } = useTranslation('Role');
  const { t: gt } = useTranslation('Gender');
  const { t: st } = useTranslation('Sexuality');
  const { t: tdt } = useTranslation('TripDuration');
  const { t: zt } = useTranslation('Zodiac');

  const { isOpen: isOpenDetails, onToggle: onToggleDetails } = useDisclosure();

  const profileRef = useProfileRef();
  const handleClick = useCallback(
    () => {
      onToggleDetails();

      if (queryId) {
        algoliaInsights.pushEvents({
          events: [
            {
              authenticatedUserToken: profileRef.id,
              eventName: 'Trip Details Clicked',
              eventType: 'click',
              index: 'trips',
              objectIDs: [tripRecord.objectID],
              positions: [index + 1],
              queryID: queryId,
              timestamp: Date.now(),
              userToken: profileRef.id,
            },
          ],
        }).catch(() => { });
      }
    },
    [index, onToggleDetails, profileRef.id, queryId, tripRecord.objectID],
  );

  const profilesCollectionRef = useProfilesCollectionRef();

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <Container>
        <Box
          _dark={{
            backgroundColor: 'rgb(from var(--chakra-colors-black) r g b / 0.5)',
          }}
          _light={{
            backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.5)',
          }}
          backdropFilter="saturate(180%) blur(20px)"
          borderRadius="2xl"
          cursor="pointer"
          onClick={handleClick}
          p={4}
        >
          <Collapse
            in={isOpenDetails}
            startingHeight="48px"
          >
            <VStack alignItems="stretch" gap={4}>
              <HStack gap={3}>
                <OrganizerAvatar
                  organizerRef={doc(profilesCollectionRef, tripRecord.organizer.id)}
                />

                <VStack alignItems="stretch" flex={1} gap={1} minW={0}>
                  <HStack>
                    <Text fontSize="md" lineHeight={1}>
                      {/* eslint-disable-next-line max-len */}
                      {tripRecord.organizer.translations?.name[i18n.language as AppLanguage] ?? tripRecord.organizer.name}
                      {', '}
                      {tripRecord.organizer.age}
                    </Text>

                    {tripRecord.organizer.role === Role.HOT ? (
                      <Badge colorScheme="deepOrange" my="-1px">
                        {rt(`${tripRecord.organizer.role}.shortLabel`)}
                      </Badge>
                    ) : null}

                    {tripRecord.organizer.role === Role.RICH ? (
                      <Badge colorScheme="teal" my="-1px">
                        {rt(`${tripRecord.organizer.role}.shortLabel`)}
                      </Badge>
                    ) : null}
                  </HStack>

                  <Text fontSize="xs" lineHeight={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    {_.compact([
                      tripRecord.destinationCountryEmoji,
                      _.compact([
                        tripRecord.destinationName[i18n.language as AppLanguage],
                        tripRecord.destinationCountryName[i18n.language as AppLanguage],
                      ]).join(', '),
                    ]).join(' ')}
                  </Text>

                  <Text fontSize="xs" lineHeight={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    {t('departure', {
                      departure: moment(tripRecord.departure).toDate(),
                      formatParams: { departure: { day: 'numeric', month: 'long' } },
                    })}
                  </Text>
                </VStack>

                <ActionCell
                  profileExists={profileExists}
                  queryId={queryId}
                  tripRecord={tripRecord}
                />
              </HStack>

              <Divider />

              <VStack
                alignItems="stretch"
                gap={4}
                overflow="auto"
                style={{
                  maxHeight: `calc(100vh - (${insets.top} + var(--chakra-space-4)) - (${insets.bottom} + var(--chakra-space-2)) - var(--chakra-space-12) - var(--chakra-space-16) - var(--chakra-space-1))`,
                }}
              >
                <Wrap>
                  <Badge>
                    {tdt(tripRecord.duration)}
                  </Badge>

                  <Badge>
                    {tripRecord.destinationName[i18n.language as AppLanguage]}
                  </Badge>

                  <Badge>
                    {tripRecord.destinationCountryName[i18n.language as AppLanguage]}
                  </Badge>

                  {tripRecord.destination.continent?.name ? (
                    <Badge>
                      {tripRecord.destination.continent?.name}
                    </Badge>
                  ) : null}

                  {(tripRecord.activities ?? []).map((activity) => (
                    <Badge key={activity}>{activity}</Badge>
                  ))}
                </Wrap>

                <Divider />

                <Wrap>
                  {tripRecord.organizer.role === Role.HOT ? (
                    <Badge alignItems="center" colorScheme="deepOrange" display="flex" gap={1}>
                      <Icon as={LuCircleDollarSign} boxSize={3} />
                      {rt(`${tripRecord.organizer.role}.longLabel`)}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.role === Role.RICH ? (
                    <Badge alignItems="center" colorScheme="teal" display="flex" gap={1}>
                      <Icon as={LuCircleDollarSign} boxSize={3} />
                      {rt(`${tripRecord.organizer.role}.longLabel`)}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.gender === Gender.FEMALE ? (
                    <Badge alignItems="center" colorScheme="pink" display="flex" gap={1}>
                      <Icon as={LuDna} boxSize={3} />
                      {gt(tripRecord.organizer.gender)}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.gender === Gender.MALE ? (
                    <Badge alignItems="center" colorScheme="blue" display="flex" gap={1}>
                      <Icon as={LuDna} boxSize={3} />
                      {gt(tripRecord.organizer.gender)}
                    </Badge>
                  ) : null}

                  {
                    tripRecord.organizer.sexuality === Sexuality.STRAIGHT
                      && tripRecord.organizer.gender === Gender.FEMALE ? (
                        <Badge alignItems="center" colorScheme="blue" display="flex" gap={1}>
                          <Icon as={LuHeart} size={5} />
                          {st(tripRecord.organizer.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    tripRecord.organizer.sexuality === Sexuality.STRAIGHT
                      && tripRecord.organizer.gender === Gender.MALE ? (
                        <Badge alignItems="center" colorScheme="pink" display="flex" gap={1}>
                          <Icon as={LuHeart} size={5} />
                          {st(tripRecord.organizer.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    tripRecord.organizer.sexuality === Sexuality.GAY
                      && tripRecord.organizer.gender === Gender.FEMALE ? (
                        <Badge alignItems="center" colorScheme="pink" display="flex" gap={1}>
                          <Icon as={LuHeart} size={5} />
                          {st(tripRecord.organizer.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    tripRecord.organizer.sexuality === Sexuality.GAY
                      && tripRecord.organizer.gender === Gender.MALE ? (
                        <Badge alignItems="center" colorScheme="blue" display="flex" gap={1}>
                          <Icon as={LuHeart} size={5} />
                          {st(tripRecord.organizer.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    tripRecord.organizer.sexuality === Sexuality.BISEXUAL ? (
                      <Badge alignItems="center" colorScheme="purple" display="flex" gap={1}>
                        <Icon as={LuHeart} size={5} />
                        {st(tripRecord.organizer.sexuality)}
                      </Badge>
                    ) : null
                  }

                  {tripRecord.organizer.languages.map((l) => (
                    <Badge alignItems="center" display="flex" gap={1} key={l}>
                      <Icon as={LuLanguages} size={5} />
                      {lt(l)}
                    </Badge>
                  ))}

                  <Badge>
                    {tripRecord.organizer.height}
                    cm
                  </Badge>

                  <Badge>
                    {tripRecord.organizer.weight}
                    kg
                  </Badge>

                  <Badge>
                    {tripRecord.organizer.age}
                    y
                  </Badge>

                  <Badge alignItems="center" display="flex" gap={1}>
                    <Icon as={LuSparkles} size={5} />
                    {zt(tripRecord.organizer.zodiac)}
                  </Badge>

                  {tripRecord.organizer.origin.city.name ? (
                    <Badge>
                      {tripRecord.organizer.origin.city.name}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.origin.country?.name ? (
                    <Badge>
                      {tripRecord.organizer.origin.country?.name}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.origin.continent?.name ? (
                    <Badge>
                      {tripRecord.organizer.origin.continent?.name}
                    </Badge>
                  ) : null}
                </Wrap>

                <Divider />

                <Text>
                  {/* eslint-disable-next-line max-len */}
                  {tripRecord.translations?.description[i18n.language as AppLanguage] ?? tripRecord.description}
                </Text>
              </VStack>
            </VStack>
          </Collapse>
        </Box>
      </Container>
    </Box>
  );
}
