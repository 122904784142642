import { Box, Image, ImageProps } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { ApplicationDoc } from '../../collections/Applications';
import { getPhotoSizeUrl } from '../../common/getPhotoSizeUrl';
import Catch from '../../components/Catch';
import useBlur from '../../hooks/useBlur';

export type Props = {
  applicationSnap: QueryDocumentSnapshot<ApplicationDoc>;
} & ImageProps;

export function ApplicationMain({
  applicationSnap,
  ...imageProps
}: Props) {
  const applicationDoc = useMemo(() => applicationSnap.data(), [applicationSnap]);

  const { data: applicantDoc } = useFirestoreDocData(applicationDoc.applicantRef);

  const applicantImageUrl = useMemo(
    () => {
      const uri = applicantDoc.pictures[applicantDoc.primaryPictureIndex ?? 0]?.imgixUrl;

      if (!uri) {
        return undefined;
      }

      return getPhotoSizeUrl({
        faces: true,
        height: 160,
        uri,
        width: 90,
      });
    },
    [applicantDoc.pictures, applicantDoc.primaryPictureIndex],
  );

  const fallbackSrc = useBlur({
    blurHash: applicantDoc.pictures[applicantDoc.primaryPictureIndex ?? 0].blurHash,
    height: 32,
    width: 18,
  });

  return (
    <Box
      as={Link}
      to={`applications/${applicationSnap.id}`}
    >
      <Image
        alt=""
        borderRadius="md"
        fallbackSrc={fallbackSrc}
        h="160px"
        objectFit="cover"
        scrollSnapAlign="start"
        scrollSnapStop="always"
        src={applicantImageUrl}
        w="90px"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...imageProps}
      />
    </Box>
  );
}

export default function Application(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ApplicationMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
