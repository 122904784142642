import {
  Box,
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  Spinner,
} from '@chakra-ui/react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { LuPlusSquare } from 'react-icons/lu';
import { Link } from 'react-router-dom';

import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import TripList from './TripList';

export function TripsScreenMain() {
  const insets = useInsets();

  const { t } = useTranslation('TripsScreen');

  return (
    <Box
      h="100%"
      position="relative"
    >
      <Box
        backdropFilter="saturate(180%) blur(20px)"
        backgroundColor="rgb(from var(--chakra-colors-chakra-body-bg) r g b / 0.5)"
        left={0}
        position="absolute"
        py={4}
        right={0}
        style={{
          paddingTop: insets.top,
        }}
        zIndex={10}
      >
        <Container>
          <HStack gap={3}>
            <Heading
              flex={1}
              fontSize="4xl"
              lineHeight="40px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {t('title')}
            </Heading>

            <IconButton
              aria-label={t('addTripButton.default')}
              as={Link}
              icon={<Icon as={LuPlusSquare} />}
              to="/trips/add"
            />
          </HStack>
        </Container>
      </Box>

      <Container
        flex={1}
        height="100%"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
          paddingTop: `calc(${insets.top} + var(--chakra-space-10) + var(--chakra-space-4))`,
        }}
      >
        <TripList />
      </Container>
    </Box>
  );
}

export default function TripsScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <TripsScreenMain />
      </Suspense>
    </Catch>
  );
}
