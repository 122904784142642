import {
  Center,
  Container,
  Divider,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useConversationsCollectionRef } from '../../collections/Conversations';
import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import ConversationHeader from './ConversationHeader';
import MessageInput from './MessageInput';
import MessageList from './MessageList';
import TypingParticipants from './TypingParticipants';

export function ConversationScreenMain() {
  const insets = useInsets();

  const { conversationId } = useParams<{ conversationId: string }>();

  const conversationsCollectionRef = useConversationsCollectionRef();
  const conversationRef = useMemo(
    () => doc(conversationsCollectionRef, conversationId),
    [conversationId, conversationsCollectionRef],
  );

  const { data: conversationSnap } = useFirestoreDoc(conversationRef);

  if (!conversationSnap.exists()) {
    return null;
  }

  return (
    <VStack
      alignItems="stretch"
      gap={4}
      h="100%"
      style={{
        paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
      }}
      w="100%"
    >
      <ConversationHeader conversationSnap={conversationSnap} />

      <Container flex={1} minH={0}>
        <VStack alignItems="stretch" flexDirection="column-reverse" h="100%" overflow="auto">
          <TypingParticipants conversationSnap={conversationSnap} />

          <Divider
            style={{
              overflowAnchor: 'none',
            }}
          />

          <MessageList conversationSnap={conversationSnap} />
        </VStack>
      </Container>

      <MessageInput conversationSnap={conversationSnap} />
    </VStack>
  );
}

export default function ConversationScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <ConversationScreenMain />
      </Suspense>
    </Catch>
  );
}
