import {
  Badge,
  Button,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  UnorderedList,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { DocumentReference, setDoc, Timestamp } from 'firebase/firestore';
import { Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreDocData } from 'reactfire';
import * as yup from 'yup';

import { DestinationDoc } from '../../collections/Destinations';
import { UnsplashPicture } from '../../common/UnsplashPicture';
import CoverFormControl from '../../components/CoverFormControl';

export type Props = {
  destinationRef: DocumentReference<DestinationDoc>;
  onComplete: (destinationRef: DocumentReference<DestinationDoc>) => void;
};

export default function DestinationForm({ destinationRef, onComplete }: Props) {
  const { t } = useTranslation('DestinationUpdateScreen', { keyPrefix: 'DestinationForm' });
  const { t: dlt } = useTranslation('DestinationLabels');

  const { data: destinationDoc } = useFirestoreDocData(destinationRef);

  const schema = useMemo(
    () => yup.object().shape({
      picture: yup
        .mixed<UnsplashPicture>()
        .label(t('picture.label'))
        .required()
        .nullable(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      picture: destinationDoc.picture ?? null,
    }),
    [destinationDoc],
  );

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      if (!values.picture) {
        return;
      }

      await setDoc(
        destinationRef,
        {
          picture: values.picture,
          updatedAt: Timestamp.now(),
        },
        { merge: true },
      );

      onComplete(destinationRef);
    },
    [destinationRef, onComplete],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <VStack alignItems="stretch" gap={4} h="100%">
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <UnorderedList>
              <ListItem>
                Country ID:
                {' '}
                {destinationDoc.countryRef.id}
              </ListItem>

              <ListItem>
                Labels:
                <Wrap>
                  {destinationDoc.labels.map((l) => (<Badge>{dlt(l)}</Badge>))}
                </Wrap>
              </ListItem>
            </UnorderedList>

            <Tabs>
              <TabList>
                <Tab>de</Tab>
                <Tab>en</Tab>
                <Tab>es</Tab>
                <Tab>fr</Tab>
                <Tab>it</Tab>
                <Tab>nl</Tab>
                <Tab>pl</Tab>
                <Tab>pt</Tab>
                <Tab>ru</Tab>
                <Tab>tr</Tab>
                <Tab>uk</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {destinationDoc.countryName.de}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {destinationDoc.name.de}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {destinationDoc.description.de}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {destinationDoc.countryName.en}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {destinationDoc.name.en}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {destinationDoc.description.en}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {destinationDoc.countryName.es}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {destinationDoc.name.es}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {destinationDoc.description.es}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {destinationDoc.countryName.fr}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {destinationDoc.name.fr}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {destinationDoc.description.fr}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {destinationDoc.countryName.it}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {destinationDoc.name.it}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {destinationDoc.description.it}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {destinationDoc.countryName.nl}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {destinationDoc.name.nl}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {destinationDoc.description.nl}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {destinationDoc.countryName.pl}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {destinationDoc.name.pl}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {destinationDoc.description.pl}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {destinationDoc.countryName.pt}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {destinationDoc.name.pt}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {destinationDoc.description.pt}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {destinationDoc.countryName.ru}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {destinationDoc.name.ru}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {destinationDoc.description.ru}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {destinationDoc.countryName.tr}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {destinationDoc.name.tr}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {destinationDoc.description.tr}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {destinationDoc.countryName.uk}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {destinationDoc.name.uk}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {destinationDoc.description.uk}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <CoverFormControl
              destination={destinationDoc.name.en}
              label={t('picture.label')}
              name="picture"
            />
          </VStack>

          <Button
            isLoading={isSubmitting}
            loadingText={t('updateButton.loading')}
            onClick={() => handleSubmit()}
          >
            {t('updateButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
