import { Center, Spinner } from '@chakra-ui/react';
import { signInWithCustomToken } from 'firebase/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useUser } from 'reactfire';

import useTelegramGetToken from '../../functions/useTelegramGetToken';
import useShowError from '../../hooks/useShowError';

export default function TelegramScreen() {
  const telegramGetToken = useTelegramGetToken();
  const showError = useShowError();
  const auth = useAuth();
  const { data: user } = useUser();
  const navigate = useNavigate();

  useEffect(
    () => {
      if (!user) {
        telegramGetToken({ initData: Telegram.WebApp.initData })
          .then(({ data: { token } }) => signInWithCustomToken(auth, token))
          .then(() => {
            navigate('/');
          })
          .catch(showError);
      }
    },
    [auth, navigate, showError, telegramGetToken, user],
  );

  return (
    <Center h="100%">
      <Spinner size="xl" />
    </Center>
  );
}
