import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import AppLanguage from '../common/AppLanguage';
import Gender from '../common/Gender';
import Language from '../common/Language';
import Role from '../common/Role';
import Sexuality from '../common/Sexuality';
import { StoragePicture } from '../common/StoragePicture';
import Zodiac from '../common/Zodiac';
import { CityDoc } from './Cities';

export enum ProfileStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface ProfileDoc {
  _v: 1;
  age: number;
  bannedAt?: Timestamp;
  createdAt: Timestamp;
  expiresAt: Timestamp;
  gender: Gender;
  height: number;
  isBanned?: boolean;
  languages: Language[];
  lastNotificationAt: Timestamp;
  lastSeenAt: Timestamp;
  name: string;
  originRef: DocumentReference<CityDoc>;
  pictures: StoragePicture[];
  primaryPictureIndex?: number;
  role: Role;
  score?: number;
  scoredAt?: Timestamp;
  sexuality: Sexuality;
  status: ProfileStatus;
  translations?: {
    name: Record<AppLanguage, string>;
  };
  updatedAt: Timestamp;
  weight: number;
  zodiac: Zodiac;
}

export const isProfileDoc = (
  data?: DocumentData,
): data is ProfileDoc => true;

export const isProfileRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ProfileDoc> => ref.parent.id === 'profiles';

function isProfileSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
ProfileDoc
>;
function isProfileSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
ProfileDoc
>;
function isProfileSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
ProfileDoc
> | QueryDocumentSnapshot<
ProfileDoc
> {
  return isProfileRef(snap.ref);
}

export { isProfileSnap };

export const getProfilesCollectionRef = (firestore: Firestore) => collection(firestore, 'profiles') as CollectionReference<ProfileDoc>;

export const useProfilesCollectionRef = () => {
  const firestore = useFirestore();
  return getProfilesCollectionRef(firestore);
};

export interface ProfileHiddenDoc {
  _v: 1;
  appLanguage?: AppLanguage;
  birthDate: string;
  fcmTokens?: string[];
  preferences: {
    age: {
      max: number;
      min: number;
    };
    height: {
      max: number;
      min: number;
    };
    weight: {
      max: number;
      min: number;
    };
  };
  profileSuggestions?: string[];
  timezone?: string;
}

export const isProfileHiddenDoc = (
  data?: DocumentData,
): data is ProfileHiddenDoc => true;

export const isProfileHiddenRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ProfileHiddenDoc> => ref.id === 'internal' && ref.parent.id === 'hidden' && ref.parent.parent !== null && isProfileRef(ref.parent.parent);

function isProfileHiddenSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
ProfileHiddenDoc
>;
function isProfileHiddenSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
ProfileHiddenDoc
>;
function isProfileHiddenSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
ProfileHiddenDoc
> | QueryDocumentSnapshot<
ProfileHiddenDoc
> {
  return isProfileHiddenRef(snap.ref);
}

export { isProfileHiddenSnap };

export const getProfileHiddenRef = (userRef: DocumentReference<ProfileDoc>) => doc(collection(userRef, 'internal'), 'hidden') as DocumentReference<ProfileHiddenDoc>;
