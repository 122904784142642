import { Button, Text, VStack } from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { Formik } from 'formik';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreDocData } from 'reactfire';
import * as yup from 'yup';

import { DestinationDoc } from '../../collections/Destinations';
import {
  TripBudget,
  TripDoc,
  TripDuration,
  TripStatus,
  useTripsCollectionRef,
} from '../../collections/Trips';
import AppLanguage from '../../common/AppLanguage';
import Gender from '../../common/Gender';
import Sexuality from '../../common/Sexuality';
import { UnsplashPicture } from '../../common/UnsplashPicture';
import ActivitiesFormControl from '../../components/ActivitiesFormControl';
import CoverFormControl from '../../components/CoverFormControl';
import { useProfileRef } from '../../components/ProfileRefProvider';
import RadioFormControl from '../../components/RadioFormControl';
import TextFormControl from '../../components/TextFormControl';
import TripDescriptionFormControl from '../../components/TripDescriptionFormControl';
import useTranslateTripProperties from '../../functions/translateTripProperties';

export type Props = {
  destinationRef: DocumentReference<DestinationDoc>;
  onComplete: (tripRef: DocumentReference<TripDoc>) => void;
};

export default function TripForm({ destinationRef, onComplete }: Props) {
  const { i18n, t } = useTranslation('TripCreateScreen', { keyPrefix: 'TripForm' });

  const { data: destinationDoc } = useFirestoreDocData(destinationRef);

  const schema = useMemo(
    () => yup.object().shape({
      activities: yup
        .array()
        .label(t('activities.label'))
        .min(0)
        .max(20)
        .of(
          yup
            .string()
            .label(t('activities.item.label'))
            .required(),
        )
        .required(),
      departure: yup
        .string()
        .label(t('departure.label'))
        .required(),
      description: yup
        .string()
        .label(t('description.label'))
        .required(),
      duration: yup
        .string()
        .label(t('duration.label'))
        .oneOf(Object.values(TripDuration))
        .required(),
      picture: yup
        .mixed<UnsplashPicture>()
        .label(t('picture.label'))
        .required()
        .nullable(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      activities: [],
      budget: TripBudget.ECONOMY,
      departure: moment
        .utc()
        .startOf('month')
        .add(1, 'month')
        .format('YYYY-MM-DD'),
      description: '',
      duration: TripDuration.WEEK,
      picture: destinationDoc.picture ?? null,
    }),
    [destinationDoc.picture],
  );

  const profileRef = useProfileRef();
  const { data: profileDoc } = useFirestoreDocData(profileRef);

  const tripsCollectionRef = useTripsCollectionRef();

  const translateTripProperties = useTranslateTripProperties();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      if (!values.picture) {
        return;
      }

      let audienceGenders: Gender[];

      switch (profileDoc.sexuality) {
        case Sexuality.GAY: {
          audienceGenders = [profileDoc.gender];
          break;
        }
        case Sexuality.BISEXUAL: {
          audienceGenders = [Gender.FEMALE, Gender.MALE];
          break;
        }
        case Sexuality.STRAIGHT:
        default: {
          if (profileDoc.gender === Gender.FEMALE) {
            audienceGenders = [Gender.MALE];
          } else {
            audienceGenders = [Gender.FEMALE];
          }
        }
      }

      const tripRef = doc(tripsCollectionRef);

      const { data: translations } = await translateTripProperties({
        activities: values.activities,
        audienceGenders,
        departure: values.departure,
        description: values.description,
        destination: {
          countryName: destinationDoc.countryName,
          name: destinationDoc.name,
        },
        duration: values.duration,
        language: i18n.language as AppLanguage,
        organizer: {
          age: profileDoc.age,
          gender: profileDoc.gender,
          // eslint-disable-next-line max-len
          name: profileDoc.translations?.name ?? { [i18n.language as AppLanguage]: profileDoc.name } as Record<AppLanguage, string>,
          role: profileDoc.role,
          sexuality: profileDoc.sexuality,
        },
      });

      await setDoc(
        tripRef,
        {
          _v: 1,
          activities: values.activities,
          budget: TripBudget.ECONOMY,
          createdAt: Timestamp.now(),
          departure: values.departure,
          description: values.description,
          destinationRef,
          duration: values.duration,
          expiresAt: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 365),
          organizerRef: profileRef,
          picture: values.picture,
          status: TripStatus.PUBLISHED,
          translations,
          updatedAt: Timestamp.now(),
        },
      );

      onComplete(tripRef);
    },
    [
      destinationDoc.countryName,
      destinationDoc.name,
      destinationRef,
      i18n.language,
      onComplete,
      profileDoc.age,
      profileDoc.gender,
      profileDoc.name,
      profileDoc.role,
      profileDoc.sexuality,
      profileDoc.translations?.name,
      profileRef,
      translateTripProperties,
      tripsCollectionRef,
    ],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
      }) => (
        <VStack alignItems="stretch" gap={4} h="100%">
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <VStack alignItems="stretch" gap={0}>
              <Text fontSize="3xl" fontWeight="bold">
                {destinationDoc.name[i18n.language as AppLanguage]}
              </Text>

              <Text fontSize="xl">
                {destinationDoc.countryEmoji}
                {' '}
                {destinationDoc.countryName[i18n.language as AppLanguage]}
              </Text>
            </VStack>

            <CoverFormControl
              destination={destinationDoc.name.en}
              label={t('picture.label')}
              name="picture"
            />

            <TextFormControl
              label={t('departure.label')}
              name="departure"
              type="date"
            />

            <RadioFormControl
              label={t('duration.label')}
              name="duration"
              options={{
                [TripDuration.MONTH]: t('duration.option.month'),
                [TripDuration.SEASON]: t('duration.option.season'),
                [TripDuration.WEEK]: t('duration.option.week'),
                [TripDuration.WEEKEND]: t('duration.option.weekend'),
              }}
            />

            <ActivitiesFormControl
              destinationId={destinationRef.id}
              label={t('activities.label')}
              name="activities"
              placeholder={t('activities.placeholder')}
            />

            <TripDescriptionFormControl
              activities={values.activities}
              departure={values.departure}
              destinationId={destinationRef.id}
              duration={values.duration}
              label={t('description.label')}
              name="description"
              placeholder={t('description.placeholder')}
            />
          </VStack>

          <Button
            isLoading={isSubmitting}
            loadingText={t('createButton.loading')}
            onClick={() => handleSubmit()}
          >
            {t('createButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
