import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

import AppLanguage from '../common/AppLanguage';

export interface Data {
  destinationName: string;
}

export type Result = {
  countryISOKey: string;
  countryName: Record<AppLanguage, string>;
  destinationDescription: Record<AppLanguage, string>;
  destinationLabels: string[];
  destinationName: Record<AppLanguage, string>;
};

export default function useGenerateDestination() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'generateDestination'), [functions]);
}
