enum Activity {
  AMUSEMENT_PARK = 'AMUSEMENT_PARK',
  ANCIENT_ARCHITECTURE = 'ANCIENT_ARCHITECTURE',
  AQUARIUM = 'AQUARIUM',
  ART_GALLERY = 'ART_GALLERY',
  AUCTION = 'AUCTION',
  AURORA = 'AURORA',
  BEACH = 'BEACH',
  BEACH_VOLLEYBALL = 'BEACH_VOLLEYBALL',
  BOAT_TOUR = 'BOAT_TOUR',
  BOTANICAL_GARDEN = 'BOTANICAL_GARDEN',
  BREWERY_TOUR = 'BREWERY_TOUR',
  CAMPING = 'CAMPING',
  CANYON = 'CANYON',
  CAR_RACE = 'CAR_RACE',
  CASINO = 'CASINO',
  CASTLE = 'CASTLE',
  CAVE = 'CAVE',
  CITY_TOUR = 'CITY_TOUR',
  CONCERT = 'CONCERT',
  CORAL_REEF = 'CORAL_REEF',
  CRUISE = 'CRUISE',
  CYCLING = 'CYCLING',
  DESERT = 'DESERT',
  DISTILLERY = 'DISTILLERY',
  FARMERS_MARKET = 'FARMERS_MARKET',
  FASHION_SHOW = 'FASHION_SHOW',
  FESTIVAL = 'FESTIVAL',
  FILM_FESTIVAL = 'FILM_FESTIVAL',
  FIREWORKS = 'FIREWORKS',
  FISHING = 'FISHING',
  FOOD_TOUR = 'FOOD_TOUR',
  FOREST = 'FOREST',
  GHOST_TOUR = 'GHOST_TOUR',
  GLACIER = 'GLACIER',
  GLAMPING = 'GLAMPING',
  GOLF = 'GOLF',
  HELICOPTER = 'HELICOPTER',
  HIKING = 'HIKING',
  HISTORICAL_LANDMARKS = 'HISTORICAL_LANDMARKS',
  HORSEBACK_RIDING = 'HORSEBACK_RIDING',
  HOT_AIR_BALLOONING = 'HOT_AIR_BALLOONING',
  HOT_SPRINGS = 'HOT_SPRINGS',
  ICE_SKATING = 'ICE_SKATING',
  JET_SKIING = 'JET_SKIING',
  KAYAKING = 'KAYAKING',
  KITE_SURFING = 'KITE_SURFING',
  LIGHT_AIRCRAFT = 'LIGHT_AIRCRAFT',
  LIGHTHOUSE = 'LIGHTHOUSE',
  LITERARY_EVENT = 'LITERARY_EVENT',
  LOCAL_MARKET = 'LOCAL_MARKET',
  MEDITATION = 'MEDITATION',
  MICHELIN_RESTAURANT = 'MICHELIN_RESTAURANT',
  MONASTERY = 'MONASTERY',
  MOTORCYCLE = 'MOTORCYCLE',
  MOUNTAINS = 'MOUNTAINS',
  MUSEUM = 'MUSEUM',
  NATIONAL_PARK = 'NATIONAL_PARK',
  NIGHTCLUB = 'NIGHTCLUB',
  NIGHTLIFE = 'NIGHTLIFE',
  OBSERVATORY = 'OBSERVATORY',
  OCEAN = 'OCEAN',
  OPERA = 'OPERA',
  PADDLEBOARDING = 'PADDLEBOARDING',
  PARAGLIDING = 'PARAGLIDING',
  PARASAILING = 'PARASAILING',
  PHOTOGRAPHY = 'PHOTOGRAPHY',
  PLANETARIUM = 'PLANETARIUM',
  POKER = 'POKER',
  PRIVATE_ISLAND = 'PRIVATE_ISLAND',
  PUB_CRAWL = 'PUB_CRAWL',
  RAFTING = 'RAFTING',
  RAIN_FOREST = 'RAIN_FOREST',
  RETREAT = 'RETREAT',
  RIVER_CRUISE = 'RIVER_CRUISE',
  ROCK_CLIMBING = 'ROCK_CLIMBING',
  SAFARI = 'SAFARI',
  SAILING = 'SAILING',
  SCENIC_DRIVE = 'SCENIC_DRIVE',
  SCENIC_TRAIN_RIDE = 'SCENIC_TRAIN_RIDE',
  SCUBA_DIVING = 'SCUBA_DIVING',
  SEA = 'SEA',
  SHOPPING = 'SHOPPING',
  SKIING = 'SKIING',
  SNORKELING = 'SNORKELING',
  SNOWBOARDING = 'SNOWBOARDING',
  SPA = 'SPA',
  SPORT_EVENT = 'SPORT_EVENT',
  STARGAZING = 'STARGAZING',
  SUNBATHING = 'SUNBATHING',
  SUPER_CAR = 'SUPER_CAR',
  SURFING = 'SURFING',
  TECHNO_RAVE = 'TECHNO_RAVE',
  TENNIS = 'TENNIS',
  THEATER = 'THEATER',
  THEME_PARK = 'THEME_PARK',
  VINEYARD = 'VINEYARD',
  VOLCANO = 'VOLCANO',
  WATER_PARK = 'WATER_PARK',
  WELLNESS = 'WELLNESS',
  WHALE_WATCHING = 'WHALE_WATCHING',
  WILDLIFE = 'WILDLIFE',
  WINDSURFING = 'WINDSURFING',
  WINE = 'WINE',
  YACHTING = 'YACHTING',
  YOGA = 'YOGA',
  ZIP_LINE = 'ZIP_LINE',
  ZOO = 'ZOO',
}

export default Activity;
