import {
  Box,
  Center,
  Container,
  HStack,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import Catch from '../../components/Catch';
import InsetsProvider, { useInsets } from '../../components/InsetsProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Conversations from './Conversations';
import Events from './Events';
import ExploreButton from './ExploreButton';
import ProfileButton from './ProfileButton';
import TripsButton from './TripsButton';

export function MainLayoutMain() {
  const insets = useInsets();

  return (
    <VStack
      alignItems="stretch"
      bottom={0}
      gap={0}
      left={0}
      position="fixed"
      right={0}
      top={0}
    >
      <Box h="100%" minH={0}>
        <InsetsProvider
          bottom={`calc(${insets.bottom} + var(--chakra-space-16))`}
          left={insets.left}
          right={insets.right}
          top={insets.top}
        >
          <Outlet />
        </InsetsProvider>
      </Box>

      <Container
        left={0}
        position="absolute"
        right={0}
        style={{
          bottom: `calc(${insets.bottom})`,
        }}
        zIndex={50}
      >
        <Box
          _dark={{
            backgroundColor: 'rgb(from var(--chakra-colors-black) r g b / 0.5)',
          }}
          _light={{
            backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.5)',
          }}
          backdropFilter="saturate(180%) blur(20px)"
          borderRadius="2xl"
          boxShadow="dark-lg"
          p={2}
        >
          <HStack gap={2} justifyContent="space-between">
            <TripsButton />
            <Events />
            <ExploreButton />
            <Conversations />
            <ProfileButton />
          </HStack>
        </Box>
      </Container>
    </VStack>
  );
}

export default function MainLayout() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <MainLayoutMain />
      </Suspense>
    </Catch>
  );
}
