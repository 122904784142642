import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

import { TripDuration } from '../collections/Trips';
import AppLanguage from '../common/AppLanguage';
import Gender from '../common/Gender';
import Role from '../common/Role';
import Sexuality from '../common/Sexuality';

export interface Data {
  activities: string[];
  audienceGenders: Gender[];
  departure: string;
  description: string;
  destination: {
    countryName: Record<AppLanguage, string>;
    name: Record<AppLanguage, string>;
  }
  duration: TripDuration;
  language: AppLanguage;
  organizer: {
    age: number;
    gender: Gender;
    name: Record<AppLanguage, string>;
    role: Role;
    sexuality: Sexuality;
  }
}

export type Result = {
  description: Record<AppLanguage, string>;
};

export default function useTranslateTripProperties() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'translateTripProperties'), [functions]);
}
