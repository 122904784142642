import { Button, Icon } from '@chakra-ui/react';
import { arrayUnion, setDoc } from 'firebase/firestore';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuBell } from 'react-icons/lu';
import { useFirebaseApp } from 'reactfire';

import { getProfileHiddenRef } from '../../collections/Profiles';
import { useProfileRef } from '../../components/ProfileRefProvider';
import useShowError from '../../hooks/useShowError';

export default function NotificationsButton() {
  const { t } = useTranslation('SettingsScreen', { keyPrefix: 'SettingsMenu' });

  const showError = useShowError();

  const firebaseApp = useFirebaseApp();

  const [isFCMSupported, setIsFCMSupported] = useState(false);
  useEffect(
    () => {
      isSupported()
        .then(setIsFCMSupported)
        .catch(showError);
    },
    [showError],
  );

  const profileRef = useProfileRef();
  const profileHiddenRef = useMemo(() => getProfileHiddenRef(profileRef), [profileRef]);

  const enableNotifications = useCallback(
    () => {
      const messagingInstance = getMessaging(firebaseApp);

      getToken(
        messagingInstance,
        {
          vapidKey: 'BDmhbfVHrkCAZBNMKTrGkTXuy_fimmPOuH1M8ovPWuksXbrCfbrf_ugBbj6FG-g8NaQN4UqSNQIEfzEREnzFlwk',
        },
      )
        .then((currentToken) => {
          if (!currentToken) {
            throw new Error('No registration token available. Request permission to generate one.');
          }

          localStorage.setItem('fcmToken', currentToken);

          return setDoc(
            profileHiddenRef,
            {
              fcmTokens: arrayUnion(currentToken),
            },
            { merge: true },
          );
        })
        .catch(() => {
          showError('An error occurred while retrieving token. ');
        });
    },
    [firebaseApp, profileHiddenRef, showError],
  );

  return isFCMSupported ? (
    <Button
      justifyContent="start"
      leftIcon={<Icon as={LuBell} />}
      onClick={enableNotifications}
      variant="ghost"
    >
      {t('enableNotificationsButton.default')}
    </Button>
  ) : null;
}
