import {
  Box,
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  Spinner,
} from '@chakra-ui/react';
import {
  MouseEvent,
  Suspense,
  UIEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuChevronLeft } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import Catch from '../../components/Catch';
import InsetsProvider, { useInsets } from '../../components/InsetsProvider';
import { useProfileRef } from '../../components/ProfileRefProvider';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import InfoCell from './InfoCell';
import StoragePictureCell from './StoragePictureCell';

export function ProfilePreviewScreenMain() {
  const { t } = useTranslation('ProfilePreviewScreen');
  const { height, width } = useWindowDimensions();
  const insets = useInsets();

  const profileRef = useProfileRef();
  const { data: profileDoc } = useFirestoreDocData(profileRef);

  const [dots, setDots] = useState<number[]>(() => ([
    1,
    ...new Array<number>(profileDoc.pictures.length - 1).fill(0.25),
  ]));

  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      const res = new Array(profileDoc.pictures.length).fill(1);
      for (let i = 0; i < profileDoc.pictures.length; i += 1) {
        res[i] = Math.round((1 - (
          Math.min(Math.abs(e.currentTarget.scrollLeft - width * i), width) / width
        ) * 0.75) * 10) / 10;
      }
      setDots(res);
    },
    [profileDoc.pictures.length, width],
  );

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!ref.current) {
        return;
      }

      if (e.clientX < e.currentTarget.clientWidth / 3) {
        ref.current.scrollBy({ left: -width });
      } else {
        ref.current.scrollBy({ left: width });
      }
    },
    [width],
  );

  return (
    <Box
      h="100%"
      position="relative"
    >
      <Box
        left={0}
        position="absolute"
        right={0}
        style={{
          top: insets.top,
        }}
        zIndex={10}
      >
        <Container>
          <HStack gap={3}>
            <IconButton
              aria-label={t('backButton.default')}
              as={Link}
              icon={<Icon as={LuChevronLeft} />}
              to=".."
            />

            <Heading
              flex={1}
              fontSize="4xl"
              lineHeight="40px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {t('title')}
            </Heading>
          </HStack>
        </Container>
      </Box>

      <Box
        h="100%"
        position="relative"
      >
        {dots.length > 1 ? (
          <Container
            left={0}
            position="absolute"
            right={0}
            style={{
              top: `calc(${insets.top} + var(--chakra-space-2) + var(--chakra-space-10))`,
            }}
            zIndex={10}
          >
            <HStack gap={2}>
              {dots.map((opacity, i) => (
                <Box
                  backdropFilter="saturate(180%) blur(20px)"
                  backgroundColor={`rgb(from var(--chakra-colors-white) r g b / ${opacity})`}
                  borderRadius="full"
                  flex={1}
                  h={0.5}
                    // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  transition="background-color 0.15s ease-in-out"
                />
              ))}
            </HStack>
          </Container>
        ) : null}

        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          h={height}
          onClick={handleClick}
          onScroll={handleScroll}
          overflow="hidden"
          overscrollBehavior="none"
          position="absolute"
          ref={ref}
          scrollSnapType="x mandatory"
          w={width}
        >
          <InsetsProvider
            bottom={insets.bottom}
            left={insets.left}
            right={insets.right}
            top={`calc(${insets.top} + var(--chakra-space-2) + var(--chakra-space-10) + var(--chakra-space-0-5))`}
          >
            {profileDoc.pictures.map((p) => (
              <StoragePictureCell
                flexBasis={height}
                flexGrow={0}
                flexShrink={0}
                height={height}
                key={p.imgixUrl}
                picture={p}
                width={width}
              />
            ))}
          </InsetsProvider>
        </Box>

        <InfoCell
          left={0}
          position="absolute"
          profileDoc={profileDoc}
          right={0}
          style={{
            bottom: `calc(${insets.bottom} + var(--chakra-space-2))`,
          }}
          zIndex={20}
        />
      </Box>
    </Box>
  );
}

export default function ProfilePreviewScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <ProfilePreviewScreenMain />
      </Suspense>
    </Catch>
  );
}
