/* eslint-disable react/require-default-props */
import {
  Button,
  Center,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Gender from '../../common/Gender';
import Role from '../../common/Role';
import Sexuality from '../../common/Sexuality';
import Catch from '../../components/Catch';
import GenderFormControl from '../../components/GenderFormControl';
import SexualityFormControl from '../../components/SexualityFormControl';
import ErrorFallbackScreen from '../ErrorFallbackScreen';

export type Props = {
  gender?: Gender;
  onComplete: (values: {
    gender: Gender,
    sexuality: Sexuality,
  }) => void;
  role: Role;
  sexuality?: Sexuality;
};

export function GenderFormMain({
  gender,
  onComplete,
  role,
  sexuality,
}: Props) {
  const { t } = useTranslation('OnboardingGenderScreen', { keyPrefix: 'GenderForm' });

  const schema = useMemo(
    () => yup.object().shape({
      gender: yup
        .string()
        .label(t('gender.label'))
        .oneOf(Object.values(Gender))
        .required(),
      sexuality: yup
        .string()
        .label(t('sexuality.label'))
        .oneOf(Object.values(Sexuality))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      gender: gender ?? role === Role.HOT ? Gender.FEMALE : Gender.MALE,
      sexuality: sexuality ?? Sexuality.STRAIGHT,
    }),
    [gender, role, sexuality],
  );

  const handleFormSubmit = useCallback(
    (values: typeof schema['__outputType']) => {
      onComplete(values);
    },
    [onComplete],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          flex={1}
          gap={4}
          h="100%"
          minH={0}
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
        >
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <GenderFormControl
              label={t('gender.label')}
              name="gender"
            />

            <SexualityFormControl
              gender={values.gender}
              label={t('sexuality.label')}
              name="sexuality"
            />
          </VStack>

          <Button
            isLoading={isSubmitting}
            loadingText={t('nextButton.loading')}
            type="submit"
            width="100%"
          >
            {t('nextButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}

export default function GenderForm(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <GenderFormMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
