import { Button, Text, VStack } from '@chakra-ui/react';
import { setDoc } from 'firebase/firestore';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getProfileHiddenRef } from '../../collections/Profiles';
import AppLanguage from '../../common/AppLanguage';
import { useProfileRef } from '../../components/ProfileRefProvider';

export default function LanguageSelector() {
  const { i18n, t } = useTranslation('SettingsScreen', { keyPrefix: 'SettingsMenu.LanguageSelector' });

  const profileRef = useProfileRef();
  const profileHiddenRef = useMemo(() => getProfileHiddenRef(profileRef), [profileRef]);

  const onChange = useCallback(
    (language: string | string[]) => {
      setDoc(profileHiddenRef, {
        appLanguage: (typeof language === 'string' ? language : language[0]) as AppLanguage,
      }, { merge: true })
        .then(() => i18n.changeLanguage(typeof language === 'string' ? language : language[0]))
        .catch(() => { });
    },
    [i18n, profileHiddenRef],
  );

  return (
    <VStack alignItems="stretch">
      <Text fontSize="sm">
        {t('title')}
      </Text>

      <Button
        isActive={i18n.language === 'de'}
        justifyContent="start"
        leftIcon={<Text as="span">🇩🇪</Text>}
        onClick={() => onChange('de')}
        variant="ghost"
      >
        Deutsch
      </Button>

      <Button
        isActive={i18n.language === 'en'}
        justifyContent="start"
        leftIcon={<Text as="span">🇬🇧</Text>}
        onClick={() => onChange('en')}
        variant="ghost"
      >
        English
      </Button>

      <Button
        isActive={i18n.language === 'es'}
        justifyContent="start"
        leftIcon={<Text as="span">🇪🇸</Text>}
        onClick={() => onChange('es')}
        variant="ghost"
      >
        Español
      </Button>

      <Button
        isActive={i18n.language === 'fr'}
        justifyContent="start"
        leftIcon={<Text as="span">🇫🇷</Text>}
        onClick={() => onChange('fr')}
        variant="ghost"
      >
        Français
      </Button>

      <Button
        isActive={i18n.language === 'it'}
        justifyContent="start"
        leftIcon={<Text as="span">🇮🇹</Text>}
        onClick={() => onChange('it')}
        variant="ghost"
      >
        Italiano
      </Button>

      <Button
        isActive={i18n.language === 'nl'}
        justifyContent="start"
        leftIcon={<Text as="span">🇳🇱</Text>}
        onClick={() => onChange('nl')}
        variant="ghost"
      >
        Nederlands
      </Button>

      <Button
        isActive={i18n.language === 'pl'}
        justifyContent="start"
        leftIcon={<Text as="span">🇵🇱</Text>}
        onClick={() => onChange('pl')}
        variant="ghost"
      >
        Polski
      </Button>

      <Button
        isActive={i18n.language === 'pt'}
        justifyContent="start"
        leftIcon={<Text as="span">🇵🇹</Text>}
        onClick={() => onChange('pt')}
        variant="ghost"
      >
        Português
      </Button>

      <Button
        isActive={i18n.language === 'ru'}
        justifyContent="start"
        leftIcon={<Text as="span">🇷🇺</Text>}
        onClick={() => onChange('ru')}
        variant="ghost"
      >
        Русский
      </Button>

      <Button
        isActive={i18n.language === 'tr'}
        justifyContent="start"
        leftIcon={<Text as="span">🇹🇷</Text>}
        onClick={() => onChange('tr')}
        variant="ghost"
      >
        Türkçe
      </Button>

      <Button
        isActive={i18n.language === 'uk'}
        justifyContent="start"
        leftIcon={<Text as="span">🇺🇦</Text>}
        onClick={() => onChange('uk')}
        variant="ghost"
      >
        Українська
      </Button>
    </VStack>
  );
}
