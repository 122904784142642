import {
  Button,
  ButtonGroup,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { setDoc } from 'firebase/firestore';
import { Formik } from 'formik';
import { Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreDocData } from 'reactfire';
import * as yup from 'yup';

import { getProfileHiddenRef, ProfileHiddenDoc } from '../../collections/Profiles';
import Catch from '../../components/Catch';
import { useProfileRef } from '../../components/ProfileRefProvider';
import RangeFormControl from '../../components/RangeFormControl';

export type Props = {
  onCancel: () => void;
};

export function PreferencesFormMain({ onCancel }: Props) {
  const { t } = useTranslation('ProfileScreen', { keyPrefix: 'ProfileForm' });

  const profileRef = useProfileRef();
  const profileHiddenRef = useMemo(() => getProfileHiddenRef(profileRef), [profileRef]);

  const { data: profileHiddenDoc } = useFirestoreDocData<ProfileHiddenDoc | undefined>(
    profileHiddenRef,
  );

  const schema = useMemo(
    () => yup.object().shape({
      preferences: yup
        .object()
        .label(t('preferences.label'))
        .shape({
          age: yup
            .object()
            .label(t('preferences.age.label'))
            .shape({
              max: yup
                .number()
                .label(t('preferences.age.max.label'))
                .integer()
                .min(18)
                .max(100)
                .required(),
              min: yup
                .number()
                .label(t('preferences.age.min.label'))
                .integer()
                .min(18)
                .max(100)
                .required(),
            })
            .required(),
          height: yup
            .object()
            .label(t('preferences.height.label'))
            .shape({
              max: yup
                .number()
                .label(t('preferences.height.max.label'))
                .integer()
                .min(30)
                .max(300)
                .required(),
              min: yup
                .number()
                .label(t('preferences.height.min.label'))
                .integer()
                .min(30)
                .max(300)
                .required(),
            })
            .required(),
          weight: yup
            .object()
            .label(t('preferences.weight.label'))
            .shape({
              max: yup
                .number()
                .label(t('preferences.weight.max.label'))
                .integer()
                .min(30)
                .max(300)
                .required(),
              min: yup
                .number()
                .label(t('preferences.weight.min.label'))
                .integer()
                .min(30)
                .max(300)
                .required(),
            })
            .required(),
        })
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      preferences: profileHiddenDoc?.preferences ?? {
        age: {
          max: 100,
          min: 18,
        },
        height: {
          max: 300,
          min: 30,
        },
        weight: {
          max: 300,
          min: 30,
        },
      },
    }),
    [profileHiddenDoc],
  );

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      await setDoc(
        profileHiddenRef,
        {
          preferences: values.preferences,
        },
        { merge: true },
      );

      onCancel();
    },
    [onCancel, profileHiddenRef],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <VStack alignItems="stretch" gap={4}>
          <Heading fontSize="xl">
            {t('preferences.label')}
          </Heading>

          <RangeFormControl
            label={t('preferences.age.label')}
            max={60}
            min={18}
            name="preferences.age"
          />

          <RangeFormControl
            label={t('preferences.height.label')}
            max={250}
            min={150}
            name="preferences.height"
          />

          <RangeFormControl
            label={t('preferences.weight.label')}
            max={150}
            min={35}
            name="preferences.weight"
          />

          <ButtonGroup display="flex" justifyContent="flex-end">
            <Button
              isLoading={isSubmitting}
              loadingText={t('updateProfileButton.loading')}
              onClick={() => handleSubmit()}
            >
              {t('updateProfileButton.default')}
            </Button>
          </ButtonGroup>
        </VStack>
      )}
    </Formik>
  );
}

export default function PreferencesForm(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <PreferencesFormMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
