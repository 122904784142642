import {
  Badge,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import algoliasearch from 'algoliasearch';
import { useField } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { LuFileEdit } from 'react-icons/lu';

import Language from '../common/Language';
import LanguageAlgoliaSearchRecord from '../common/LanguageAlgoliaSearchRecord';
import useShowError from '../hooks/useShowError';

const client = algoliasearch('G4ZEIPSJ7Z', '87b1a20fccf5600b3aff0754e664c2ce');
const languagesIndex = client.initIndex('languages');

export interface Props {
  label: string;
  name: string;
  placeholder: string;
}

export default function LanguagesFormControl({ label, name, placeholder }: Props) {
  const showError = useShowError();
  const [input, meta, helper] = useField<Language[]>(name);

  const [query, setQuery] = useState<string>('');
  const [languagesResult, setLanguagesResult] = useState<LanguageAlgoliaSearchRecord[]>();

  useEffect(
    () => {
      languagesIndex
        .search<LanguageAlgoliaSearchRecord>(query, { hitsPerPage: 5 })
        .then(
          ({ hits }) => {
            setLanguagesResult(hits);
          },
        )
        .catch(showError);
    },
    [query, showError],
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleChange = useCallback(
    (languages: Language[]) => {
      helper.setValue(languages.sort())
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <>
      <FormControl isInvalid={!!meta.error}>
        <FormLabel>{label}</FormLabel>

        <VStack alignItems="stretch">
          <Box
            borderRadius="md"
            cursor="pointer"
            onClick={onOpen}
            px={4}
            py={2}
            style={{
              border: '1px solid',
              borderColor: 'inherit',
            }}
          >
            <HStack spacing={4}>
              {input.value.length ? (
                <Wrap flex={1}>
                  {input.value.map((item) => (
                    <Badge key={item} textTransform="uppercase">{item}</Badge>
                  ))}
                </Wrap>
              ) : (
                <Text flex={1}>
                  {placeholder}
                </Text>
              )}

              <Icon as={LuFileEdit} />
            </HStack>
          </Box>
        </VStack>

        <FormErrorMessage>
          {meta.error}
        </FormErrorMessage>
      </FormControl>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay
          backdropFilter="saturate(180%) blur(20px)"
          backgroundColor="rgb(from var(--chakra-colors-chakra-body-bg) r g b / 0.5)"
        />

        <ModalContent
          mx={4}
        >
          <ModalCloseButton />

          <ModalHeader>
            {label}
          </ModalHeader>

          <ModalBody>
            <VStack alignItems="stretch" spacing={4}>
              <Input
                onChange={(e) => setQuery(e.target.value)}
                placeholder="English"
                value={query}
              />

              <CheckboxGroup
                onChange={handleChange}
                value={input.value}
              >
                <VStack alignItems="stretch" spacing={4}>
                  {languagesResult?.map(({ name: langName, objectID }) => (
                    <Checkbox
                      key={objectID}
                      value={objectID}
                    >
                      <Text>{langName}</Text>
                    </Checkbox>
                  ))}
                </VStack>
              </CheckboxGroup>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant="ghost">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
