import { Center, Spinner } from '@chakra-ui/react';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
      <App />
    </Suspense>
  </StrictMode>,
);
