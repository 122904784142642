import {
  Box,
  Center,
  Container,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreCollection } from 'reactfire';

import { ApplicationStatus, useApplicationsCollectionRef } from '../../collections/Applications';
import Catch from '../../components/Catch';
import { useProfileRef } from '../../components/ProfileRefProvider';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Application from './Application';

export function ApplicationListMain() {
  const { t } = useTranslation('ApplicationsScreen');
  const profileRef = useProfileRef();

  const applicationsCollectionRef = useApplicationsCollectionRef();
  const { data: applicationsSnap } = useFirestoreCollection(
    query(
      applicationsCollectionRef,
      where('organizerRef', '==', profileRef),
      where('status', '==', ApplicationStatus.SENT),
      orderBy('sentAt', 'desc'),
    ),
  );

  const { height, width } = useWindowDimensions();

  if (!applicationsSnap.docs.length) {
    return (
      <Container height="100%">
        <Center height="100%">
          <Text textAlign="center">
            {t('emptyList.body')}
          </Text>
        </Center>
      </Container>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
      h={height}
      overflowX="hidden"
      overflowY="scroll"
      overscrollBehavior="none"
      scrollSnapType="y mandatory"
      w={width}
    >
      {applicationsSnap.docs.map((applicationSnap) => (
        <Application
          applicationSnap={applicationSnap}
          flexBasis={height}
          flexGrow={0}
          flexShrink={0}
          height={height}
          key={applicationSnap.id}
          scrollSnapAlign="center"
          scrollSnapStop="always"
          width={width}
        />
      ))}
    </Box>
  );
}

export default function ApplicationList() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <ApplicationListMain />
      </Suspense>
    </Catch>
  );
}
