import {
  Avatar,
  Box,
  Center,
  CircularProgress,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  ListItem,
  Skeleton,
  Spinner,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuPen, LuSettings, LuUser } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { getProfileHiddenRef } from '../../collections/Profiles';
import { getPhotoSizeUrl } from '../../common/getPhotoSizeUrl';
import Role from '../../common/Role';
import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import { useProfileRef } from '../../components/ProfileRefProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';

export function ProfileScreenMain() {
  const insets = useInsets();

  const { t } = useTranslation('ProfileScreen');

  const profileRef = useProfileRef();
  const profileHiddenRef = useMemo(() => getProfileHiddenRef(profileRef), [profileRef]);
  const { data: profileDoc } = useFirestoreDocData(profileRef);
  const { data: profileHiddenDoc } = useFirestoreDocData(profileHiddenRef);

  const profileImageUrl = useMemo(
    () => {
      const uri = profileDoc.pictures[profileDoc.primaryPictureIndex ?? 0]?.imgixUrl;

      if (!uri) {
        return undefined;
      }

      return getPhotoSizeUrl({
        faces: true,
        height: 48 * 4,
        uri,
        width: 48 * 4,
      });
    },
    [profileDoc.pictures, profileDoc.primaryPictureIndex],
  );

  const colorScheme = useMemo(
    () => {
      if (profileDoc.role === Role.HOT) {
        return 'deepOrange';
      }

      if (profileDoc.role === Role.RICH) {
        return 'teal';
      }

      return 'gray';
    },
    [profileDoc.role],
  );

  return (
    <VStack alignItems="stretch" gap={4} h="100%">
      <Container
        style={{
          paddingTop: insets.top,
        }}
      >
        <HStack gap={3}>
          <Heading
            flex={1}
            fontSize="4xl"
            lineHeight="40px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {t('title')}
          </Heading>

          <IconButton
            aria-label={t('settingsButton.default')}
            as={Link}
            icon={<Icon as={LuSettings} />}
            to="settings"
          />
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <VStack alignItems="stretch" gap={8}>
          <VStack alignItems="center" gap={4}>
            <Box boxSize={64} position="relative">
              <CircularProgress
                capIsRound
                color={`${colorScheme}.400`}
                isIndeterminate={typeof profileDoc.score !== 'number'}
                position="absolute"
                size={64}
                thickness={4}
                trackColor={`rgb(from var(--chakra-colors-${colorScheme}-400) r g b / 0.25)`}
                transform="rotate(180deg)"
                transformOrigin="center center"
                value={profileDoc.score}
                zIndex={-10}
              />

              <Avatar
                as={Link}
                boxSize={48}
                icon={<Icon as={LuUser} />}
                loading="lazy"
                margin={8}
                position="absolute"
                size="2xl"
                src={profileImageUrl}
                to="preview"
              />

              <HStack
                bottom={0}
                justifyContent="center"
                left={0}
                position="absolute"
                right={0}
              >
                <Box
                  background={`${colorScheme}.400`}
                  borderRadius="xl"
                  px={4}
                  py={2}
                >
                  {typeof profileDoc.score === 'number' ? (
                    <Text color="var(--chakra-colors-gray-800)" fontSize="xl" fontWeight="bold">
                      {profileDoc.score}
                      %
                      {' '}
                      {profileDoc.role}
                    </Text>
                  ) : (
                    <Text color="var(--chakra-colors-gray-800)" fontSize="xl" fontWeight="bold">
                      Analyzing...
                    </Text>
                  )}
                </Box>
              </HStack>

              <IconButton
                aria-label={t('updateProfileButton.default')}
                as={Link}
                borderRadius="full"
                colorScheme={colorScheme}
                icon={<Icon as={LuPen} />}
                position="absolute"
                right={6}
                size="lg"
                to="update"
                top={6}
              />
            </Box>

            <HStack gap={3}>
              <Text fontSize="3xl" fontWeight="bold" lineHeight={1}>
                {profileDoc.name}
                {', '}
                {profileDoc.age}
              </Text>
            </HStack>

            {profileHiddenDoc.profileSuggestions ? (
              <UnorderedList flex={1} fontSize="sm">
                {profileHiddenDoc.profileSuggestions.map((s, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem key={i}>{s}</ListItem>
                ))}
              </UnorderedList>
            ) : (
              <VStack alignItems="stretch">
                <Skeleton h="20px" w="100%" />
                <Skeleton h="20px" w="100%" />
                <Skeleton h="20px" w="100%" />
              </VStack>
            )}
          </VStack>
        </VStack>
      </Container>
    </VStack>
  );
}

export default function ProfileScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><Spinner size="xl" /></Center>}>
        <ProfileScreenMain />
      </Suspense>
    </Catch>
  );
}
