import {
  Button,
  Center,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { LuPlusSquare } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { useTripsCollectionRef } from '../../collections/Trips';
import Catch from '../../components/Catch';
import { useProfileRef } from '../../components/ProfileRefProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Trip, { TripLoading } from './Trip';

export function TripListMain() {
  const { t } = useTranslation('TripsScreen');

  const profileRef = useProfileRef();

  const tripsCollectionRef = useTripsCollectionRef();
  const { data: tripsSnap } = useFirestoreCollection(
    query(
      tripsCollectionRef,
      where('organizerRef', '==', profileRef),
      orderBy('departure', 'asc'),
    ),
  );

  if (!tripsSnap.docs.length) {
    return (
      <VStack alignItems="stretch" h="100%">
        <Center flex={1}>
          <Text textAlign="center">{t('emptyList.body')}</Text>
        </Center>

        <Button
          as={Link}
          leftIcon={<Icon as={LuPlusSquare} />}
          to="/trips/add"
        >
          {t('addTripButton.default')}
        </Button>
      </VStack>
    );
  }

  return (
    <VStack alignItems="stretch" gap={3}>
      {tripsSnap.docs.map((tripSnap) => (
        <Trip key={tripSnap.id} tripSnap={tripSnap} />
      ))}
    </VStack>
  );
}

export function TripListLoading() {
  return (
    <VStack alignItems="stretch" gap={3}>
      <TripLoading />
      <TripLoading />
      <TripLoading />
    </VStack>
  );
}

export default function TripList() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<TripListLoading />}>
        <TripListMain />
      </Suspense>
    </Catch>
  );
}
